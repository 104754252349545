import React from 'react';
import { connect } from 'react-redux';
import { Org as OrgComp } from 'dsilo-ui-components'
import AppHeader from '../AppsDashboard/AppHeader';
import { getOrgDetails, updateOrg, clearOrgMessage, getOrgLogo } from "../../store/actions/org"

const Org = props => {

	return (
		<AppHeader>
			<div style={{ margin: 20 }}>
				<OrgComp
					onBack={() => { props.history.push({ pathname: `/apps` }); }}
					getOrgDetails={props.getOrgDetails}
					clearOrgMessage={props.clearOrgMessage}
					getOrgLogo={props.getOrgLogo}
					onSubmit={props.updateOrg}
					org={props.org}
				/>
			</div>
		</AppHeader>

	);
};

const mapDispatchToProps = {
	getOrgDetails,
	updateOrg,
	clearOrgMessage,
	getOrgLogo
}
const mapStateToProps = state => {
	return {
		org: state.org
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Org);