
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    InputLabel: {
        marginTop: 10
    },
    TextField: {
        width: "100%",
        "& .MuiInputBase-input": {
            fontSize: "14px !important",
        },
        "& .MuiInputBase-root": {
            minHeight: "43px !important",
        },
        "& .MuiChip-root": {
            fontSize: "14px !important",
        },
    },
    TextArea: {
        width: '100%',
    },
    gridStyle1: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    gridStyle2: {
        display: 'flex',
        gap: '5px'
    },
    tableContainer: {
        marginTop: 20,
        maxHeight: "530px",
        overflowY: "auto",
        overflowX: "auto",
    },
    tableHeadRow: {
        height: "40px !important",
        backgroundColor: "#ddd !important",
        position: "sticky",
        top: 0,
        zIndex: 2,
    },
    tableHeadCell: {
        color: "black",
        fontSize: "14px",
        padding: "12px 16px"
    },
    tableRowEven: {
        backgroundColor: "#f9f9f9 !important",
    },
    tableRowOdd: {
        backgroundColor: "#ffffff !important",
    },
    tableCell: {
        padding: "4px 8px !important",
        fontSize: "14px",
        lineHeight: "1.5 !important",
        whiteSpace: "normal",
    },
    tableRow: {
        height: "auto !important",
        minHeight: "20px !important"
    },
    actionIcons: {
        color: "#555",
        border: "1px solid #ddd",
        borderRadius: "6px",
        padding: "2px",
        backgroundColor: "transparent",
        transition: "all 0.3s ease",
        "&:hover": {
            color: "#000",
            borderColor: "#aaa",
        },
        marginRight: "5px",
    },
    iconSize: {
        fontSize: "14px",
    },

    editClause: {
        marginTop: "10px",
        position: "relative",
        overflow: "visible",
        maxWidth: "80%"
    },
    updateTemplate: {
        dispaly: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    confirmationDialoge: {
        "& .MuiDialog-paperWidthSm": {
            height: "250px",
            width: "600px"
        }
    },
    actionsContainer: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        flexWrap: "nowrap",
    },
    table: {
        minWidth: "100px"
    },
    searchIcon: {
        fontSize: 32,
    },
    clauseDataGrid: {
        marginTop: 10,
        position: 'relative',
        overFlow: 'visible',
        maxWidth: '80%'
    },
    clauseList: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 10
    },
    clauseDataDiv: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
    },
    clauseDataDiv: {
        display: "flex",
        justifyContent: "space-between"
    }


}));
export default useStyles


