/**
 * @module Actions/Application
 * @desc Application Actions
 */
import { ActionTypes } from 'constants/index';
export const loadAppconfig = (appId, orgId) => dispatch => {
	dispatch({ type: ActionTypes.LOAD_APP_CONFIG_LOADING, payload: { appId, orgId } });
};

export const loadApps = (orgId) => dispatch => {
	dispatch({ type: ActionTypes.LOAD_APPS_LOADING, orgId });
};
export const getTemplates = (orgId) => dispatch => {
	dispatch({ type: ActionTypes.GET_TEMPLATES, orgId: orgId });
};
// export const getRoles = (appId) => dispatch => {
// 	dispatch({ type: ActionTypes.LOAD_ROLES_LOADING, appId });
// };

export const saveApp = (data) => dispatch => {
	dispatch({ type: ActionTypes.SAVE_APP_LOADING, data });
};

export const saveDatasource = (data) => dispatch => {
	dispatch({ type: ActionTypes.SAVE_DATASOURCE_LOADING, data });
};

export const getDataelements = (datasourceId) => dispatch => {
	dispatch({ type: ActionTypes.LOAD_DATAELEMENTS_LOADING, datasourceId });
};

export const saveDataModel = (data) => dispatch => {
	dispatch({ type: ActionTypes.SAVE_DATAMODEL_LOADING, data });
};
export const getMember = (user, appId) => dispatch => {
	dispatch({ type: ActionTypes.LOAD_MEMBER_LOADING, user: { ...user, appId } });
};
export const getEntity = (entityId, appId) => dispatch => {
	dispatch({ type: ActionTypes.GET_ENTITY_LOADING, payload: { entityId, appId } });
};
export const getMemberById = (appId) => dispatch => {
	dispatch({ type: ActionTypes.GET_MEMBER_LOADING, appId });
};

export const saveSupportingDocument = (data) => dispatch => {
	dispatch({ type: ActionTypes.SAVE_SUPPORTING_DOCUMENT_SUCCESS, payload: data });
};

export const getAppAndTemplates = (orgId) => dispatch => {
	dispatch({ type: ActionTypes.GET_APP_TEMPLATES, orgId: orgId });
}

export const createApp = (data) => dispatch => {
	dispatch({ type: ActionTypes.CREATE_APP, payload: data });
}

export const clearAppMessage = () => dispatch => {
	dispatch({ type: ActionTypes.APP_MESSAGE_CLEAR, payload: {} });
}

export const getCategories = () => dispatch => {
	dispatch({ type: ActionTypes.GET_CATEGORIES });
}

export const setDappsSearchText = (event) => {
	return {
		type: ActionTypes.SET_APPS_SEARCH_TEXT,
		searchText: event.target.value
	}
}

export const setCategoryFilter = (event) => {
	return {
		type: ActionTypes.SET_APPS_CATEGORY_FILTER,
		category: event.target.value
	}
}

export const deleteDapp = (dappId) => dispatch => {
	return dispatch({ type: ActionTypes.DELETE_APP, payload: dappId });
}

export const verifyApp = (appId) => dispatch => {
	return dispatch({ type: ActionTypes.VERIFY_APP, payload: appId });
}

export const handleLoader = (data) =>  {
	return { type: ActionTypes.HANDLE_LOADER, payload: data }
}