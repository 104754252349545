import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';

import { AdvancedTable, TableSupport } from 'dsilo-ui-components'

import { Loader } from '../../components/loader'

import { getColumns, modifyDataIntoNeededFormat, sharedJSON } from "../../utility/SystemPagesCommonCode/TableUtils";
import { getLastSelectedItemFromUserSession, getPaginationDataFromUserSession, updatePaginationDataToUserSession, updateSelectedItemToUserSession } from "../../utility/SystemPagesCommonCode/UserSession";

import { clearReduxDataOfCurrentComponent, clearSuccessMsg, getRoles } from "../../store/actions";

import { useStyles } from "../../utility/SystemPagesCommonCode/styles";

const Roles = (props) => {
    const classes = useStyles()

    const { appId, formData } = props
    const pageUrl = props.page?.url
    const { _id: userId } = props.user
    const { chartUniqueId, chartData } = props.data

    const [paginationData, setPaginationData] = useState({
        page: 0,
        rowsPerPage: 10
    })
    const [state, setState] = useState({ ...sharedJSON });
    const [rolesList, setRolesList] = useState([]);

    const [highLighterIdForSelectedLineItem, setHighLighterIdForSelectedLineItem] = useState('');

    useEffect(() => {
        let pageSession = getPaginationDataFromUserSession(userId, pageUrl)
        if (pageSession && pageSession.rowsPerPage) {
            setPaginationData({
                ...paginationData,
                page: pageSession.page,
                rowsPerPage: pageSession.rowsPerPage,
                updateLocalStore: false
            })
        }
        fetchData()
    }, [])

    // Update the localStorage in browser whenever pagination data changes
    useEffect(() => {
        if (paginationData && paginationData.updateLocalStore) {
            updatePaginationDataToUserSession(userId, pageUrl, { page: paginationData.page, rowsPerPage: paginationData.rowsPerPage })
        }
    }, [paginationData])

    // When data is updated to redux state from API call
    useEffect(() => {
        if (formData && formData.document[appId + '_system_roles']?.length) {
            console.log("roles page === redux state of roles", formData.document[appId + '_system_roles'])
            let roles = formData?.document[appId + '_system_roles'].map(k => ({
                id: k._id,
                name: k.name,
                description: k.description,
                privileges: k.privileges.join(', ')
            }))
            console.log("roles page === modified roles", roles)
            setRolesList(roles)
        }
    }, [formData && formData.document[appId + '_system_roles']])

    // Modify data to needed format in the table
    useEffect(() => {
        if (rolesList) {
            let keysArr = ['_id'];
            console.log("privileges page === privileges list", rolesList)
            let td = TableSupport.setDatafromApi2(
                modifyDataIntoNeededFormat(rolesList, chartData),
                keysArr,
                state,
                getColumns()
            )
            console.log("privileges page === final data to pass table", td)
            setState({
                ...TableSupport.setDatafromApi2(
                    modifyDataIntoNeededFormat(rolesList, chartData),
                    keysArr,
                    state,
                    getColumns(rolesList)
                ),
                count: rolesList.length
            });
            let lastSelected = getLastSelectedItemFromUserSession(userId, pageUrl)
            console.log("it is last selected tile ===", lastSelected)
            if (lastSelected) {
                setHighLighterIdForSelectedLineItem(lastSelected)
            }
        }
    }, [rolesList]);

    const fetchData = () => {
        console.log("roles page === getRoles")
        const payload = {
            "appId": appId,
            "orgId": props.user.orgId,
            "dataUniqueId": appId + '_system_roles'
        }
        props.getRoles(payload)
    }

    const onAddClick = () => {
        props.history.push({ pathname: `/${props.match.params.appid}/page/role/new` });
    }

    const tableCrudOperations = (item, type) => {
        if (type === 'edit') {
            updateSelectedItemToUserSession(userId, pageUrl, item.id)
            props.history.push({
                pathname: `/${appId}/page/${chartData.editPage}/${item.id}`,
            })
        }
    }

    // Pagination related functions
    const handleChangePage = (event, page) => {
        setPaginationData({
            ...paginationData,
            page,
            updateLocalStore: true
        })
    };

    const handleChangeRowsPerPage = event => {
        setPaginationData({
            ...paginationData,
            page: 0,
            rowsPerPage: event.target.value,
            updateLocalStore: true
        })
    };

    return (
        <>
            {
                formData[appId + '_system_roles_loading'] ? <Loader /> :
                    <AdvancedTable
                        name={'RolesList'}
                        {...props}
                        {...state}
                        chartData={chartData}

                        isTableHead={true}
                        isTableSubHeader={false}
                        isExport={false}
                        isViewCoulmns={false}
                        isSearch={false}
                        isFilter={false}
                        hasCheckbox={false}

                        actions={{ edit: true, view: false, delete: true, copy: false, download: false }}
                        isTablePagination={true}
                        pagination={{ type: 'local' }}
                        page={paginationData.page}
                        rowsPerPage={paginationData.rowsPerPage}

                        highLighterIdForSelectedLineItem={highLighterIdForSelectedLineItem}

                        // Actions
                        onAddClick={onAddClick}
                        tableCrudOperations={tableCrudOperations}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}

                        // Styles
                        stripRows
                        headerstyle={{
                            fontSize: "14px",
                            color: "rgba(0, 0, 0, 0.87)",
                            textAlign: "left",
                            backgroundColor: "#"
                        }}
                        subHeaderstyle={{
                            fontSize: "14px",
                            color: "rgba(0, 0, 0, 0.87)",
                            backgroundColor: "#FFF",
                            textAlign: "left"
                        }}
                        headerClass={classes.headerClass}
                        rowStyle={{
                            fontSize: "14px",
                            color: "rgba(0, 0, 0, 0.87)",
                            backgroundColor: "none",
                            lineHeight: 1.5,
                            textAlign: "left"
                        }}
                    />
            }
        </>
    )
}

const mapDispatchToProps = {
    getRoles,
    clearReduxDataOfCurrentComponent,
    clearSuccessMsg,
};

const mapStateToProps = state => {
    return {
        user: state.user,
        formData: state.form,
        member: state.appConfig.members,
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(withRouter(Roles)));