import React, { useState } from 'react';

import { useParams } from "react-router-dom";

import { Grid } from "@material-ui/core";

import ClauseList from './ClauseList';
import TemplateView from './TemplateView';
import ClauseData from './ClauseData';

const WorkbookView = (props) => {
	const { id: _id } = useParams()

	const { chartData } = props.data

	const [isClauseView,setIsClauseView]=useState(false)

	return (
		<div style={{ width: "100%" }}>
			{isClauseView && 
				<ClauseData
					{...props}
					isClauseView={isClauseView}
					setIsClauseView={setIsClauseView}
				/>
			}
			{!isClauseView &&
				<>
					<Grid>
						<TemplateView
							{...props}
							setIsClauseView={setIsClauseView}	
						/>
						{_id &&
							<>
								<ClauseList
									{...props}
									isSearch={chartData.isSearch || false}
									isClauseView={isClauseView}
									setIsClauseView={setIsClauseView}

								/>
							</>
						}
					</Grid>
				</>
					}
		</div >
	);
}
export default WorkbookView
