import React, { useState, useEffect } from "react"

import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { hot } from 'react-hot-loader/root';
import { useHistory, withRouter, useParams } from "react-router-dom";

import { Button, Grid, Divider, Typography, TextField, Slider, FormHelperText, CircularProgress } from "@material-ui/core"

import { getDataModelById, getContractDataById, createDocument, clearReduxDataOfCurrentComponent, updateDataModelData } from '../../../store/actions';

import { Helpers } from "dsilo-ui-components";

import { Loader } from '../../loader';

import useStyles from "../styles";

const ClauseData = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const { id: _id, sdid } = useParams();

    const { setIsClauseView } = props
    const { chartData, chartUniqueId } = props.data

    const [state, setState] = useState({})
    const [threshold, setThreshold] = useState(10);
    const [selectedClause, setSelectedClause] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [allDataElementforClause, setAllDataElementsforClause] = useState({})

    let subDataElements = chartData.subDataElement
    let dataUniqueId = chartData.subDataModel;
    let mainDataModelDataElement = props?.formData?.dataModel?.[dataUniqueId]?.mainDataModel?.dataElement
    let loader = props.formData[props.data.chartUniqueId + '_getClause' + '_loading']

    const clearCurrentChartReduxStore = () => {
        let subKeys = [
            chartUniqueId + '_get',
            chartUniqueId + '_getClause',
            chartUniqueId + '_save',
            chartUniqueId + '_update'
        ]

        props.clearReduxDataOfCurrentComponent({
            key: "document",
            subKey: subKeys
        })
    }

    useEffect(() => {
        let res = props.formData.document[props.data.chartUniqueId + '_getClause'];
        if (res?.data?.[0]) {
            let news = res?.data?.[0] || {}
            setSelectedClause({ ...selectedClause, ...news })
        }
    }, [props.formData.document[props.data.chartUniqueId + '_getClause']])

    useEffect(() => {
        if (props.formData?.dataModel?.[dataUniqueId]) {
            let dataElementsList = props.formData?.dataModel?.[dataUniqueId]?.dataElements || []
            let obj = {}
            dataElementsList.forEach(de => {
                obj[de.name] = de
            })
            setAllDataElementsforClause(obj)
        }
    }, [props.formData?.dataModel])

    useEffect(() => {
        let res = props.formData.document[chartUniqueId + '_save'];
        if (res) {
            toast.success(res.message || 'Document Created Successfully')
            clearCurrentChartReduxStore();
            handleBack();
        }
    }, [props.formData?.document[chartUniqueId + '_save']])

    useEffect(() => {
        let res = props.formData.document[chartUniqueId + '_update'];
        if (res) {
            clearCurrentChartReduxStore();
            handleBack();
        }
    }, [props.formData?.document[chartUniqueId + '_update']]);

    useEffect(() => {
        fetchDataModel();
        updateDefaultFields();
        getClauseData();

        return () => {
            clearCurrentChartReduxStore();
        };
    }, []);

    // Fetch Data Model
    const fetchDataModel = () => {
        let dataUniqueId = chartData.subDataModel;
        let dataModelId = chartData.subDataModel;

        if (!props.formData?.dataModel?.[dataUniqueId] && !props.formData[`${dataUniqueId}_loading}`]) {
            let payload = {
                appid: props.match.params.appid,
                orgId: props.appConfig?.org?._id,
                dataUniqueId,
                dataModelId,
            };
            props.getDataModelById(payload);
        }
    };

    //updating the fields
    const updateDefaultFields = () => {
        if (chartData.subDataElement?.length) {
            let isUpdated = false;
            let obj = {};
            chartData.subDataElement.forEach((sd) => {
                if (sd.defaultValue) {
                    obj[sd.value] = sd.defaultValue;
                    isUpdated = true;
                }
            });

            if (isUpdated) {
                setState((prevState) => ({ ...prevState, ...obj }));
            }
        }
    };

    //  to Get the  Clause Data 
    const getClauseData = () => {
        if (sdid && sdid !== 'new') {
            let fields = chartData.subDataElement?.map((sd) => sd.value).join(",") || "";
            let payload = {
                id: sdid,
                dataModelId: chartData.subDataModel,
                appId: props.match.params.appid,
                fields: fields,
                dataUniqueId: chartUniqueId + "_getClause",
            };
            props.getContractDataById(payload);
        }
    };

    const onSaveClick = () => {
        let data = JSON.parse(JSON.stringify(selectedClause))
        let isValid = true;
        subDataElements.forEach(sd => {
            const value = data[sd.value];
            //checking if fields are empty or invalid
            if (sd.required && (value === undefined || value === null || (typeof value === "string" && value.trim() === "") || (typeof value === "number" && isNaN(value)))) {
                isValid = false;
            }

            if ((sd.hide || sd.readonly) && sd.defaultValue) {
                data[sd.value] = sd.defaultValue;
            }
        });
        // If validation fails, show an error
        if (!isValid) {
            setIsSubmitted(true);
            return;
        }
        //update request for updating the clause
        if (sdid && sdid !== 'new') {
            let formData = {
                appid: props.match.params.appid,
                dataModelId: props.data.chartData?.subDataModel,
                dataUniqueId: props.data.chartUniqueId + '_update',
                payload: data,
            }
            props.updateDataModelData(formData)
        } else {
            //adding the clause document
            let formData = {
                appid: props.match.params.appid,
                dataModelId: props.data.chartData?.subDataModel,
                dataUniqueId: props.data.chartUniqueId + '_save',
                payload: {
                    ...data,
                    [mainDataModelDataElement]: _id
                },
            }
            props.createDocument(formData)
        }
    }

    const handleThresholdChange = (event, newValue) => {
        setThreshold(newValue);
        setSelectedClause((prevClause) => ({
            ...prevClause,
            threshold: newValue,
        }));
    };

    const handleInputChange = (event, field) => {
        const { value } = event.target;
        setSelectedClause((prev) => ({
            ...prev,
            [field.value]: value,
        }));
    };

    const handleBack = () => {
        setIsClauseView(false)
        history.goBack()
    }

    return (
        <>
            {
                loader ? <Loader /> :
                    <Grid style={{ width: "100%" }}>
                        <div className={classes.clauseDataDiv}>
                            <h1>{props.page.clauseTitle}</h1>
                            <div style={{ marginTop: 10, display: "flex", gap: '10px' }}>
                                <Button
                                    color="primary" onClick={onSaveClick}
                                    style={{ textTransform: 'capitalize' }}
                                    variant="contained">
                                    {
                                        (Helpers.loading(props, '_save') || Helpers.loading(props, '_update')) ? <CircularProgress color='#fff' size={24} /> : (selectedClause._id ? "Update" : "Add")
                                    }
                                </Button>
                                <Button variant="contained" color="secondary" size='small' onClick={handleBack}>Back</Button>
                            </div>
                        </div>
                        <Divider />
                        <Grid container spacing={1} columnSpacing={2} xs={12} className={classes.clauseDataGrid}>
                            {chartData.subDataElement.map((field) => {
                                let de = allDataElementforClause[field.value]
                                let type = de?.type

                                return (
                                    <Grid item xs={6} key={field.value}>
                                        <Grid container alignItems="center" spacing={2} columnSpacing={5}>
                                            <Grid item xs={3}>
                                                <Typography sx={{ textAlign: "right", fontWeight: "500" }}>
                                                    {field.label}{field.required && <span style={{ color: "#d32f2f" }}>*</span>}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                {type === 'number' && field.uiType === "range" ? (
                                                    <>
                                                        <Slider
                                                            value={Number(selectedClause.threshold)}
                                                            onChange={handleThresholdChange}
                                                            min={0}
                                                            max={100}
                                                            step={1}
                                                            valueLabelDisplay="on"
                                                            error={isSubmitted && selectedClause.threshold === undefined}
                                                        />
                                                        {isSubmitted && selectedClause.threshold === undefined && (
                                                            <FormHelperText error>This field is required</FormHelperText>
                                                        )}
                                                    </>
                                                ) : type === 'textarea' ? (
                                                    <TextField
                                                        multiline
                                                        minRows={3}
                                                        variant="outlined"
                                                        required={field.required}
                                                        error={isSubmitted && !selectedClause[field.value]}
                                                        className={classes.TextArea}
                                                        value={selectedClause[field.value] || ""}
                                                        onChange={(event) => handleInputChange(event, field)}
                                                        placeholder={'Enter ' + field.label}
                                                        helperText={isSubmitted && !selectedClause[field.value] ? "This field is required" : ""}
                                                    />
                                                ) : (
                                                    <TextField
                                                        type={type === 'number' ? "number" : "text"}
                                                        variant="outlined"
                                                        error={isSubmitted && !selectedClause[field.value]}
                                                        className={classes.TextField}
                                                        value={selectedClause[field.value] || ""}
                                                        onChange={(event) => handleInputChange(event, field)}
                                                        placeholder={'Enter ' + field.label}
                                                        helperText={isSubmitted && !selectedClause[field.value] ? "This field is required" : ""}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
            }
        </>
    )
}

const mapDispatchToProps = {
    getDataModelById,
    getContractDataById,
    clearReduxDataOfCurrentComponent,
    updateDataModelData,
    createDocument

}

const mapStateToProps = state => {
    return {
        formData: state.form,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ClauseData)))