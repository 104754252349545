import { ActionTypes } from 'constants/index';

const initialState = {
  success: false,
  forms: [],
  data: [],
  loading: false,
  uploadedPDFData: null,
  document: {},
  pdfDocument: null,
  message: null,
  loading1: false,
  successMsg: null,
  externalFilterData: null,
  downloadLoading: false,
  dataModel: {},
  topSuggestions: null,
  searchResults: null,
  tasks: [],
  taskData: {},
  duplicateDocError: null,
  processAgain: null,
  auditTrails: null,
  bulkUploadA: [],
  serviceBusData: [],
  error: {},
  historyData: null
};

const form = function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_FORMS_SUCCESS:
      {
        return {
          ...state,
          forms: action.payload
        }
      }
    case ActionTypes.GET_FORMS_ERROR:
      {
        return {
          ...state,
          error: action.payload
        }
      }
    case ActionTypes.GET_FORM_BY_NAME_SUCCESS:
      {
        let selectedForm = JSON.parse(JSON.stringify(action.payload.form));
        let rows = selectedForm.design.rows;
        let copyRows = JSON.parse(JSON.stringify(selectedForm.design.rows));

        for (let i = 0; i < rows.length; i++) {
          if (rows[i].columns[0].type === 'card') {
            copyRows[i].columns[0].index = 0;
            copyRows[i].columns[0].isAddbutton = true;
          }
        }
        selectedForm.design.rows = copyRows;
        return { ...state, selectedForm: selectedForm, events: action.payload.events, success: true }
      }
    case ActionTypes.GET_FORM_BY_NAME_ERROR:
      {
        return {
          success: false,
          error: action.payload
        };
      }
    case ActionTypes.GET_SUBMITTED_DATA_BY_ID_SUCCESS:
      {
        if (state.selectedForm) {
          let selectedForm = JSON.parse(JSON.stringify(state.selectedForm));
          let rows = selectedForm.design.rows;
          let copyRows = JSON.parse(JSON.stringify(selectedForm.design.rows));
          let copyModal = JSON.parse(JSON.stringify(selectedForm.modalData.rows));
          for (let i = 0; i < rows.length; i++) {
            if (rows[i].columns[0].type === 'card') {
              let row = action.payload[0][rows[i].columns[0].name];
              for (let j = 1; j < row.length; j++) {
                let tempRows = JSON.parse(JSON.stringify(rows[i]));
                tempRows.columns[0].index = j
                copyRows.push(tempRows);
                copyModal.push(copyModal[i])
              }
            }
          }
          selectedForm.design.rows = copyRows;
          selectedForm.modalData.rows = copyModal;
          return { ...state, selectedForm: selectedForm, submittedDataToBeEdited: action.payload, success: true }
        } else {
          return { ...state, submittedDataToBeEdited: action.payload, success: true }
        }

      }
    case ActionTypes.GET_SUBMITTED_DATA_BY_ID_ERROR:
      {
        return {
          success: false,
          error: action.payload
        };
      }
    case ActionTypes.SAVE_SUBMITTED_DATA_SUCCESS:
      {
        return {
          ...state,
          success: true
        }
      }
    case ActionTypes.SAVE_SUBMITTED_DATA_ERROR:
      {
        return {
          success: false,
          error: action.payload
        };
      }
    case ActionTypes.GET_WORKFLOW_EVENTS_SUCCESS:
      {
        return {
          ...state,
          success: true,
          events: action.payload
        };
      }
    case ActionTypes.CLEAN_SUBMITTED_DATA:
      {
        return {
          ...state,
          selectedForm: [],
          submittedDataToBeEdited: []
        };
      }
    case ActionTypes.UPDATE_SELECTED_FORM:
      {
        let copyRows = [...state.selectedForm.design.rows];
        let newRows = JSON.parse(JSON.stringify(copyRows[action.outerIndex]))
        newRows.columns[0].index = action.cardIndex + 1
        copyRows.push(newRows);

        let copyModalRows = [...state.selectedForm.modalData.rows];
        copyModalRows.push(copyModalRows[action.outerIndex])

        return {
          ...state,
          selectedForm: {
            ...state.selectedForm,
            design: {
              ...state.selectedForm.design,
              rows: copyRows
            },
            modalData: {
              ...state.selectedForm.modalData,
              rows: copyModalRows
            }
          }
        };
      }
    case ActionTypes.DOWNLOAD_CONTRACT_PDF_SUCCESS: {
      return {
        ...state,
        downlaodPdf: action.payload,
      };
    }
    case ActionTypes.DELETE_CONTRACT_PDF: {
      return {
        ...state,
        loading: true,
        [action.payload.dataUniqueId + '_loading']: true,
        [action.payload.dataUniqueId]: {
          ...state[action.payload.dataUniqueId],
          deleteSuccess: false
        },
      };
    }
    case ActionTypes.DELETE_CONTRACT_PDF_SUCCESS: {
      let document = state.document;
      if (action.payload.dataUniqueId && action.payload.data.ids) {
        // let updateData = state.document?.[action.payload.dataUniqueId]?.data?.filter(i => i._id !== action.payload.data?.ids?.[0])
        let updateData = state.document?.[action.payload.dataUniqueId]?.data?.filter(i => !action.payload.data?.ids?.includes(i._id))
        // let total = state.document?.[action.payload.dataUniqueId]?.total - 1
        let total = state.document?.[action.payload.dataUniqueId]?.total - action.payload.data?.ids?.length
        document = {
          ...state.document,
          [action.payload.dataUniqueId]: {
            ...state.document[action.payload.dataUniqueId],
            data: updateData,
            total: total
          }
        }
      }
      return {
        ...state,
        message: action.payload?.message,
        [action.payload.dataUniqueId + '_loading']: false,
        loading: false,
        [action.payload.dataUniqueId]: {
          ...state[action.payload.dataUniqueId],
          deleteSuccess: true
        },
        document,
      };

      // return {
      //   ...state,
      //   message: action.payload?.message,
      //   loading: false,
      // };
    }
    case ActionTypes.DELETE_CONTRACT_PDF_ERROR: {
      return {
        ...state,
        loading: false,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId]: {
          ...state[action.payload.dataUniqueId],
          deleteSuccess: true
        }
      };
    }
    case ActionTypes.UPDATE_DOCUMENT_TABLES_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: action.payload?.localLoading === true ? false : true,
        [action.payload.dataUniqueId + '_localLoading']: action.payload?.localLoading === true ? true : false
      };
    }
    case ActionTypes.UPDATE_DOCUMENT_TABLES_DATA_SUCCESS: {
      let data = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false,
        document: data,
      }
    }
    case ActionTypes.UPDATE_DOCUMENT_TABLES_DATA_FAILURE: {
      let updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false,
      }
    }
    case ActionTypes.GET_LINEITEM_TABLES_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: action.payload?.localLoading === true ? false : true,
        [action.payload.dataUniqueId + '_localLoading']: action.payload?.localLoading === true ? true : false
      };
    }
    case ActionTypes.GET_LINEITEM_TABLES_DATA_SUCCESS: {
      let data = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false,
        document: data,
      }
    }
    case ActionTypes.GET_LINEITEM_TABLES_DATA_FAILURE: {
      let updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false,
      }
    }
    case ActionTypes.GET_DOCUMENT_DATA:
    case ActionTypes.GET_DOCUMENT_TABLES_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: action.payload?.localLoading === true ? false : true,
        [action.payload.dataUniqueId + '_localLoading']: action.payload?.localLoading === true ? true : false
      };
    }
    // case ActionTypes.GET_DOCUMENT_DATA_SUCCESS: {
    //   let updatedDocument = {...state.document, [action.payload.dataUniqueId]: action.payload.data}
    //   return {
    //     ...state,
    //     [action.payload.dataUniqueId+ '_loading']: false,
    //     document:  updatedDocument,
    //   };
    // }
    case `${ActionTypes['GET_DOCUMENT_DATA_SUCCESS']}_${action.payload?.dataUniqueId}`:
    case `${ActionTypes['GET_DOCUMENT_TABLES_DATA_SUCCESS']}_${action.payload?.dataUniqueId}`: {
      let dataModelId;
      let selectedDataElement = '';
      if (action.payload && action.payload.dataUniqueId) {
        let splittedValue = action.payload.dataUniqueId.split('_');
        if (splittedValue && splittedValue.length) {
          dataModelId = splittedValue[0] + '_dataElements';
          selectedDataElement = splittedValue[1];
        }
      }
      let updatedDocument;
      if (selectedDataElement === 'tables') {
        updatedDocument = {
          ...state.document,
          [action.payload.dataUniqueId]: {
            data: [{
              _id: action.payload.documentId,
              documentAge: 0,
              [selectedDataElement]: action.payload.data
            }]
          }
        }
      } else {
        updatedDocument = {
          ...state.document,
          [action.payload.dataUniqueId]: action.payload.data
        }
      }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
        [action.payload.dataUniqueId + '_localLoading']: false,
        [(state.dataModel?.[dataModelId]?._id || 'noLoad') + '_loading']: false,
        error: {},
        message: null
      };
    }
    case ActionTypes.GET_DOCUMENT_DATA_FAILURE:
    case ActionTypes.GET_DOCUMENT_TABLES_DATA_FAILURE: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: null }
      return {
        ...state,
        message: action.payload.error,
        document: updatedDocument,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false
      };
    }
    case ActionTypes.CREATE_DOCUMENT_TABLES_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: action.payload?.localLoading === true ? false : true,
        [action.payload.dataUniqueId + '_localLoading']: action.payload?.localLoading === true ? true : false
      };
    }
    case `${ActionTypes['CREATE_DOCUMENT_TABLES_DATA_SUCCESS']}_${action.payload?.dataUniqueId}`: {
      let dataModelId;
      let selectedDataElement = '';
      if (action.payload && action.payload.dataUniqueId) {
        let splittedValue = action.payload.dataUniqueId.split('_');
        console.log("create splittedValue ::: *** ", splittedValue);
        if (splittedValue && splittedValue.length) {
          dataModelId = splittedValue[0] + '_dataElements';
          selectedDataElement = splittedValue[1];
        }
      }
      const orgDataUniqueId = action.payload.dataUniqueId.replace("_createTable", "");
      console.log("create payload ::: *** ", action.payload)
      let updatedDocument = {
        ...state.document,
        [orgDataUniqueId]: {
          data: [{
            _id: action.payload.documentId,
            documentAge: 0,
            [selectedDataElement]: action.payload.data
          }]
        }
      }

      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
        [action.payload.dataUniqueId + '_localLoading']: false,
        [(state.dataModel?.[dataModelId]?._id || 'noLoad') + '_loading']: false,
        error: {},
        message: null
      };
    }
    case ActionTypes.CREATE_DOCUMENT_TABLES_DATA_FAILURE: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: [...[action.payload.dataUniqueId]] }
      return {
        ...state,
        message: action.payload.error,
        document: updatedDocument,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false
      };
    }
    case ActionTypes.SUBMIT_CONTRACT_PDF_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case ActionTypes.SUBMIT_CONTRACT_PDF_SUCCESS: {
      return {
        ...state,
        loading: false,
        uploadedPDFData: action.payload
      }
    }
    case ActionTypes.SUBMIT_CONTRACT_PDF_ERROR: {
      return {
        ...state,
        loading: false,
      }
    }
    case ActionTypes.SUBMIT_CONTRACT_PDF_DUPLICATE_ERROR: {
      return {
        ...state,
        loading: false,
        duplicateDocError: action.payload
      }
    }
    case ActionTypes.CLAER_SUBMIT_CONTRACT_PDF_DUPLICATE_ERROR: {
      return {
        ...state,
        duplicateDocError: null
      }
    }
    case ActionTypes.GET_COGNITIVE_SEARCH_DATA: {

      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: action.payload?.localLoading === true ? false : true,
        [action.payload.dataUniqueId + '_localLoading']: action.payload?.localLoading === true ? true : false
      }
    }
    case ActionTypes.GET_COGNITIVE_SEARCH_DATA_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false,
        document: updatedDocument,
      }
    }
    case ActionTypes.GET_COGNITIVE_SEARCH_DATA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false
      }
    }
    case ActionTypes.GET_MODAL_DATA: {
      if (action.payload?.doNotLoad) {
        return {
          ...state,
          // Hardcoded avoid multiple calls after delete in DA view
          [action.payload?.dataUniqueId?.replace('_data', '_deleteDocument')]: false
        }
      } else {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: action.payload?.localLoading === true ? false : true,
          [action.payload.dataUniqueId + '_localLoading']: action.payload?.localLoading === true ? true : false,
          // Hardcoded avoid multiple calls after delete in DA view
          [action.payload?.dataUniqueId?.replace('_data', '_deleteDocument')]: false,
          [action.payload?.dataUniqueId?.replace('_data', '_assignToMe')]: false,
          [action.payload?.dataUniqueId?.replace('_data', '_assign')]: false
        }
      }
    }
    case ActionTypes.GET_MODAL_DATA_SUCCESS: {
      let dataModelId;
      if (action.payload && action.payload.dataUniqueId) {
        let splittedValue = action.payload.dataUniqueId.split('_');
        if (splittedValue && splittedValue.length) {
          dataModelId = splittedValue[0] + '_dataElements';
        }
      }
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false,
        [(state.dataModel?.[dataModelId]?._id || 'noLoad') + '_loading']: false,
        document: updatedDocument,
        ['executeWorkFlowSuccess']: false,
        error: {},
        message: null
      }
    }
    case ActionTypes.GET_MODAL_DATA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false
      }
    }
    case ActionTypes.HIDE_SUCCESS_MSG: {
      return {
        ...state,
        message: null,
      }
    }
    case ActionTypes.GET_COGNITIVE_SEARCH_DATA_V1: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: action.payload?.localLoading === true ? false : true,
        [action.payload.dataUniqueId + '_localLoading']: action.payload?.localLoading === true ? true : false
      }
    }
    case ActionTypes.GET_COGNITIVE_SEARCH_DATA_V1_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false,
        document: updatedDocument,
      }
    }
    case ActionTypes.GET_COGNITIVE_SEARCH_DATA_V1_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false
      }
    }
    case ActionTypes.SAVE_SMART_SEARCH_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: action.payload?.localLoading === true ? false : true,
        [action.payload.dataUniqueId + '_localLoading']: action.payload?.localLoading === true ? true : false
      }
    }
    case ActionTypes.SAVE_SMART_SEARCH_DATA_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false,
        document: updatedDocument,
      }
    }
    case ActionTypes.SAVE_SMART_SEARCH_DATA_FAILURE: {
      let updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false
      }
    }
    case ActionTypes.GET_ALL_SMART_SEARCH_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: action.payload?.localLoading === true ? false : true,
        [action.payload.dataUniqueId + '_localLoading']: action.payload?.localLoading === true ? true : false
      }
    }
    case ActionTypes.GET_ALL_SMART_SEARCH_DATA_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false,
        document: updatedDocument,
      }
    }
    case ActionTypes.GET_ALL_SMART_SEARCH_DATA_FAILURE: {
      let updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false
      }
    }
    case ActionTypes.CLEAR_UPLOAD_PDF_DATA: {
      return {
        ...state,
        uploadedPDFData: null,
        pdfDocument: null,
      }
    }
    case ActionTypes.DOWNLOAD_PDF_ACTION: {
      if (action.payload.pdfLoadRefresh == false) {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true,
        }
      } else {
        return {
          ...state,
          loading1: true,
          [action.payload.dataUniqueId + '_loading']: true,
        }
      }

    }
    case ActionTypes.DOWNLOAD_PDF_ACTION_SUCCESS: {
      if (action.payload.isEmailBody) {
        let updatedPDFObj = { ...state.emailDocument, [action.payload.dataUniqueId]: action.payload }
        return {
          ...state,
          loading1: false,
          emailDocument: updatedPDFObj,
          [action.payload.dataUniqueId + '_loading']: false,
        }
      } else {
        let updatedPDFObj = { ...state.pdfDocument, [action.payload.dataUniqueId]: action.payload }
        if (action.payload.pdfLoadRefresh == false) {
          return {
            ...state,
            loading1: false,
            pdfDocument: updatedPDFObj,
            [action.payload.dataUniqueId + '_loading']: false,
          }
        } else {
          return {
            ...state,
            loading1: false,
            pdfDocument: updatedPDFObj,
            [action.payload.dataUniqueId + '_loading']: false,
          }
        }
      }
    }
    case ActionTypes.DOWNLOAD_PDF_ACTION_FAILURE: {
      if (action.payload.isEmailBody) {
        let updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
        return {
          ...state,
          loading1: false,
          message: action.payload.error,
          error: updatedErrorObj,
          emailDocument: { ...updatedErrorObj, message: action.payload.error, error: action.payload.error },
          [action.payload.dataUniqueId + '_loading']: false,
        }
      } else {
        let updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
        return {
          ...state,
          loading1: false,
          message: action.payload.error,
          error: updatedErrorObj,
          pdfDocument: { ...updatedErrorObj, message: action.payload.error, error: action.payload.error },
          [action.payload.dataUniqueId + '_loading']: false,
        }
      }
    }
    case ActionTypes.DOWNLOAD_AMENDMENTTEMPLATE_ACTION: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.DOWNLOAD_AMENDMENTTEMPLATE_ACTION_SUCCESS: {
      let updatedPDFObj = { ...state.pdfDocument, [action.payload.dataUniqueId]: action.payload }
      return {
        ...state,
        pdfDocument: updatedPDFObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.DOWNLOAD_AMENDMENTTEMPLATE_ACTION_FAILURE: {
      let updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        message: action.payload.error,
        error: updatedErrorObj,
        emailDocument: { ...updatedErrorObj, message: action.payload.error, error: action.payload.error },
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.DOWNLOAD_AMENDMENTTEMPLATE_ACTION_V2: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.DOWNLOAD_AMENDMENTTEMPLATE_ACTION_SUCCESS_V2: {
      let updatedPDFObj = { ...state.pdfDocument, [action.payload.dataUniqueId]: action.payload }
      return {
        ...state,
        pdfDocument: updatedPDFObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.DOWNLOAD_AMENDMENTTEMPLATE_ACTION_FAILURE_V2: {
      let updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        message: action.payload.error,
        error: updatedErrorObj,
        emailDocument: { ...updatedErrorObj, message: action.payload.error, error: action.payload.error },
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.UPDATE_DATA_MODAL_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.UPDATE_DATA_MODAL_DATA_SUCCESS: {
      let message = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: message,
        [action.payload.dataUniqueId + '_error']: ''
      }
    }
    case ActionTypes.UPDATE_DATA_MODAL_DATA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        message: action.payload,
        [action.payload.dataUniqueId + '_error']: action.payload
      }
    }
    case ActionTypes.CREATE_DOCUMENT: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.CREATE_DOCUMENT_SUCCESS: {
      let data = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: data,
      }
    }
    case ActionTypes.CREATE_DOCUMENT_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_error']: action.payload.message || 'Error while saving data'
        // message: action.payload,
      }
    }
    case ActionTypes.CLEAR_SUCCESS_MSG: {
      return {
        ...state,
        successMsg: null,
      }
    }

    case ActionTypes.GET_MODAL_DROP_DOWN_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_MODAL_DROP_DOWN_DATA_SUCCESS: {
      let updatedData = { ...state.externalFilterData, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        externalFilterData: updatedData,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }

    case ActionTypes.GET_MODAL_DROP_DOWN_DATA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.DOWNLOAD_SHEET: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.DOWNLOAD_SHEET_SUCCESS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.DOWNLOAD_SHEET_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.GET_DATA_MODEL_BY_ID: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_DATA_MODEL_BY_ID_SUCCESS: {
      let updatedDataModel = { ...state.dataModel, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        dataModel: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.GET_DATA_MODEL_BY_ID_FAILURE: {
      let updatedDataModel = { ...state.dataModel, [action.payload.dataUniqueId]: false }
      return {
        ...state,
        // dataModel: false,
        dataModel: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.UPDATE_DATA_MODEL_BY_ID: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.UPDATE_DATA_MODEL_BY_ID_SUCCESS: {
      let updatedDataModel = { ...state.dataModel, [action.payload.dataUniqueId]: action.payload.data.updatedDataModel }
      return {
        ...state,
        dataModel: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.UPDATE_DATA_MODEL_BY_ID_FAILURE: {
      return {
        ...state,
        dataModel: null,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.CLEAR_REDUX_DATA_OF_COMPONENT: {
      let dataToClear = action.payload;
      if (Array.isArray(dataToClear.key)) {
        let newState = JSON.parse(JSON.stringify({ ...state }))
        dataToClear.key.forEach(k => {
          delete newState[k]
        })
        return newState;
      } else {
        let _document = { ...state[dataToClear.key] };
        if (Array.isArray(dataToClear?.subKey)) {
          dataToClear?.subKey?.forEach(key => {
            delete _document[key];
          })
        } else {
          delete _document[dataToClear?.subKey]
        }
        return {
          ...state,
          [dataToClear.key]: _document
        }
      }
    }
    case ActionTypes.SAVE_DATA_FOR_TRAINING: {
      return {
        ...state,
        loading: true
      }
    }
    case ActionTypes.SAVE_DATA_FOR_TRAINING_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case ActionTypes.SAVE_DATA_FOR_TRAINING_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }
    case ActionTypes.GET_TOP_SUGGESTIONS: {
      return {
        ...state,
        loading: true
      }
    }
    case ActionTypes.GET_TOP_SUGGESTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        topSuggestions: action.payload
      }
    }
    case ActionTypes.GET_TOP_SUGGESTIONS_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }
    case ActionTypes.ON_SEARCH_TEXT: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.ON_SEARCH_TEXT_SUCCESS: {
      let updatedSearchResults = { ...state.searchResults, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        searchResults: updatedSearchResults,
      }
    }
    case ActionTypes.ON_SEARCH_TEXT_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.GET_LINE_DATA_BY_ID: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_LINE_DATA_BY_ID_SUCCESS: {
      let updatedDataModel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        document: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.GET_LINE_DATA_BY_ID_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.GET_BAR_DATA_BY_ID: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_BAR_DATA_BY_ID_SUCCESS: {
      let updatedDataModel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        document: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.GET_BAR_DATA_BY_ID_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.GET_TASKS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_TASKS_SUCCESS: {
      let updatedSearchResults = { ...state.searchResults, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        tasks: updatedSearchResults,
      }
    }
    case ActionTypes.GET_TASKS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.GET_TASK_DATA_SUCCESS: {
      let updatedSearchResults = { ...state.taskData, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        taskData: updatedSearchResults,
        message: action.payload?.data?.message
      }
    }
    case ActionTypes.GET_TASK_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.GET_TASK_DATA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.UPDATE_TASK_DATA_LOADING: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.UPDATE_TASK_DATA_SUCCESS: {
      let updatedSearchResults = { ...state.taskData, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        taskData: updatedSearchResults,
      }
    }
    case ActionTypes.UPDATE_TASK_DATA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.DELETE_TASK_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.DELETE_TASK_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionTypes.DELETE_TASK_DATA_SUCCESS: {
      return {
        ...state,
        message: action.payload?.message,
        loading: false,
      };
    }
    case ActionTypes.GET_MEMBERS_LIST:
    case ActionTypes.GET_ALL_MEMBERS_LIST: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_MEMBERS_LIST_SUCCESS: {
      let membersListResponse = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: membersListResponse,
      }
    }
    case ActionTypes.GET_ALL_MEMBERS_LIST_SUCCESS: {
      let membersListResponse = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: membersListResponse,
      }
    }
    case ActionTypes.GET_MEMBERS_LIST_FAILURE:
    case ActionTypes.GET_ALL_MEMBERS_LIST_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.PROCESS_AGAIN: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.PROCESS_AGAIN_SUCCESS: {
      let processAgain = { ...state.processAgain, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        processAgain: processAgain,
      }
    }
    case ActionTypes.PROCESS_AGAIN_FAILURE: {
      return {
        ...state,
        processAgain: null,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.GET_AUDIT_TRAILS_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.GET_AUDIT_TRAILS_DATA_SUCCESS: {
      let auditTrails = { ...state.auditTrails, data: action.payload.data, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        auditTrails: auditTrails,
      }
    }
    case ActionTypes.GET_AUDIT_TRAILS_DATA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.GET_OTHERS_AUDIT_TRAILS_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.GET_OTHERS_AUDIT_TRAILS_DATA_SUCCESS: {
      let auditTrails = { ...state.auditTrails, data: action.payload.data, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        auditTrails: auditTrails,
      }
    }
    case ActionTypes.GET_OTHERS_AUDIT_TRAILS_DATA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.BULK_UPLOAD_PDF: {
      return {
        ...state,
        loading: true,
      }
    }
    case ActionTypes.BULK_UPLOAD_PDF_SUCCESS: {
      return {
        ...state,
        loading: false,
        bulkUploadA: action.payload
      }
    }
    case ActionTypes.BULK_UPLOAD_PDF_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case ActionTypes.SERVICE_BUS_DATA: {
      return {
        ...state,
        loading: true,
      }
    }
    case ActionTypes.SERVICE_BUS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        serviceBusData: action.payload
      }
    }
    case ActionTypes.SERVICE_BUS_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case ActionTypes.SERVICE_BUS_TITLE_LIST_STATUS: {
      return {
        ...state,
        loading: true,
      }
    }
    case ActionTypes.SERVICE_BUS_TITLE_LIST_STATUS_SUCCESS: {
      let dataEx = JSON.parse(JSON.stringify({ ...state.document[action.payload?.dataUniqueId + '_data'] }))
      if (dataEx?.data) {
        action.payload?.data.forEach((ele, ind) => {
          let index = dataEx.data.findIndex(element => (element._id === ele._id))
          if (index > -1) {
            dataEx.data[index] = { ...dataEx.data[index], status: action.payload?.data[ind].status }
          }
        })
      }
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId + '_data']: dataEx }
      return {
        ...state,
        loading: false,
        document: updatedDocument
      }
    }
    case ActionTypes.SERVICE_BUS_TITLE_LIST_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case ActionTypes.ASSIGNEE_CHANGE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_' + action.payload.documentId + '_loading']: true,
        [action.payload.dataUniqueId]: {
          ...state[action.payload.dataUniqueId],
          assignSuccess: false
        }
      }
    }
    case ActionTypes.ASSIGNEE_CHANGE_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_' + action.payload.documentId + '_loading']: false,
        document: updatedDocument,
        [action.payload.dataUniqueId]: {
          ...state[action.payload.dataUniqueId],
          assignSuccess: true
        }
      };
    }
    case ActionTypes.ASSIGNEE_CHANGE_FAILURE: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        [action.payload.dataUniqueId + '_' + action.payload.documentId + '_loading']: false,
        document: updatedDocument,
        [action.payload.dataUniqueId]: {
          ...state[action.payload.dataUniqueId],
          assignSuccess: false
        }
      }
    }

    case ActionTypes.COMPLETE_REVIEW: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
        [action.payload.dataUniqueId]: {
          ...state[action.payload.dataUniqueId],
          completeSuccess: false
        }
      }
    }
    case ActionTypes.COMPLETE_REVIEW_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
        [action.payload.dataUniqueId]: {
          ...state[action.payload.dataUniqueId],
          completeSuccess: true
        }
      };
    }
    case ActionTypes.COMPLETE_REVIEW_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId]: {
          ...state[action.payload.dataUniqueId],
          completeSuccess: false
        }
      }
    }

    case ActionTypes.REJECT_REVIEW: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.REJECT_REVIEW_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: [{ reject: true }] }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
      };
    }
    case ActionTypes.REJECT_REVIEW_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.CREATE_MEMBER: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.CREATE_MEMBER_SUCCESS: {
      let membersResponse = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: membersResponse,
      }
    }
    case ActionTypes.CREATE_MEMBER_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.GET_ROLES_MEMBERS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_ROLES_MEMBERS_SUCCESS: {
      let roles = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: roles,
      }
    }
    case ActionTypes.GET_ROLES_MEMBERS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_GROUPS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_GROUPS_SUCCESS: {
      let groups = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: groups,
      }
    }
    case ActionTypes.GET_GROUPS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_ROLES: {
      console.log("getRoles === reducer")
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_ROLES_SUCCESS: {
      let document = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: document,
      }
    }
    case ActionTypes.GET_ROLES_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_ROLE_BY_ID: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_ROLE_BY_ID_SUCCESS: {
      let document = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: document,
      }
    }
    case ActionTypes.GET_ROLE_BY_ID_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.CREATE_ROLE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.CREATE_ROLE_SUCCESS: {
      let document = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: document,
      }
    }
    case ActionTypes.CREATE_ROLE_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_PRIVILEGES: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_PRIVILEGES_SUCCESS: {
      let privileges = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: privileges,
      }
    }
    case ActionTypes.GET_PRIVILEGES_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_PRIVILEGE_BY_ID: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_PRIVILEGE_BY_ID_SUCCESS: {
      let document = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: document,
      }
    }
    case ActionTypes.GET_PRIVILEGE_BY_ID_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.CREATE_PRIVILEGE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.CREATE_PRIVILEGE_SUCCESS: {
      let document = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: document,
      }
    }
    case ActionTypes.CREATE_PRIVILEGE_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_SYSTEM_OPERATIONS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_SYSTEM_OPERATIONS_SUCCESS: {
      let document = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: document,
      }
    }
    case ActionTypes.GET_SYSTEM_OPERATIONS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_WORKFLOW: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_WORKFLOW_SUCCESS: {
      let roles = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: roles,
      }
    }
    case ActionTypes.GET_WORKFLOW_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.GET_NEXT_TASKS:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true
        }
      }
    case ActionTypes.GET_NEXT_TASKS_SUCCESS: {
      let roles = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: roles,
      }
    }
    case ActionTypes.GET_NEXT_TASKS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.REINITIATE_WORKFLOW:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true
        }
      }
    case ActionTypes.REINITIATE_WORKFLOW_SUCCESS: {
      let roles = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        ['executeWorkFlowSuccess']: true,
        document: roles,
      }
    }
    case ActionTypes.REINITIATE_WORKFLOW_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_error']: action.payload.data
      }
    }
    case ActionTypes.EXECUTE_WORKFLOW:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true
        }
      }
    case ActionTypes.EXECUTE_WORKFLOW_SUCCESS: {
      let roles = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        ['executeWorkFlowSuccess']: true,
        document: roles,
      }
    }
    case ActionTypes.EXECUTE_WORKFLOW_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_error']: action.payload.data
      }
    }
    case ActionTypes.INIT_WORKFLOW:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true
        }
      }
    case ActionTypes.INIT_WORKFLOW_SUCCESS: {
      let roles = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: roles,
      }
    }
    case ActionTypes.INIT_WORKFLOW_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.REJECT_WORKFLOW:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true
        }
      }
    case ActionTypes.REJECT_WORKFLOW_SUCCESS: {
      let roles = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: roles,
      }
    }
    case ActionTypes.REJECT_WORKFLOW_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.REJECT_TO_QA:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true
        }
      }
    case ActionTypes.REJECT_TO_QA_SUCCESS: {
      let roles = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: roles,
      }
    }
    case ActionTypes.REJECT_TO_QA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.GET_REVIEW_INFO:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true
        }
      }
    case ActionTypes.GET_REVIEW_INFO_SUCCESS: {
      let roles = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: roles,
      }
    }
    case ActionTypes.GET_REVIEW_INFO_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_PO_INFO_FROM_EXTERNAL_SOURCE:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true
        }
      }
    case ActionTypes.GET_PO_INFO_FROM_EXTERNAL_SOURCE_SUCCESS: {
      let roles = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: roles,
      }
    }
    case ActionTypes.GET_PO_INFO_FROM_EXTERNAL_SOURCE_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.SAGE_INTACCT:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true
        }
      }
    case ActionTypes.SAGE_INTACCT_SUCCESS: {
      let d = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: d,
      }
    }
    case ActionTypes.SAGE_INTACCT_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.GET_CHARGE_CODES:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true
        }
      }
    case ActionTypes.GET_CHARGE_CODES_SUCCESS: {
      let roles = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: roles,
      }
    }
    case ActionTypes.GET_CHARGE_CODES_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.GET_NEXT_RESPONDENT:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true
        }
      }
    case ActionTypes.GET_NEXT_RESPONDENT_SUCCESS: {
      let roles = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: roles,
      }
    }
    case ActionTypes.GET_NEXT_RESPONDENT_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.GET_CURRENT_STEP_RESPONDENT: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_CURRENT_STEP_RESPONDENT_SUCCESS: {
      let roles = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: roles,
      }
    }
    case ActionTypes.GET_CURRENT_STEP_RESPONDENT_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.DELETE_MEMBER: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      };
    }
    case ActionTypes.DELETE_MEMBER_SUCCESS: {
      let deleteData = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: deleteData,
      }
    }
    case ActionTypes.DELETE_MEMBER_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      };
    }

    case ActionTypes.GET_APP_CONFIG: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_APP_CONFIG_SUCCESS: {
      let mailBoxData = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: mailBoxData,
      }
    }
    case ActionTypes.GET_APP_CONFIG_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_MEMBER_PREVILAGES: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_MEMBER_PREVILAGES_SUCCESS: {
      let previlagesData = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: previlagesData,
      }
    }
    case ActionTypes.GET_MEMBER_PREVILAGES_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.SAVE_APP_CONFIG: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.SAVE_APP_CONFIG_SUCCESS: {
      let mailBoxData = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: mailBoxData,
      }
    }
    case ActionTypes.SAVE_APP_CONFIG_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_APP_CONFIG_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.GROUPS_CREATE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GROUPS_CREATE_SUCCESS: {
      let createGroup = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: createGroup,
      }
    }
    case ActionTypes.GROUPS_CREATE_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.DELETE_GROUPS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.DELETE_GROUPS_SUCCESS: {
      return {
        ...state,
        successMsg: action.payload?.data?.message,
        loading: false,
      };
    }
    case ActionTypes.DELETE_GROUPS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case ActionTypes.GET_CHARGE_CODE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_CHARGE_CODE_SUCCESS: {
      let chargeCodeList = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: chargeCodeList,
      }
    }
    case ActionTypes.GET_CHARGE_CODE_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.CREATE_CHARGE_CODE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.CREATE_CHARGE_CODE_SUCCESS: {
      let chargeCoderes = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: chargeCoderes,
      }
    }
    case ActionTypes.CREATE_CHARGE_CODE_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.DELETE_CHARGE_CODE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.DELETE_CHARGE_CODE_SUCCESS: {
      let chargeCodedel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: chargeCodedel,
      }
    }
    case ActionTypes.DELETE_CHARGE_CODE_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_ARCHIVED_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: action.payload?.localLoading === true ? false : true,
        [action.payload.dataUniqueId + '_localLoading']: action.payload?.localLoading === true ? true : false
      }
    }
    case ActionTypes.GET_ARCHIVED_DATA_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false,
        document: updatedDocument,
      }
    }
    case ActionTypes.GET_ARCHIVED_DATA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false
      }
    }

    case ActionTypes.GET_DELETED_DOCUMENT: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: action.payload?.localLoading === true ? false : true,
        [action.payload.dataUniqueId + '_localLoading']: action.payload?.localLoading === true ? true : false
      }
    }
    case ActionTypes.GET_DELETED_DOCUMENT_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false,
        document: updatedDocument,
      }
    }
    case ActionTypes.GET_DELETED_DOCUMENT_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false
      }
    }
    case ActionTypes.GET_DELEGATIONS:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true
        }
      }
    case ActionTypes.GET_DELEGATIONS_SUCCESS: {
      let delegations = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: delegations,
      }
    }
    case ActionTypes.GET_DELEGATIONS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_DELEGATION: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_DELEGATION_SUCCESS: {
      let chargeCodeList = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: chargeCodeList,
      }
    }
    case ActionTypes.GET_DELEGATION_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.CREATE_DELEGATION: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.CREATE_DELEGATION_SUCCESS: {
      let chargeCoderes = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: chargeCoderes,
      }
    }
    case ActionTypes.CREATE_DELEGATION_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.DELETE_DELEGATION: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.DELETE_DELEGATION_SUCCESS: {
      let chargeCodedel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: chargeCodedel,
      }
    }
    case ActionTypes.DELETE_DELEGATION_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_TICKETS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_TICKETS_SUCCESS: {
      let ticketsList = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: ticketsList,
      }
    }
    case ActionTypes.GET_TICKETS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.CREATE_TICKETS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.CREATE_TICKETS_SUCCESS: {
      let ticketCreate = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: ticketCreate,
      }
    }
    case ActionTypes.CREATE_TICKETS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.DELETE_TICKETS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.DELETE_TICKETS_SUCCESS: {
      let deleteTicket = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: deleteTicket,
      }
    }
    case ActionTypes.DELETE_TICKETS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.RESTORE_DELETE_DOC:
    case ActionTypes.RESTORE_OTHERS_DELETED_DOC: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.RESTORE_DELETE_DOC_SUCCESS:
    case ActionTypes.RESTORE_OTHERS_DELETED_DOC_SUCCESS: {
      let restoreDoc = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: restoreDoc,
      }
    }
    case ActionTypes.RESTORE_DELETE_DOC_FAILURE:
    case ActionTypes.RESTORE_OTHERS_DELETED_DOC_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.TERMINATE_MEMBER: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.TERMINATE_MEMBER_SUCCESS: {
      let terminated_data = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: terminated_data,
      }
    }
    case ActionTypes.TERMINATE_MEMBER_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_ALL_OTHER_DOC: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_ALL_OTHER_DELETED_DOC: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_ALL_OTHER_DOC_SUCCESS: {
      let otherDoc_data = { ...state.document, [action.payload.dataUniqueId]: action.payload?.data?.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: otherDoc_data,
      }
    }
    case ActionTypes.GET_ALL_OTHER_DOC_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.DOWNLOAD_ALL_OTHER_DOCS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.DOWNLOAD_ALL_OTHER_DOCS_SUCCESS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.DOWNLOAD_ALL_OTHER_DOCS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.DELETE_ALL_OTHER_DOCS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.DELETE_ALL_OTHER_DOCS_SUCCESS: {
      let deleteData = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: deleteData
      }
    }
    case ActionTypes.DELETE_ALL_OTHER_DOCS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_PDF_FOR_OTHER_DOC: {
      if (action.payload.pdfLoadRefresh == false) {
        return {
          ...state,
          loading1: false,
          [action.payload.dataUniqueId + '_loading']: true,
        }
      } else {
        return {
          ...state,
          loading1: true,
          [action.payload.dataUniqueId + '_loading']: true,
        }
      }
    }
    case ActionTypes.GET_OTHERS_DELETED_DOC: {
      return {
        ...state,
        loading1: true,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }

    case ActionTypes.GET_PDF_FOR_OTHER_DOC_SUCCESS: {
      if (action.payload.isEmailBody) {
        let updatedPDFObj = { ...state.emailDocument, [action.payload.dataUniqueId]: action.payload }
        return {
          ...state,
          loading1: false,
          emailDocument: updatedPDFObj,
          [action.payload.dataUniqueId + '_loading']: false,
        }
      } else {
        let updatedPDFObj = { ...state.pdfDocument, [action.payload.dataUniqueId]: action.payload }
        return {
          ...state,
          loading1: false,
          pdfDocument: updatedPDFObj,
          [action.payload.dataUniqueId + '_loading']: false,
        }
      }
    }
    case ActionTypes.GET_PDF_FOR_OTHER_DOC_FAILURE: {
      let updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        loading1: false,
        message: action.payload,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.OTHER_DOC_MOVE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
        [action.payload.dataUniqueId]: {
          ...state[action.payload.dataUniqueId],
          moveSuccess: false
        }
      }
    }
    case ActionTypes.OTHER_DOC_MOVE_SUCCESS: {
      let move_data = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: move_data,
        [action.payload.dataUniqueId]: {
          ...state[action.payload.dataUniqueId],
          moveSuccess: true
        }
      }
    }
    case ActionTypes.OTHER_DOC_MOVE_DUPLICATE_ERROR: {
      let duplicate_data = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: duplicate_data,
        [action.payload.dataUniqueId]: {
          ...state[action.payload.dataUniqueId],
          moveSuccess: false
        }
      }
    }
    case ActionTypes.OTHER_DOC_MOVE_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.MOVE_TO_DUPLICATES: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
        [action.payload.dataUniqueId]: {
          ...state[action.payload.dataUniqueId],
          moveSuccess: false
        }
      }
    }
    case ActionTypes.MOVE_TO_DUPLICATES_SUCCESS: {
      let move_data = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: move_data,
        [action.payload.dataUniqueId]: {
          ...state[action.payload.dataUniqueId],
          moveSuccess: true
        }
      }
    }
    case ActionTypes.MOVE_TO_DUPLICATES_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.ADD_COMMENT: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.ADD_COMMENT_SUCCESS: {
      let move_data = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: move_data,
      }
    }
    case ActionTypes.ADD_COMMENT_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.ADD_NEW_ENTRY_BY_DATAMODELS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.ADD_NEW_ENTRY_BY_DATAMODELS_SUCCESS: {
      let move_data = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: move_data,
      }
    }
    case ActionTypes.ADD_NEW_ENTRY_BY_DATAMODELS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.GET_GROUPS_BY_MEMBER_ID: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_GROUPS_BY_MEMBER_ID_SUCCESS: {
      let move_data = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: move_data,
      }
    }
    case ActionTypes.GET_GROUPS_BY_MEMBER_ID_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.UPLOAD_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.UPLOAD_DATA_SUCCESS: {
      let move_data = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: move_data,
      }
    }
    case ActionTypes.UPLOAD_DATA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.BULK_ASSIGN: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.BULK_ASSIGN__SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
      };
    }
    case ActionTypes.BULK_ASSIGN__FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_SUPPORTING_dOCUMENTS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.GET_SUPPORTING_dOCUMENTS_SUCCESS: {
      let supportingDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: supportingDocument,
      };
    }
    case ActionTypes.GET_SUPPORTING_dOCUMENTS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.FINAL_DOCUMENT: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.FINAL_DOCUMENT_SUCCESS: {
      let finalDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: finalDocument
      }
    }
    case ActionTypes.FINAL_DOCUMENT_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.DUPLICATES_GET_REQUEST: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.DUPLICATES_GET_REQUEST_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
      }
    }
    case ActionTypes.DUPLICATES_GET_REQUEST_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_DUPLICATE_DOC: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_DUPLICATE_DOC_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
      }
    }
    case ActionTypes.GET_DUPLICATE_DOC_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.DUPLICATES_MOVE_REQUEST: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.DUPLICATES_MOVE_REQUEST_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
      }
    }
    case ActionTypes.DUPLICATES_MOVE_REQUEST_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.SPLIT_PDF: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.SPLIT_PDF_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
      }
    }
    case ActionTypes.SPLIT_PDF_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.UPLOAD_SUPPORT_DOCUMENT: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.UPLOAD_SUPPORT_DOCUMENT_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
      }
    }
    /**
 * Handles the action to initiate the upload of an attachment for an opportunity.
 * Sets a loading state to indicate that the upload process has started.
 * 
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The dispatched action containing the payload.
 * @returns {Object} - The updated state with the loading flag set to true.
 */
    case ActionTypes.UPLOAD_ATTACHEMENT_OF_OPPORTUNITY: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    /**
 * Handles the success of an attachment upload for an opportunity.
 * Updates the state with the new document data and sets the loading flag to false.
 * 
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The dispatched action containing the payload with the uploaded data.
 * @returns {Object} - The updated state with the new document data and loading flag set to false.
 */
    case ActionTypes.UPLOAD_ATTACHEMENT_OF_OPPORTUNITY_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
      }
    }
    /**
 * Handles the failure of an attachment upload for an opportunity.
 * Sets the loading flag to false, indicating that the upload process has failed or been interrupted.
 * 
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The dispatched action containing the payload.
 * @returns {Object} - The updated state with the loading flag set to false.
 */
    case ActionTypes.UPLOAD_ATTACHEMENT_OF_OPPORTUNITY_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.UPLOAD_ATTACHEMENT_OF_AMENDMENTTEMPLATE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    /**
 * Handles the success of an attachment upload for an opportunity.
 * Updates the state with the new document data and sets the loading flag to false.
 * 
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The dispatched action containing the payload with the uploaded data.
 * @returns {Object} - The updated state with the new document data and loading flag set to false.
 */
    case ActionTypes.UPLOAD_ATTACHEMENT_OF_AMENDMENTTEMPLATE_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
      }
    }
    /**
 * Handles the failure of an attachment upload for an opportunity.
 * Sets the loading flag to false, indicating that the upload process has failed or been interrupted.
 * 
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The dispatched action containing the payload.
 * @returns {Object} - The updated state with the loading flag set to false.
 */
    case ActionTypes.UPLOAD_ATTACHEMENT_OF_AMENDMENTTEMPLATE_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.UPLOAD_SUPPORT_DOCUMENT_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.GET_VIS_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_VIS_DATA_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
      }
    }
    case ActionTypes.GET_VIS_DATA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.LOCK_DOCUMENT:
    case ActionTypes.UNLOCK_DOCUMENT:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true
        }
      }
    case ActionTypes.LOCK_DOCUMENT_SUCCESS:
    case ActionTypes.UNLOCK_DOCUMENT_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
      }
    }
    case ActionTypes.LOCK_DOCUMENT_FAILURE:
    case ActionTypes.UNLOCK_DOCUMENT_FAILURE:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: false,
        }
      }

    case ActionTypes.GET_WORKFLOW_BY_ID:
      {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: true
        }
      }
    case ActionTypes.GET_WORKFLOW_BY_ID_SUCCESS: {
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
      }
    }
    case ActionTypes.GET_WORKFLOW_BY_ID_FAILURE: {
      let updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.FETCH_MASTER_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.FETCH_MASTER_DATA_SUCCESS: {
      let updatedDataModel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        document: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.FETCH_MASTER_DATA_FAILURE: {
      return {
        ...state,
        // dataModel: false,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.GET_HISTORY_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.GET_HISTORY_DATA_SUCCESS: {
      let historyData = { ...state.historyData, data: action.payload.data, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        historyData: historyData,
      }
    }
    case ActionTypes.GET_HISTORY_DATA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.FETCH_PROCESS_DASHBOARD_1_DATA:
    case ActionTypes.FETCH_PROCESS_DASHBOARD_2_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
      }
    }
    case ActionTypes.FETCH_PROCESS_DASHBOARD_1_DATA_SUCCESS:
    case ActionTypes.FETCH_PROCESS_DASHBOARD_2_DATA_SUCCESS: {
      const selectedChartType = state.document?.selectedChartItems;
      let updatedDocument = {}
      if (selectedChartType) {
        updatedDocument = {
          ...state.document,
          [action.payload.dataUniqueId]: {
            ...action.payload.data,
            [selectedChartType]: state.document?.[action.payload.dataUniqueId]?.[selectedChartType]
          }
        }
      } else {
        updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedDocument,
      }
    }
    case ActionTypes.FETCH_PROCESS_DASHBOARD_1_DATA_FAILURE:
    case ActionTypes.FETCH_PROCESS_DASHBOARD_2_DATA_FAILURE: {
      let updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }
    case ActionTypes.SELECTED_DASHBOARD_CHART_ITEMS: {
      let updateDocument = { ...state.document, selectedChartItems: action.payload }
      return {
        ...state,
        document: updateDocument
      }
    }
    case ActionTypes.CREATE_AMENDMENT: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.CREATE_AMENDMENT_SUCCESS: {
      let updatedDataModel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        document: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.CREATE_AMENDMENT_FAILURE: {
      return {
        ...state,
        // dataModel: false,
        [action.payload.dataUniqueId + '_loading']: false
      }
    } 
    case ActionTypes.CREATE_AMENDMENT_V2:
    case ActionTypes.CREATE_AMENDMENT_NEW_V2: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.CREATE_AMENDMENT_V2_SUCCESS:
    case ActionTypes.CREATE_AMENDMENT_NEW_V2_SUCCESS:
      {
        let updatedDataModel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
        return {
          ...state,
          document: updatedDataModel,
          [action.payload.dataUniqueId + '_loading']: false
        }
      }
    case ActionTypes.CREATE_AMENDMENT_V2_FAILURE:
    case ActionTypes.CREATE_AMENDMENT_NEW_V2_FAILURE:
      {
        return {
          ...state,
          // dataModel: false,
          [action.payload.dataUniqueId + '_loading']: false
        }
      }

    case ActionTypes.GET_AMENDMENTS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_AMENDMENTS_SUCCESS: {
      let updatedDataModel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        document: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.GET_AMENDMENTS_FAILURE: {
      return {
        ...state,
        // dataModel: false,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }

    case ActionTypes.GET_NOTDUPLICATE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_NOTDUPLICATE_SUCCESS: {
      let updatedDataModel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        document: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.GET_NOTDUPLICATE_FAILURE: {
      return {
        ...state,
        // dataModel: false,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.CREATE_CONTRACT_HIERARCHY: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.CREATE_CONTRACT_HIERARCHY_SUCCESS: {
      let updatedDataModel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        document: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.CREATE_CONTRACT_HIERARCHY_FAILURE: {
      return {
        ...state,
        // dataModel: false,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }

    case ActionTypes.GET_CONTRACT_HIERARCHYS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_CONTRACT_HIERARCHYS_SUCCESS: {
      let updatedDataModel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        document: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.GET_CONTRACT_HIERARCHYS_FAILURE: {
      return {
        ...state,
        // dataModel: false,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }

    case ActionTypes.DELETE_TREE_ELEMENTS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.DELETE_TREE_ELEMENTS_SUCCESS: {
      let updatedDataModel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        document: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.DELETE_TREE_ELEMENTS_FAILURE: {
      return {
        ...state,
        // dataModel: false,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }

    case ActionTypes.GET_DOCUMENT_HIERARCHYS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_DOCUMENT_HIERARCHYS_SUCCESS: {
      let updatedDataModel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        document: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.GET_DOCUMENT_HIERARCHYS_FAILURE: {
      return {
        ...state,
        // dataModel: false,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.GET_DOCUMENT_HIERARCHYS_NEWV2: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_DOCUMENT_HIERARCHYS_SUCCESS_NEWV2: {
      let updatedDataModel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        document: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.GET_DOCUMENT_HIERARCHYS_FAILURE_NEWV2: {
      return {
        ...state,
        // dataModel: false,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.UPDATE_DOCUMENT_HIERARCHYS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      };
    }
    case ActionTypes.UPDATE_DOCUMENT_HIERARCHYS_SUCCESS: {
      let updatedDataModel = {
        ...state.document,
        [action.payload.dataUniqueId]: action.payload.data
      };
      return {
        ...state,
        document: updatedDataModel, // Updates the document hierarchy with the new data
        [action.payload.dataUniqueId + '_loading']: false // Turns off the loading state
      };
    }
    case ActionTypes.UPDATE_DOCUMENT_HIERARCHYS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false // Ensures loading is false after failure
      };
    }
    case ActionTypes.GET_DOCUMENTS_FOR_HIERARCHYS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_DOCUMENTS_FOR_HIERARCHYS_SUCCESS: {
      let updatedDataModel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        document: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.GET_DOCUMENTS_FOR_HIERARCHYS_FAILURE: {
      return {
        ...state,
        // dataModel: false,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }

    case ActionTypes.SAVE_DOCUMENT_HIERARCHY: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.SAVE_DOCUMENT_HIERARCHY_SUCCESS: {
      let updatedDataModel = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        document: updatedDataModel,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.SAVE_DOCUMENT_HIERARCHY_FAILURE: {
      return {
        ...state,
        // dataModel: false,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }

    case ActionTypes.SAVE_DOCUMENT_PROMPT: {
      let tempDocumentQuestions = [...state?.document?.[action.payload.dataUniqueId] || []];
      const newPrompt = {
        queryText: action.payload?.query || action.payload?.question,
        aiType: action.payload?.aiType,
        _id: 'newPrompt',
        questionId: 'newPrompt',
      }
      tempDocumentQuestions.push(newPrompt);
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true,
        document: {
          ...state?.document,
          [action.payload.dataUniqueId]: tempDocumentQuestions
        }
      }
    }
    case ActionTypes.SAVE_DOCUMENT_PROMPT_SUCCESS: {
      let tempDocumentQuestions = [...state?.document?.[action.payload.dataUniqueId] || []];
      const newPromptQuestionindex = tempDocumentQuestions?.findIndex(prompt => prompt?._id === 'newPrompt');

      if (newPromptQuestionindex > -1) {
        tempDocumentQuestions[newPromptQuestionindex] = { ...tempDocumentQuestions[newPromptQuestionindex] }
        tempDocumentQuestions[newPromptQuestionindex] = {
          ...tempDocumentQuestions[newPromptQuestionindex],
          ...action.payload.data,
          _id: action.payload.data.questionId
        }
      }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: {
          ...state?.document,
          [action.payload.dataUniqueId]: tempDocumentQuestions
        }
      }
    }
    case ActionTypes.SAVE_DOCUMENT_PROMPT_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }
    case ActionTypes.FETCH_DOCUMENT_QUESTIONS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.FETCH_DOCUMENT_QUESTIONS_SUCCESS: {
      let updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.FETCH_DOCUMENT_QUESTIONS_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }

    case ActionTypes.EMAIL_SEND_NOTIFICATIONS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }

    case ActionTypes.EMAIL_SEND_NOTIFICATIONS_SUCCESS: {
      let updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.EMAIL_SEND_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }

    case ActionTypes.FETCH_DOCUMENT_DATA_FROM_TARGET_DATAMODEL: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.FETCH_DOCUMENT_DATA_FROM_TARGET_DATAMODEL_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.FETCH_DOCUMENT_DATA_FROM_TARGET_DATAMODEL_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }

    case ActionTypes.FETCH_DOCUMENT_DATA_TO_SUPPORT_MAPPING: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.FETCH_DOCUMENT_DATA_TO_SUPPORT_MAPPING_SUCCESS: {
      let updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.FETCH_DOCUMENT_DATA_TO_SUPPORT_MAPPING_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }

    case ActionTypes.UPDATE_MAPPING_DATA: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }

    case ActionTypes.UPDATE_MAPPING_DATA_SUCCESS: {
      let updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.UPDATE_MAPPING_DATA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }

    case ActionTypes.FETCH_DOCUMENT_DATA_TO_SUPPORT_THREE_WAY_MATCH: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.FETCH_DOCUMENT_DATA_TO_SUPPORT_THREE_WAY_MATCH_SUCCESS: {
      let updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.FETCH_DOCUMENT_DATA_TO_SUPPORT_THREE_WAY_MATCH_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false
      }
    }

    case ActionTypes.GET_DOCUMENT_ANNOTATIONS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_DOCUMENT_ANNOTATIONS_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.GET_DOCUMENT_ANNOTATIONS_FAILURE: {
      const updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.CREATE_OPPORTUNITY: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.CREATE_OPPORTUNITY_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.CREATE_OPPORTUNITY_FAILURE: {
      const updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_ALL_OPPORTUNITIES: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_ALL_OPPORTUNITIES_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.GET_ALL_OPPORTUNITIES_FAILURE: {
      const updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_SINGLE_OPPORTUNITY: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_SINGLE_OPPORTUNITY_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.GET_SINGLE_OPPORTUNITY_FAILURE: {
      const updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.DELETE_OPPORTUNITY: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.DELETE_OPPORTUNITY_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.DELETE_OPPORTUNITY_FAILURE: {
      const updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.UPDATE_OPPORTUNITY: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.UPDATE_OPPORTUNITY_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.UPDATE_OPPORTUNITY_FAILURE: {
      const updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.CREATE_ACTION_FOR_OPPORTUNITY: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.CREATE_ACTION_FOR_OPPORTUNITY_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.CREATE_ACTION_FOR_OPPORTUNITY_FAILURE: {
      const updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_ALL_ACTION_OF_OPPORTUNITY: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_ALL_ACTION_OF_OPPORTUNITY_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.GET_ALL_ACTION_OF_OPPORTUNITY_FAILURE: {
      const updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_SINGLE_ACTION_OF_OPPORTUNITY: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_SINGLE_ACTION_OF_OPPORTUNITY_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.GET_SINGLE_ACTION_OF_OPPORTUNITY_FAILURE: {
      const updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.UPDATE_SINGLE_ACTION_OF_OPPORTUNITY: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.UPDATE_SINGLE_ACTION_OF_OPPORTUNITY_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.UPDATE_SINGLE_ACTION_OF_OPPORTUNITY_FAILURE: {
      const updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.DELETE_SINGLE_ACTION_OF_OPPORTUNITY: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    /**
 * Handles the action to initiate the deletion of attachments for an opportunity.
 * Sets a loading state to indicate that the deletion process has started.
 * 
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The dispatched action containing the payload.
 * @returns {Object} - The updated state with the loading flag set to true for the specific `dataUniqueId`.
 */

    case ActionTypes.DELETE_ATTACHMENTS_FOR_OPPORTUNITY: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }

    case ActionTypes.DELETE_SINGLE_ACTION_OF_OPPORTUNITY_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    /**
 * Handles the successful deletion of attachments for an opportunity.
 * Updates the state to remove the deleted attachments and sets the loading flag to false.
 * 
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The dispatched action containing the payload with the deleted data.
 * @returns {Object} - The updated state with the deleted attachments removed and loading flag set to false.
 */

    case ActionTypes.DELETE_ATTACHMENTS_FOR_OPPORTUNITY_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }

    /**
 * Handles the failure of the attachment deletion process for an opportunity.
 * Updates the state to reflect the error that occurred and sets the loading flag to false.
 * 
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The dispatched action containing the payload with the error details.
 * @param {string} action.payload.dataUniqueId - The unique identifier for the data related to the failed deletion.
 * @param {Object} action.payload.error - The error object or message describing the failure.
 * @returns {Object} - The updated state with the error details and loading flag set to false.
 */

    case ActionTypes.DELETE_ATTACHMENTS_FOR_OPPORTUNITY_FAILURE: {
      const updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.DELETE_SINGLE_ACTION_OF_OPPORTUNITY_FAILURE: {
      const updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_DOCUMENTS_FROM_IDS_OF_OPPORTUNITY: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.GET_DOCUMENTS_FROM_IDS_OF_OPPORTUNITY_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.GET_DOCUMENTS_FROM_IDS_OF_OPPORTUNITY_FAILURE: {
      const updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.RESOLVE_INTEGRATION_EXCEPTIONS: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: true
      }
    }
    case ActionTypes.RESOLVE_INTEGRATION_EXCEPTIONS_SUCCESS: {
      const updatedStateDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        document: updatedStateDocument
      }
    }
    case ActionTypes.RESOLVE_INTEGRATION_EXCEPTIONS_FAILURE: {
      const updatedErrorObj = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
      return {
        ...state,
        error: updatedErrorObj,
        [action.payload.dataUniqueId + '_loading']: false,
      }
    }

    case ActionTypes.GET_SUB_MODAL_DATA: {
      if (action.payload?.doNotLoad) {
        return {
          ...state,
          // Hardcoded avoid multiple calls after delete in DA view
          [action.payload?.dataUniqueId?.replace('_data', '_deleteDocument')]: false
        }
      } else {
        return {
          ...state,
          [action.payload.dataUniqueId + '_loading']: action.payload?.localLoading === true ? false : true,
          [action.payload.dataUniqueId + '_localLoading']: action.payload?.localLoading === true ? true : false,
          // Hardcoded avoid multiple calls after delete in DA view
          [action.payload?.dataUniqueId?.replace('_data', '_deleteDocument')]: false,
          [action.payload?.dataUniqueId?.replace('_data', '_assignToMe')]: false,
          [action.payload?.dataUniqueId?.replace('_data', '_assign')]: false
        }
      }
    }
    case ActionTypes.GET_SUB_MODAL_DATA_SUCCESS: {
      let dataModelId;
      if (action.payload && action.payload.dataUniqueId) {
        let splittedValue = action.payload.dataUniqueId.split('_');
        if (splittedValue && splittedValue.length) {
          dataModelId = splittedValue[0] + '_dataElements';
        }
      }
      let updatedDocument = { ...state.document, [action.payload.dataUniqueId]: action.payload.data }
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false,
        [(state.dataModel?.[dataModelId]?._id || 'noLoad') + '_loading']: false,
        document: updatedDocument,
        ['executeWorkFlowSuccess']: false,
        error: {},
        message: null
      }
    }
    case ActionTypes.GET_SUB_MODAL_DATA_FAILURE: {
      return {
        ...state,
        [action.payload.dataUniqueId + '_loading']: false,
        [action.payload.dataUniqueId + '_localLoading']: false
      }
    }


    default: {
      return state
    }
  }
};

export default form;