import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { PdfjsViewer } from "dsilo-ui-components";
import { Loader } from "../loader";


const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 720,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: 650
    },
}));

/**
 * Returns the style object for positioning the modal at the center of the screen.
 * @returns {Object} The style object for the modal's positioning and overflow settings.
 */
function getModalStyle() {
    const top = 50
    const left = 50
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        overflow: 'auto'
    };
}

const ShowFileUploadModel = (props) => {

    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [pdf, setPdf] = useState(null);

    const handleClose = () => {
        setOpen(false);
        props.handlesQuickPDFClose()
    };

    const fetchData = () => {
        props.downloadAmendmentTemplateAction({
            appid: props.appId,
            _id : props.templateId,
            dataModelId: props.dataModelId,
            payload:{
                view: true,
                dataElement:"template",
            },
            dataUniqueId: "quickView_amendment_template"
        })
    }

    /**
     * Clears the Redux store data related to the current component.
     */
    const clearCurrentChartReduxStore = () => {
        props.clearReduxDataOfCurrentComponent({
            key: "pdfDocument",
            subKey: ['quickView_amendment_template', 'error', 'message']
        })
    }

    useEffect(() => {
        fetchData()
        return () => {
            clearCurrentChartReduxStore()
        }
    }, [])

    useEffect(() => {
        if (props.formData?.pdfDocument?.['quickView_amendment_template']?.pdf) {
            setPdf(new window.Blob([props.formData?.pdfDocument['quickView_amendment_template']?.pdf], { type: "application/pdf" }))
        }
    }, [props?.formData?.pdfDocument])

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    {
                        (props.formData?.['quickView_amendment_template_loading']) ? <Loader/> :

                            (pdf && <PdfjsViewer pdf={pdf} />)
                    }
                </div>
            </Modal>
        </>
    )
}

export default ShowFileUploadModel