import React from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { PDFComplexViewV3 } from 'dsilo-ui-components';
import {
    clearSuccessMsg,
    downloadContractPDFAction,
    downloadSheet,
    getContractDataById,
    getEventByID,
    updateContractAnnotations,
    getDataModelById,
    getModelData,
    getModelDataDropDown,
    initiateAction,
    updateDataModelData,
    getMembersList,
    clearReduxDataOfCurrentComponent,
    getAuditTrailsData,
    completeReviewAction,
    getDataModels,
    otherDocMove,
    getHistoryData,
    getWorkflow,
    getGroupsByMemberId,
    getNextRespondent,
    getNextTasks,
    reInitiateWorkflow,
    executeWorkFlow,
    executeWorkFlowReject,
    changeAssigneeRequest,
    addComment,
    rejectReviewAction,
    getGroups,
    fetchmasterDataForDataelementUsingRules,
    createDocumentTableData,
    getDocumentTablesDataById,
    updateDocumentTableData,
    deleteDocumentTableData,
    moveToDuplicates,
    getLineItemsTablesData,
    addNewEntriesByDataModel,
    createAmendment,
    getAmendments,
    createContractHierarchy,
    getContractHierarchys,
    deleteTreeElements,
    createNewDocumentPrompt,
    getDocumentQuestions,
    getSupportingDocuments,
    uploadSupportDocument,
    emailSendNotification,
    splitPDF,
    getDocumentHierarchys,
    updateDocHierarchys,
    getDocumentHierarchysV2,
    uploadAttachmentAmendmentTemplate,
    createAmendmentV2,
    getSubModelData
} from '../../store/actions';
import { Loader } from '../loader';
import { PUBLIC_PATH } from '../../constants';

const PDFComplexViewV3Component = props => {
    return (
        <div>
            <PDFComplexViewV3
                {...props}
                env={process.env}
                loader={<Loader />}
                jwtAuthToken={localStorage.getItem('jwt_access_token') || ''}
            />
        </div>
    );
};

const mapDispatchToProps = {
    getContractDataById,
    updateDataModelData,
    clearSuccessMsg,
    downloadSheet,
    getModelDataDropDown,
    downloadContractPDFAction,
    getEventByID,
    initiateAction,
    getMembersList,
    clearReduxDataOfCurrentComponent,
    updateContractAnnotations,
    getDataModelById,
    getAuditTrailsData,
    getModelData,
    completeReviewAction,
    getDataModels,
    otherDocMove,
    getHistoryData,
    getWorkflow,
    getGroupsByMemberId, getNextRespondent,
    getNextTasks,
    reInitiateWorkflow,
    executeWorkFlow,
    executeWorkFlowReject,
    changeAssigneeRequest,
    addComment,
    rejectReviewAction,
    getGroups,
    fetchmasterDataForDataelementUsingRules,
    createDocumentTableData,
    getDocumentTablesDataById,
    updateDocumentTableData,
    deleteDocumentTableData,
    moveToDuplicates,
    getLineItemsTablesData,
    addNewEntriesByDataModel,
    createAmendment,
    getAmendments,
    createContractHierarchy,
    getContractHierarchys,
    deleteTreeElements,
    createNewDocumentPrompt,
    getDocumentQuestions,
    getSupportingDocuments,
    uploadSupportDocument,
    emailSendNotification,
    splitPDF,
    getDocumentHierarchys,
    updateDocHierarchys,
    getDocumentHierarchysV2,
    uploadAttachmentAmendmentTemplate,
    createAmendmentV2,
    getSubModelData
};

const mapStateToProps = state => {
    return {
        action: state.action,
        formData: state.form,
        PUBLIC_PATH: PUBLIC_PATH,
        dataModels: state.dataModels,
        appConfig: state.appConfig.app,
        members: state.appConfig.members,
        currentMember: state.appConfig.members,
        user: state.user,
    };
};

export default hot(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFComplexViewV3Component)),
);