import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import jwtDecode from 'jwt-decode';
import { CryptoHelper } from 'dsilo-ui-components'
import { compareDatesByMinutes } from '../../utility/utils'

const SilentSSO = (props) => {

	const { emailCheckData } = props.login
	const { restart } = props
	const [lastTriggeredAT, setLastTriggeredAt] = useState()

	useEffect(() => {
		if (restart)
			startSilentSSO()
	}, [restart])

	// IFRAME Silent SSO
	// IFRAME will reload after successful SSO
	// This will force the Component (SilentSSO) restart
	// SSO will be retriggered after 70% of client systems time period or 30 minutes which ever is less
	useEffect(() => {
		let interval
		const initSilentSSO = async () => {
			console.log("useEffect initialized <><><>")
			let token = localStorage.getItem('jwt_access_token');
			const decoded = jwtDecode(token);
			console.log("decoded ====", decoded)
			const defaultSessionTimeout = 30 * 60 * 1000; // 30 minutes in ms
			const refreshTime = Math.min(decoded?.session ?? defaultSessionTimeout, defaultSessionTimeout);
			console.log("refreshTime ====", refreshTime)
			interval = setInterval(async () => {
				// const interval = setTimeout(async () => {
				console.log("Silent SSO Interval initiated <><><>")
				await startSilentSSO()
			}, refreshTime) // Every 30 minutes or 70% of session time
		}
		initSilentSSO()

		/**
		 * To recognize the iframe refresh
		 * handleMessage - event on refresh
		 * */
		const handleMessage = (event) => {
			if (event.data === "iframeRefreshed") {
				console.log("Iframe refreshed (message from iframe)!");
			}
		};

		window.addEventListener("message", handleMessage);

		return () => {
			clearInterval(interval) // Cleanup on component unmount
			// To remove window message
			window.removeEventListener("message", handleMessage);
		};
	}, [])

	const getDecryptedConfig = async (encryptedConfig, iv) => {
		if (encryptedConfig && typeof encryptedConfig === 'string' && iv) {
			const decryptedConfig = await CryptoHelper.decodeData(encryptedConfig, iv, props?.secretKey)
			if (decryptedConfig) return JSON.parse(decryptedConfig)
			return null
		}
		return encryptedConfig
	}

	const handleConfig = async (configType, configData) => {
		if (configData?.[`${configType}Enable`] && configData?.[`${configType}Config`]) {
			// const decryptedConfig = await getDecryptedConfig(configData[`${configType}Config`], configData?.iv)
			let decryptedConfig = configData[`${configType}Config`]
			if (decryptedConfig && decryptedConfig.enable) {
				const finalConfig = {
					...decryptedConfig,
					orgId: configData?.orgId,
				}
				localStorage.setItem(`${configType}Config`, window.btoa(JSON.stringify(finalConfig)))
				if (configType === 'sso') {
					return finalConfig?.saml?.ssoURL
				}
			}
		}
	}

	// Function to trigger silent re-authentication after 30 minutes
	// compareDatesByMinutes is used to find trigger difference is 30 mins
	const startSilentSSO = async () => {
		let userEmailReport = localStorage.getItem('userEmailReport')
		let token = localStorage.getItem('jwt_access_token');
		const decoded = jwtDecode(token);
		const THIRTY_MINUTES = 30; // 30 minutes

		// Convert decoded.session from ms to minutes
		const sessionFromToken = decoded?.session && typeof decoded?.session === 'number'
		? decoded.session / (60 * 1000)
		: THIRTY_MINUTES

		// Take the smaller of the two
		const effectiveSession = Math.min(sessionFromToken, THIRTY_MINUTES);
		console.log("effectiveSession ====", effectiveSession)

		console.log("Silent SSO Triggered time", new Date(), lastTriggeredAT)
		console.log("Silent SSO Triggered time - diff, props.e401, isSsoLogin", !compareDatesByMinutes(new Date(), lastTriggeredAT, effectiveSession), props.e401)

		if (userEmailReport && (!compareDatesByMinutes(new Date(), lastTriggeredAT, effectiveSession) || props.e401)) {
			let emailCheckData = JSON.parse(window.atob(userEmailReport))
			let isSsoLogin = localStorage.getItem('isSsoLogin')
			console.log("isSsoLogin ====", isSsoLogin)
			if (typeof emailCheckData === 'object') {
				if (emailCheckData?.adEnable && emailCheckData?.adConfig) {
				} else if (emailCheckData?.ssoEnable && emailCheckData?.ssoConfig && isSsoLogin) {
					if (emailCheckData && Object.keys(emailCheckData)?.length) {
						if (emailCheckData?.ssoEnable && emailCheckData?.ssoConfig) {
							let url = await handleConfig('sso', emailCheckData)
							console.log("Silent SSO Triggered <><><>", url)
							if (window.self !== window.top) {
								console.log("Silent SSO Triggered --- The code is running inside an iframe <><><>")
							} else {
								console.log("Silent SSO Triggered --- The code is NOT running inside an iframe <><><>, Process started.", new Date())
								const iframe = document.getElementById('silent-sso-iframe')
								if (iframe) {
									iframe.src = url // Update the iframe's src to trigger silent SSO
									setLastTriggeredAt(new Date())
								}
							}
						}
					}
				}
			}
		} else {
			if (!userEmailReport)
				console.log("No userEmailReport object found")
			else if(!compareDatesByMinutes(new Date(), lastTriggeredAT, effectiveSession))
				console.log(`Trigger difference is more than ${effectiveSession} minutes`)
		}
	}

	return (
		<iframe
			id="silent-sso-iframe"
			// src="/silent-sso"
			style={{ display: 'none' }} // Keep the iframe hidden
			title="Silent SSO"
		/>
	)
}

const mapDispatchToProps = {}

const mapStateToProps = state => {
	console.log("state.login ====", state.login)
	return {
		login: state.login
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SilentSSO)
// export default SilentSSO