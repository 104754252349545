import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { makeStyles, Paper } from "@material-ui/core";
import moment from 'moment';

import { Loader } from '../loader'
import ConfirmationHandler from '../../components/ConfirmDialog'
import { AdvancedTable, TableSupport } from 'dsilo-ui-components'
import { getDelegations, deleteDelegation, clearReduxDataOfCurrentComponent } from "../../store/actions";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    headerClass: {
        backgroundColor: "#f6f9fc",
        textTransform: 'capitalize',
    },
    confirmationDialoge: {
        "& .MuiDialog-paperWidthSm": {
            height: "250px",
            width: "600px"
        }
    },
    para: {
        fontSize: "28px !important",
        fontWeight: 600,
        color: "#000"
    },
    table: {
        '& td': {
            padding: '4px !important',
            textAlign: 'center',
        },
        margin: '15px 5px 0 5px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 10,
    },
}))

const sharedJSON = {
    "order": "asc",
    "orderBy": null,
    "selected": [],
    "tableData": [],
    "filterTableData": [],
    // "page": 0,
    "count": 10,
    // "rowsPerPage": 10,
    "searchText": "",
    "filterList": [],
    "filterData": []
}

const tableJson = [
    {
        id: 'user',
        numeric: false,
        disablePadding: false,
        label: 'User',
        sort: true,
    },
    {
        id: 'delegate',
        numeric: false,
        disablePadding: false,
        label: 'Delegate',
        sort: true,
    },
    {
        id: 'from',
        numeric: false,
        disablePadding: false,
        label: 'From Date',
        sort: true,
    },
    {
        id: 'to',
        numeric: false,
        disablePadding: false,
        label: 'To Date',
        sort: true,
    },
    {
        id: 'keyValueFields',
        numeric: false,
        disablePadding: false,
        label: 'Key Data Fields',
        sort: true,
    },

];

const Delegation = (props) => {
    console.log('Delegation-props', props)
    const { data } = props
    const { chartData } = data

    const classes = useStyles()
    const [delegationsList, setDelegationsList] = useState([])
    const [state, setState] = useState({ ...sharedJSON, });
    const [searchedText, setSearchedText] = useState('');
    const [deleteConfirmState, setDeleteConfirm] = useState({
        isOpen: false,
        deleteMessage: "Are you sure you want to delete ?",
        data: null
    })
    const [fetch, setFetch] = useState(false)

    const { _id: userId } = props.member
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [localStorageObject, setLocalStorageObject] = useState({})
    const [isHighlighted, setHighLighted] = useState({});
    const pageUrl = props.page?.url
    const [highLighterIdForSelectedLineItem, setHighLighterIdForSelectedLineItem] = useState('');

    useEffect(() => {
        let localStoreJson = localStorage.getItem('userSession');
        let currentUserSes, _currSessPage = page || 0, _currSessRPP = rowsPerPage
        if (localStoreJson) {
            let currentSession = JSON.parse(atob(localStoreJson));
            if (Object.keys(currentSession).length > 0) {
                if (currentSession && currentSession[userId] && currentSession[userId][pageUrl]) {
                    currentUserSes = currentSession[userId][pageUrl];
                    if (currentUserSes?.page) {
                        _currSessPage = Number(currentUserSes?.page)
                        setPage(_currSessPage)
                    }
                    if (currentUserSes?.rowsPerPage) {
                        _currSessRPP = Number(currentUserSes?.rowsPerPage)
                        setRowsPerPage(_currSessRPP)
                    }
                }
            }
        }
        fetchData(_currSessPage, _currSessRPP)
    }, [])


    useEffect(() => {
        if (props.formData?.document && props.formData.document[props.data?.chartUniqueId + '_data']?.data?.length) {
            let delegationsListData = JSON.parse(JSON.stringify(props.formData.document[props.data.chartUniqueId + '_data'])).data.map(d => {
                return {
                    _id: d._id,
                    user: d.user && d.user.name ? d.user.name : '',
                    delegate: d.delegate && d.delegate.name ? d.delegate.name : '',
                    // from: new Date(d.from).toLocaleDateString(),
                    // to: new Date(d.to).toLocaleDateString()
                    from: d.from ? d.from.includes('T') ? moment(d.from).utc().format('YYYY-MM-DD') : moment(d.from).format('YYYY-MM-DD') : d.from,
                    to: d.to ? d.to.includes('T') ? moment(d.to).utc().format('YYYY-MM-DD') : moment(d.to).format('YYYY-MM-DD') : d.to
                }
            })
            setDelegationsList(delegationsListData)
        }
        else {
            setDelegationsList([]);
        }
    }, [props.formData && props.formData.document[props.data?.chartUniqueId + '_data']])

    useEffect(() => {
        if (props.formData?.document && props.formData.document[props.data?.chartUniqueId + '_delete_delegation']) {
            let deleleDeligationState = props.formData?.document && props.formData.document[props.data?.chartUniqueId + '_delete_delegation']
            toast.success(deleleDeligationState.message || 'Deleted successfully');
            let subKeys = [
                props.data?.chartUniqueId + '_delete_delegation',
                props.data?.chartUniqueId + '_delete_delegation_loading'
            ]
            props.clearReduxDataOfCurrentComponent({
                key: "document",
                subKey: subKeys
            })

            fetchData();
        }
    }, [props.formData && props.formData.document[props.data?.chartUniqueId + '_delete_delegation']])

    useEffect(() => {
        setState({ ...TableSupport.updateTableData(state) });
    }, [state.searchText, state.filterList]);

    useEffect(() => {
        if (delegationsList) {
            const rows = tableJson;
            let keysArr = ['_id'];
            setState({
                ...TableSupport.setDatafromApi2(
                    getData(delegationsList),
                    keysArr,
                    state,
                    getColumns()),
                count: props.formData?.document[props.data?.chartUniqueId + '_data']?.total
            });
            isHighlightedTile()
        }
    }, [delegationsList]);

    useEffect(() => {
        return () => {
            clearCurrentChartReduxStore()
        }
    }, [])

    useEffect(() => {
        if (localStorage.getItem('userSession')) {
            let userSession = JSON.parse(atob(localStorage.getItem('userSession')))
            if (Object.keys(userSession).length === 0) {
                if (Object.keys(localStorageObject).length > 0) {
                    localStorage.setItem('userSession', btoa(JSON.stringify({ [userId]: { [pageUrl]: { ...localStorageObject } } })))
                }
            } else {
                for (let [key, value] of Object.entries(userSession)) {
                    if (key === userId) {
                        for (let [key1, value1] of Object.entries(value)) {
                            if (key1 === pageUrl) {
                                userSession[userId][pageUrl] = { ...value1, ...localStorageObject }
                                localStorage.setItem('userSession', window.btoa(JSON.stringify(userSession)))
                            } else {
                                if (Object.keys(localStorageObject).length > 0) {
                                    userSession[userId][pageUrl] = { ...localStorageObject }
                                    localStorage.setItem('userSession', window.btoa(JSON.stringify(userSession)))
                                }
                            }
                        }
                    }
                }
            }
        } else if (Object.keys(localStorageObject).length > 0) {
            localStorage.setItem('userSession', btoa(JSON.stringify({ [userId]: { [pageUrl]: { ...localStorageObject } } })))
        }
    }, [localStorageObject])

    const isHighlightedTile = async () => {
        let userSession = await localStorage.getItem('userSession')
        if (userSession) {
            userSession = JSON.parse(atob(userSession))
            let currentUserSession = userSession && userSession[userId]
            let currentUserSessionPage = userSession && currentUserSession && currentUserSession[pageUrl]
            if (currentUserSessionPage?.selectedLineItem) {
                setHighLighterIdForSelectedLineItem(currentUserSessionPage?.selectedLineItem)
            }
        }
    }

    const getData = (dataSource) => {
        const clonedDataSource = JSON.parse(JSON.stringify(dataSource))
        let fetchType;
        return clonedDataSource.map((item) => {
            const keys = Object.keys(item)
            keys.forEach((key) => {
                if (key && typeof item[key] !== "string") {
                    if (fetchType === "keysArray" && !selectedInnerSupportDataElement) {
                        if (data?.chartData?.selectedInnerDataElement?.length) {
                            data?.chartData?.selectedInnerDataElement?.map(side => {
                                if (side.value === key) {
                                    if (side.type === "namepicker") {
                                        if (item[key] instanceof Array) {
                                            item[key] = item[key].map(obj => obj.label).join(', ')
                                        } else {
                                            item[key] = item[key]
                                                ? (typeof item[key] === "object"
                                                    ? item[key][label]
                                                    : item[key])
                                                : ""
                                        }
                                    } else {
                                        item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                                    }
                                }
                            })
                        } else {
                            item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                        }
                    } else {
                        if (data.chartData?.selectedDataElement?.length) {
                            data.chartData?.selectedDataElement?.map(sde => {
                                if (sde.value === key) {
                                    if (sde.type === "namepicker") {
                                        if (item[key] instanceof Array) {
                                            item[key] = item[key].map(obj => obj.label).join(', ')
                                        } else {
                                            item[key] = item[key]
                                                ? (typeof item[key] === "object"
                                                    ? item[key][label]
                                                    : item[key])
                                                : ""
                                        }
                                    } else {
                                        item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                                    }
                                }
                            })
                        } else {
                            item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                        }
                    }
                }
            })
            return item
        })
    }

    const getColumns = () => {
        let COLUMNS = []
        if (delegationsList.length) {
            COLUMNS = Object.keys(
                delegationsList[0]
            ).map((i) => {
                return {
                    id: i,
                    numeric: false,
                    disablePadding: false,
                    label: i,
                    sort: true,
                    checked: true
                }
            }).filter(ele => ele.id !== '_id')
        }

        return COLUMNS
    }

    const fetchData = (_page = page, _rowsPerPage = rowsPerPage) => {
        const payload = {
            "appId": props.appId,
            "orgId": props.user.orgId,
            'dataModelId': props.data.chartData.selectedDataModel,
            "dataUniqueId": props.data?.chartUniqueId + '_data',
            "payload": {
                "page": _page,
                "rowsPerPage": _rowsPerPage
            }
        }
        props.getDelegations(payload)
    }

    const onRowClick = (rowData, rowMeta) => {
        console.log('onRowClick');
    };

    const onSearchEnter = (e, onSearchButton = false) => {
        if (e.keyCode === 13 || onSearchButton) {
            // write api call here
        }
    }

    const handleChangePage = (event, _page) => {
        fetchData(_page, rowsPerPage)
        setPage(_page)
        setLocalStorageObject({ ...localStorageObject, page: _page })
    };

    const onAddClick = () => {
        props.history.push({ pathname: `/${props.match.params.appid}/page/delegation/new` });
    }

    const handleChangeRowsPerPage = event => {
        fetchData(0, event.target.value)
        setRowsPerPage(event.target.value)
        setPage(0)
        setLocalStorageObject({ ...localStorageObject, rowsPerPage: event.target.value, page: 0 })
    };

    const searchHandler = (e) => {
        setSearchedText(e ? e.target.value : "")
    }

    const handleInputChange = event => {
        let obj = state.filterTableData;
        let list = obj.columns;
        list.map((item, index) => {
            if (item.name === event.target.value) list[index].checked = event.target.checked;
        });
        obj.columns = [...list];
        setState({ ...state, filterTableData: obj });
        setState({ ...TableSupport.updateTableData(state) });
    };

    const resetFilters = event => {
        setState({ ...state, filterList: [] });
    };

    const handleDropdownChange = (event, index, column) => {
        const value = event.target.value === 'All' ? [] : [event.target.value];
        // filterUpdate(index, value, column, 'dropdown');
        let list = state.filterList;
        list[index] = event.target.value;
        setState({ ...state, filterList: [...list] });
        setState({ ...TableSupport.updateTableData(state) });
    };

    const handleRequestSort = (event, property) => {
        let obj = TableSupport.requestSort(event, property, state);
        setState({ ...state, ...obj });
    };

    const handleSelectAllClick = event => {
        setState({
            ...state,
            selected: TableSupport.handleTableSelectAllClick(event, state.filterTableData.datasets),
        });
    };

    const onClickAnyAction = (docId) => {
        const { _id: userId } = props.member
        if (localStorage.getItem('userSession')) {
            let userSession = JSON.parse(atob(localStorage.getItem('userSession')))
            for (let [key, value] of Object.entries(userSession)) {
                if (key === userId) { // userId matched
                    for (let [key1, value1] of Object.entries(value)) {
                        if (key1 === pageUrl) { // page matched
                            userSession[userId][pageUrl] = { ...value1, 'selectedLineItem': docId }
                            localStorage.setItem('userSession', btoa(JSON.stringify(userSession)))
                        } else {
                            if (Object.keys(userSession).length > 0) {
                                userSession[userId][pageUrl] = { ...value1, 'selectedLineItem': docId }
                                localStorage.setItem('userSession', btoa(JSON.stringify(userSession)))
                            }
                        }
                    }
                }
            }
        } else {
            localStorage.setItem('userSession', btoa(JSON.stringify({ [userId]: { [pageUrl]: { 'selectedLineItem': docId } } })))
        }
    }

    const tableCrudOperations = (item, type) => {
        if (type === 'edit') {
            onClickAnyAction(item._id)
            props.history.push({
                pathname: `/${props.match.params.appid}/page/${chartData.editPage}/${item._id}`,
            })
        }
        if (type === 'delete') {
            setDeleteConfirm({
                ...deleteConfirmState,
                isOpen: true,
                data: item._id
            })
        }
    };

    const onDeleteResponse = (isDelete) => {
        if (isDelete) {
            const payload = {
                appid: props.appId,
                dataUniqueId: props.data?.chartUniqueId + '_delete_delegation',
                orgId: props.user?.orgId,
                dataModelId: props.data.chartData.selectedDataModel,
                memberId: props.member?._id,
                id: deleteConfirmState.data
            }
            props.deleteDelegation(payload)
        }
        setDeleteConfirm({ ...deleteConfirmState, isOpen: false, data: null })
    }

    const handleCheck = (event, id) => {
        const { selected } = state;
        let newSelected = TableSupport.handleSelectedRow(event, id, selected);
        setState({ ...state, selected: newSelected });
        props.checkedContractChanged(newSelected);
    };

    const clearCurrentChartReduxStore = () => {
        let subKeys = [
            props.data?.chartUniqueId + '_data',
            props.data?.chartUniqueId + '_create_delegation'
        ]

        props.clearReduxDataOfCurrentComponent({
            key: "document",
            subKey: subKeys
        })
    }
    console.log('localStoageObject------------>', localStorageObject)

    return (
        <div>
            {(props.formData[props.data?.chartUniqueId + '_data_loading'] || props.formData[props.data?.chartUniqueId + '_delete_delegation_loading']) ? <Loader /> :
                <Paper className={classes.table} elevation={2}>
                    <AdvancedTable
                        name={'ContractList'}
                        onRowClick={onRowClick}
                        {...props}
                        {...state}
                        chartData={chartData}
                        searchedText={searchedText}
                        searchHandler={searchHandler}
                        onSearchEnter={onSearchEnter}
                        isExport={false}
                        isViewCoulmns={false}
                        isSearch={false}
                        isFilter={false}
                        hasCheckbox={false}
                        actions={{ edit: true, view: false, delete: true, copy: false, download: false }}
                        isTablePagination={true}
                        pagination={{ type: 'server' }}
                        paginationPage={page}
                        rowsPerPage={rowsPerPage}
                        isTableHead={true}
                        isTableSubHeader={false}
                        associatedFormName={chartData.associatedFormName}
                        handleInputChange={handleInputChange}
                        onAddClick={onAddClick}
                        resetFilters={resetFilters}
                        handleDropdownChange={handleDropdownChange}
                        handleRequestSort={handleRequestSort}
                        handleSelectAllClick={handleSelectAllClick}
                        tableCrudOperations={tableCrudOperations}
                        handleCheck={handleCheck}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        tableBodyCss={{ border: "1px solid lightGrey" }}
                        stripRows
                        headerstyle={{
                            fontSize: "14px",
                            textAlign: "center",
                            color: "rgba(0, 0, 0, 0.87)",
                            backgroundColor: "#"
                        }}
                        subHeaderstyle={{
                            fontSize: "14px",
                            color: "rgba(0, 0, 0, 0.87)",
                            backgroundColor: "#FFF",
                            textAlign: "center"
                        }}
                        headerClass={classes.headerClass}
                        rowStyle={{
                            fontSize: "14px",
                            color: "rgba(0, 0, 0, 0.87)",
                            backgroundColor: "none",
                            lineHeight: 1.5,
                            textAlign: "center",
                            padding: 0,
                        }}
                        // stripRows
                        loader={props.loader}
                        isNestedTable={props.isNestedTable}
                        onExapandIconClick={props.onExapandIconClick}
                        nestedTableData={props.nestedTableData}
                        highLighterIdForSelectedLineItem={highLighterIdForSelectedLineItem}

                    />
                </Paper>
            }
            <ConfirmationHandler
                open={deleteConfirmState.isOpen}
                handleClose={onDeleteResponse}
                alertMessageText={deleteConfirmState.deleteMessage}
                classes={classes}
                confirm
            />
        </div>
    )
}

const mapDispatchToProps = {
    getDelegations,
    deleteDelegation,
    clearReduxDataOfCurrentComponent
};

const mapStateToProps = state => {
    return {
        formData: state.form,
        member: state.appConfig.members,
        user: state.user,
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(withRouter(Delegation)));