import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 10,
    },
    root: {
        width: "100%"
    },
    headerClass: {
        backgroundColor: "#cecece",
        textTransform: 'capitalize'
    },
    confirmationDialoge: {
        "& .MuiDialog-paperWidthSm": {
            height: "250px",
            width: "600px"
        }
    },
    para: {
        fontSize: "28px !important",
        fontWeight: 600,
        color: "#000"
    },
    InputLabel: {
        marginTop: 20
    },
    TextField: {
        width: '100%',
        '& .MuiInputBase-formControl': {
            height: '43px !important'
        }
    },
    status: {
        display: 'contents'
    },
    tabController: {
        "& .MuiTab-textColorInherit ": {
            minWidth: "0px"
        }
    },
    appBar: {
        boxShadow: "none !important"
    },
    ContactTabs: {
        marginTop: 'none !important',
        marginBottom: 'none !important',
        border: "3px solid #eeee",
    },
    paper: {
        width: '100%',
        // marginBottom: theme.spacing(2),
        border: "3px solid #eeee",
        marginLeft: '-41px !important'
    },
    backIconCls: {
        padding: '0px !important'
    }
});