import React, { useState, useEffect } from "react";

import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { hot } from 'react-hot-loader/root';
import { useHistory, withRouter } from "react-router-dom";

import { TextField, InputLabel, Grid, Paper, Button, Divider, IconButton, CircularProgress } from '@material-ui/core';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { ChipSelect } from 'dsilo-ui-components'

import { Loader } from "../../components/loader";
import { clearReduxDataOfCurrentComponent, createRole, getPrivileges, getRoleById } from "../../store/actions";

import { useStyles } from "../../utility/SystemPagesCommonCode/styles";

const AddRole = (props) => {
    const classes = useStyles()
    const history = useHistory();

    const { appId, formData } = props
    const { chartUniqueId, chartData } = props.data

    const [privilegesList, setPrivilegesList] = useState([])

    const [formState, setFormState] = useState({})
    const [validate, setValidate] = useState({})

    useEffect(() => {
        fetchPrivileges()
    }, [])

    useEffect(() => {
        if (formData.document[appId + '_privileges']?.length) {
            let d = formData.document[appId + '_privileges'].map(privilege => ({
                value: privilege?._id?.toString(),
                label: privilege.name
            }))
            setPrivilegesList(d)
        }
    }, [formData && formData.document[appId + '_privileges']])

    useEffect(() => {
        if (formData?.document[chartUniqueId + '_role']) {
            let d = formData?.document[chartUniqueId + '_role']
            console.log("it is privilege data ===", d)
            let obj = {
                _id: d._id?.toString(),
                name: d.name,
                description: d.description,
                privileges: []
            }
            if (d.privileges && d.privileges.length) {
                d.privileges.map((rpriv) => {
                    let priv = privilegesList.find((privilege) => privilege.value === rpriv)
                    if (priv) {
                        obj['privileges'].push({
                            value: d.rpriv,
                            label: priv.label
                        })
                    }
                })

            }
            setFormState(obj)
        }
    }, [formData && formData.document[chartUniqueId + '_role']])

    useEffect(() => {
        if (privilegesList?.length) {
            fetchRoleData()
        }
    }, [privilegesList])

    useEffect(() => {
        if (formData?.document && formData.document[chartUniqueId + '_create_role']) {
            let message = formData.document[chartUniqueId + '_create_role']?.message
            if (message) {
                toast.success(message);
                props.history.push({ pathname: `/${props.match.params.appid}/page/roles` });
            }
        }
        return () => {
            props.clearReduxDataOfCurrentComponent({
                key: "document",
                subKey: [chartUniqueId + '_create_role']
            })
        }
    }, [formData && formData.document[chartUniqueId + '_create_role']])

    const fetchPrivileges = () => {
        const payload = {
            "appId": appId,
            "orgId": props.user.orgId,
            "dataUniqueId": appId + '_privileges'
        }
        props.getPrivileges(payload)
    }

    const fetchRoleData = () => {
        if (props.match.params.id !== "new") {
            const payload = {
                "appId": appId,
                "orgId": props.user.orgId,
                "id": props.match.params.id,
                "dataUniqueId": chartUniqueId + '_role'
            }
            props.getRoleById(payload)
        }
    }

    const onChangeHandler = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        })
    }

    const onChangeOfSelectHandler = (value, key) => {
        setFormState({
            ...formState,
            [key]: value
        })
    }

    const handleBackBtnClick = () => {
        history.goBack()
    }

    const onSubmitHandler = () => {
        console.log("onSubmitHandler === formState", formState)
        let data = {
            ...formState,
        }
        if (formState.privileges.length) {
            data['privileges'] = formState.privileges.map(privilege => privilege.value)
        }
        console.log("It is data to save in db ==", data)
        const payload = {
            data,
            "appId": appId,
            "orgId": props.user.orgId,
            "id": props.match.params.id,
            "dataUniqueId": chartUniqueId + '_create_role',
        }
        props.createRole(payload)
    }

    if (formData[chartUniqueId + '_privileges_loading'] || formData[chartUniqueId + '_role_loading']) {
        return <Loader />
    }

    return (
        <div style={{ position: 'absolute' }}>
            <div style={{ display: 'flex' }}>
                <IconButton onClick={handleBackBtnClick} className={classes.backIconCls} title={'Back'}>
                    <ArrowBackIosIcon />
                </IconButton>
                <h1>{'Role View'}</h1>
            </div>
            <Divider />
            <Grid container style={{ marginTop: 30, position: 'relative', overflow: 'visible' }}>
                <Grid container xs={6} spacing={3} className={classes.gridContainer}>
                    <Grid item xs={3} sm={3} >
                        <InputLabel htmlFor="my-input" className={classes.InputLabel}>Name</InputLabel>
                    </Grid>
                    <Grid item xs={9} sm={9}>
                        <TextField
                            variant="outlined"
                            id="standard-error-helper-text"
                            className={classes.TextField}
                            placeholder={'Enter Name'}
                            name='name'
                            value={formState.name}
                            onChange={onChangeHandler}
                            error={validate.name ? true : false}
                            helperText={validate.name ? 'Name is required' : ''}
                        />
                    </Grid>

                    <Grid item xs={3} sm={3}>
                        <InputLabel htmlFor="my-input" className={classes.InputLabel}>Description</InputLabel>
                    </Grid>
                    <Grid item xs={9} sm={9}>
                        <TextField
                            variant="outlined"
                            className={classes.TextField}
                            placeholder={'Enter Description'}

                            name={'description'}
                            value={formState.description}
                            onChange={onChangeHandler}

                            error={validate.description ? true : false}
                            id="standard-error-helper-text"
                            helperText={validate.description ? 'Description is required' : ''}
                        />
                    </Grid>

                    <Grid item xs={3} sm={3} >
                        <InputLabel htmlFor="my-input" className={classes.InputLabel}>Select Privileges</InputLabel>
                    </Grid>
                    <Grid item xs={9} sm={9}>
                        <ChipSelect
                            id='role'
                            isMulti
                            variant="fixed"
                            isSearchable={true}

                            options={privilegesList}
                            value={formState.privileges}
                            onChange={(val) => onChangeOfSelectHandler(val, 'privileges')}

                            textFieldProps={{
                                InputLabelProps: {
                                    shrink: true,
                                },
                                variant: 'outlined',
                            }}
                            className={classes.chipSelect}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSubmitHandler}
                            style={{ float: 'right' }}
                        >
                            {
                                props.formData[chartUniqueId + '_create_role_loading']
                                    ? <CircularProgress color="#fff" size={24} />
                                    : formState?._id ? "Update" : "Save"
                            }
                        </Button>
                        {formState?._id &&
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleBackBtnClick}
                                style={{ float: 'right', marginRight: 10 }}
                            >
                                {"Cancel"}
                            </Button>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapDispatchToProps = {
    getPrivileges,
    getRoleById,
    createRole,
    clearReduxDataOfCurrentComponent,
}

const mapStateToProps = state => {
    return {
        user: state.user,
        formData: state.form,
        dataModels: state.dataModels,
        member: state.appConfig.members,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddRole)))