import { Loader } from "components/loader";
import { CognitiveSearchView } from "dsilo-ui-components";
import React from "react";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PUBLIC_PATH } from "../../constants";
import {
  clearReduxDataOfCurrentComponent,
  downloadContractPDFAction,
  getCognitiveSearchV1Data,
  getModelDataDropDown,
  getModelData,
  downloadSheet,
  saveAdvancedSearchData,
  getAllSavedSearchData
} from '../../store/actions';
import adobeLogo from '../../images/adobe-pdf-icon.png';

const CognitiveSearchComponent = props => {
  return (
    <div>
      <CognitiveSearchView 
        adobeLogo={adobeLogo} 
        loader={<Loader />}     
        PUBLIC_PATH={PUBLIC_PATH} 
        {...props} 
      />
    </div>
  );
};

const mapDispatchToProps = {
  clearReduxDataOfCurrentComponent,
  downloadContractPDFAction,
  getCognitiveSearchV1Data,
  getModelDataDropDown,
  getModelData,
  saveAdvancedSearchData,
  getAllSavedSearchData,
  downloadSheet,
};

const mapStateToProps = state => {
  return {
    appConfig: state.appConfig.app,
    apps: state.app.app,
    login: state.login,
    user: state.user,
    formData: state.form,
    contractAnnotations: state.contractAnnotations,
    model: state.model,
    members: state.appConfig.members,
  };
};

export default hot(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CognitiveSearchComponent)),
);
