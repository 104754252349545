import { keyMirror } from 'modules/helpers';

import dataModels from './dataModels';
import rules from './rules';
import powerBi from './powerBi';

/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({

  /*Email icon */
  EMAIL_SEND_NOTIFICATIONS: undefined,
  EMAIL_SEND_NOTIFICATIONS_SUCCESS: undefined,
  EMAIL_SEND_NOTIFICATIONS_ERROR: undefined,

  LOAD_APP_CONFIG_LOADING: undefined,
  LOAD_APP_CONFIG_SUCCESS: undefined,
  LOAD_APP_CONFIG_ERROR: undefined,
  LOAD_APPS_LOADING: undefined,
  LOAD_APPS_SUCCESS: undefined,
  LOAD_APPS_ERROR: undefined,
  LOAD_PAGE_CONFIG_LOADING: undefined,
  LOAD_PAGE_CONFIG_SUCCESS: undefined,
  LOAD_PAGE_CONFIG_ERROR: undefined,
  LOAD_PAGES_LOADING: undefined,
  LOAD_PAGES_SUCCESS: undefined,
  LOAD_PAGES_ERROR: undefined,
  LOAD_ORG_LOADING: undefined,
  LOAD_ORG_SUCCESS: undefined,
  LOAD_ORG_ERROR: undefined,
  CREATE_ORG_LOADING: undefined,
  CREATE_ORG_SUCCESS: undefined,
  CREATE_ORG_ERROR: undefined,
  LOAD_THEME_LOADING: undefined,
  LOAD_THEME_SUCCESS: undefined,
  LOAD_THEME_ERROR: undefined,
  REGISTER_LOADING: undefined,
  REGISTER_SUCCESS: undefined,
  REGISTER_ERROR: undefined,
  CHECK_EMAIL_AAD: undefined,
  CHECK_EMAIL_AAD_SUCCESS: undefined,
  CHECK_EMAIL_AAD_ERROR: undefined,
  LOGIN_LOADING: undefined,
  LOGIN_SUCCESS: undefined,
  LOGIN_ERROR: undefined,
  USER_LOGGED_OUT: undefined,
  USER_SSO_EXPIRE: undefined,
  CLEAR_REDUX_STORE: undefined,
  SET_USER_DATA: undefined,
  REMOVE_USER_DATA: undefined,
  HIDE_MESSAGE: undefined,
  SHOW_MESSAGE: undefined,
  SAVE_APP_LOADING: undefined,
  SAVE_APP_SUCCESS: undefined,
  SAVE_APP_ERROR: undefined,
  SAVE_DATASOURCE_LOADING: undefined,
  SAVE_DATASOURCE_SUCCESS: undefined,
  SAVE_DATASOURCE_ERROR: undefined,
  LOAD_DATAELEMENTS_LOADING: undefined,
  LOAD_DATAELEMENTS_SUCCESS: undefined,
  LOAD_DATAELEMENTS_ERROR: undefined,
  SAVE_DATAMODEL_LOADING: undefined,
  SAVE_DATAMODEL_SUCCESS: undefined,
  SAVE_DATAMODEL_ERROR: undefined,
  SAVE_PAGE_LOADING: undefined,
  SAVE_PAGE_SUCCESS: undefined,
  SAVE_PAGE_ERROR: undefined,
  SET_PREV_PATH: undefined,
  EMPTY_PREV_PATH: undefined,
  LOAD_ENTITIES_LOADING: undefined,
  LOAD_ENTITIES_SUCCESS: undefined,
  LOAD_ENTITIES_ERROR: undefined,
  GET_FORM_BY_NAME_LOADING: undefined,
  GET_FORM_BY_NAME_SUCCESS: undefined,
  GET_FORM_BY_NAME_ERROR: undefined,
  GET_FORMS_LOADING: undefined,
  GET_FORMS_SUCCESS: undefined,
  GET_FORMS_ERROR: undefined,
  GET_SUBMITTED_DATA_BY_ID_LOADING: undefined,
  GET_SUBMITTED_DATA_BY_ID_SUCCESS: undefined,
  GET_SUBMITTED_DATA_BY_ID_ERROR: undefined,
  SAVE_SUBMITTED_DATA_LOADING: undefined,
  SAVE_SUBMITTED_DATA_SUCCESS: undefined,
  SAVE_SUBMITTED_DATA_ERROR: undefined,
  UPDATE_SUBMITTED_DATA_LOADING: undefined,
  RESET_PASSWORD_LOADING: undefined,
  RESET_PASSWORD_SUCCESS: undefined,
  RESET_PASSWORD_ERROR: undefined,
  CLEAN_SUBMITTED_DATA: undefined,
  UPDATE_SELECTED_FORM: undefined,
  LOAD_ROLES_LOADING: undefined,
  LOAD_ROLES_SUCCESS: undefined,
  LOAD_ROLES_ERROR: undefined,
  LOAD_MEMBER_LOADING: undefined,
  LOAD_MEMBER_SUCCESS: undefined,
  LOAD_MEMBER_ERROR: undefined,
  GET_MEMBER_LOADING: undefined,
  GET_MEMBER_SUCCESS: undefined,
  GET_MEMBER_ERROR: undefined,
  UPLOAD_CAPPS_LOADING: undefined,
  UPLOAD_CAPPS_SUCCESS: undefined,
  UPLOAD_CAPPS_ERROR: undefined,
  GET_CAPP_LOADING: undefined,
  GET_CAPP_SUCCESS: undefined,
  GET_CAPP_ERROR: undefined,
  FILTER_CAPP_LOADING: undefined,
  FILTER_CAPP_SUCCESS: undefined,
  FILTER_CAPP_ERROR: undefined,
  RULE_EXCUTER_LOADING: undefined,
  RULE_EXCUTER_SUCCESS: undefined,
  RULE_EXCUTER_ERROR: undefined,
  GET_ENTITY_LOADING: undefined,
  GET_ENTITY_SUCCESS: undefined,
  GET_ENTITY_ERROR: undefined,

  LOAD_PDF: undefined,
  SAVE_SUPPORTING_DOCUMENT_SUCCESS: undefined,
  SAVE_SUPPORTING_DOCUMENT_FAILURE: undefined,

  UPLOAD_CONTRACT_PDF: undefined,
  UPLOAD_CONTRACT_SUCCESS: undefined,
  UPLOAD_CONTRACT_ERROR: undefined,

  SAVE_ANNOTATIONS: undefined,
  SAVE_ANNOTATIONS_SUCCESS: undefined,
  SAVE_ANNOTATIONS_ERROR: undefined,

  UPDATE_ANNOTATIONS: undefined,
  UPDATE_ANNOTATIONS_SUCCESS: undefined,
  UPDATE_ANNOTATIONS_ERROR: undefined,
  UPDATE_ANNOTATIONS_WITHOUT_LOADER: undefined,
  UPDATE_ANNOTATIONS_WITHOUT_LOADER_SUCCESS: undefined,
  UPDATE_ANNOTATIONS_WITHOUT_LOADER_ERROR: undefined,

  UPDATE_CONTRACT_REDUCER_STATE: undefined,

  GET_ALL_CONTRACTS: undefined,
  GET_CONTRACT_LIST_SUCCESS: undefined,
  GET_CONTRACT_LIST_ERROR: undefined,

  DOWNLOAD_CONTRACT_PDF: undefined,
  DOWNLOAD_CONTRACT_PDF_SUCCESS: undefined,
  DOWNLOAD_CONTRACT_PDF_ERROR: undefined,

  GET_CONTRACT_BY_ID: undefined,
  GET_CONTRACT_BY_ID_EMPTY: undefined,
  GET_CONTRACT_BY_ID_SUCCESS: undefined,
  GET_CONTRACT_BY_ID_ERROR: undefined,

  DELETE_CONTRACT: undefined,
  DELETE_CONTRACT_SUCCESS: undefined,
  DELETE_CONTRACT_ERROR: undefined,

  HIDE_CONTRACT_UPDATE_MESSAGE: undefined,

  VERIFY_EMAIL_LOADING: undefined,
  VERIFY_EMAIL_SUCCESS: undefined,
  VERIFY_EMAIL_ERROR: undefined,

  FORGOT_PASSWORD_LOADING: undefined,
  FORGOT_PASSWORD_SUCCESS: undefined,
  FORGOT_PASSWORD_ERROR: undefined,

  VERIFY_USER_LOADING: undefined,
  VERIFY_USER_SUCCESS: undefined,
  VERIFY_USER_ERROR: undefined,

  GET_ORG_DETAILS_LOADING: undefined,
  GET_ORG_DETAILS_SUCCESS: undefined,
  GET_ORG_DETAILS_ERROR: undefined,

  ADD_ORG_MEMBER_LOADING: undefined,
  ADD_ORG_MEMBER_SUCCESS: undefined,
  ADD_ORG_MEMBER_ERROR: undefined,

  UPDATE_ORG_LOADING: undefined,
  UPDATE_ORG_SUCCESS: undefined,
  UPDATE_ORG_ERROR: undefined,
  GET_ORG_LOGO: undefined,
  GET_ORG_LOGO_SUCCESS: undefined,

  CLEAR_ORG_MESSAGE: undefined,
  SET_ORG_ID_TO_USER_OBJ: undefined,
  CLEAR_ORG_ERROR: undefined,
  GET_TRANSACTION_DETAILS_LOADING: undefined,
  GET_TRANSACTION_DETAILS_SUCCESS: undefined,
  GET_TRANSACTION_DETAILS_ERROR: undefined,

  GET_TRANSACTION_DETAILS_BY_ID_LOADING: undefined,
  GET_TRANSACTION_DETAILS_BY_ID_SUCCESS: undefined,
  GET_TRANSACTION_DETAILS_BY_ID_ERROR: undefined,

  ADD_TRANSACTION_LOADING: undefined,
  ADD_TRANSACTION_SUCCESS: undefined,
  ADD_TRANSACTION_ERROR: undefined,

  DELETE_TRANSACTION_LOADING: undefined,
  DELETE_TRANSACTION_SUCCESS: undefined,
  DELETE_TRANSACTION_ERROR: undefined,

  CLEAR_PDF_DATA: undefined,

  DELETE_UPLOADED_CONTRACT: undefined,
  DELETE_UPLOADED_CONTRACT_SUCCESS: undefined,
  DELETE_UPLOADED_CONTRACT_ERROR: undefined,

  DOWNLOAD_ANNOTATIONS: undefined,
  DOWNLOAD_ANNOTATIONS_SUCCESS: undefined,
  DOWNLOAD_ANNOTATIONS_ERROR: undefined,

  RESEND_VERIFY_EMAIL_LOADING: undefined,
  RESEND_VERIFY_EMAIL_SUCCESS: undefined,
  RESEND_VERIFY_EMAIL_ERROR: undefined,

  UPDATE_PROFILE_LOADING: undefined,
  UPDATE_PROFILE_SUCCESS: undefined,
  UPDATE_PROFILE_ERROR: undefined,

  SEARCH_DATA_LOADING: undefined,
  SEARCH_DATA_SUCCESS: undefined,
  SEARCH_DATA_ERROR: undefined,

  ADD_SEARCH_DATA_LOADING: undefined,
  ADD_SEARCH_DATA_SUCCESS: undefined,
  ADD_SEARCH_DATA_ERROR: undefined,

  RERUN_AI_ITEM: undefined,
  RERUN_AI_ITEM_SUCCESS: undefined,
  RERUN_AI_ITEM_ERROR: undefined,

  DOWNLOAD_TOP10CLAUSES_EXCEL: undefined,
  DOWNLOAD_TOP10CLAUSES_EXCEL_SUCCESS: undefined,
  DOWNLOAD_TOP10CLAUSES_EXCEL_ERROR: undefined,

  GET_TEMPLATES: undefined,
  GET_TEMPLATES_SUCCESS: undefined,
  GET_TEMPLATES_FAILURE: undefined,

  SUBMIT_CONTACT_DSILO_DATA_LOADING: undefined,
  SUBMIT_CONTACT_DSILO_DATA_SUCCESS: undefined,
  SUBMIT_CONTACT_DSILO_DATA_ERROR: undefined,
  CLEAR_CONTACT_DSILO_DATA_MESSAGE: undefined,

  GET_COGNITIVE_SEARCH_DATA: undefined,
  GET_COGNITIVE_SEARCH_DATA_SUCCESS: undefined,
  GET_COGNITIVE_SEARCH_DATA_FAILURE: undefined,

  GET_MODAL_DATA: undefined,
  GET_MODAL_DATA_SUCCESS: undefined,
  GET_MODAL_DATA_FAILURE: undefined,

  GET_PIECHART_DATA: undefined,
  GET_PIECHART_DATA_SUCCESS: undefined,
  GET_PIECHART_DATA_FAILURE: undefined,

  GET_MODAL_DROP_DOWN_DATA: undefined,
  GET_MODAL_DROP_DOWN_DATA_SUCCESS: undefined,
  GET_MODAL_DROP_DOWN_DATA_FAILURE: undefined,

  GET_COGNITIVE_SEARCH_DATA_V1: undefined,
  GET_COGNITIVE_SEARCH_DATA_V1_SUCCESS: undefined,
  GET_COGNITIVE_SEARCH_DATA_V1_FAILURE: undefined,

  SAVE_SMART_SEARCH_DATA: undefined,
  SAVE_SMART_SEARCH_DATA_SUCCESS: undefined,
  SAVE_SMART_SEARCH_DATA_FAILURE: undefined,

  GET_ALL_SMART_SEARCH_DATA: undefined,
  GET_ALL_SMART_SEARCH_DATA_SUCCESS: undefined,
  GET_ALL_SMART_SEARCH_DATA_FAILURE: undefined,

  SUBMIT_CONTRACT_PDF_LOADING: undefined,
  SUBMIT_CONTRACT_PDF_SUCCESS: undefined,
  SUBMIT_CONTRACT_PDF_ERROR: undefined,
  SUBMIT_CONTRACT_PDF_DUPLICATE_ERROR: undefined,
  CLAER_SUBMIT_CONTRACT_PDF_DUPLICATE_ERROR: undefined,
  CLEAR_CONTRACT_PDF_MESSAGE: undefined,

  DELETE_CONTRACT_PDF: undefined,
  DELETE_CONTRACT_PDF_SUCCESS: undefined,
  DELETE_CONTRACT_PDF_ERROR: undefined,

  GET_DOCUMENT_DATA: undefined,
  GET_DOCUMENT_DATA_SUCCESS: undefined,
  GET_DOCUMENT_DATA_FAILURE: undefined,

  GET_LINEITEM_TABLES_DATA: undefined,
  GET_LINEITEM_TABLES_DATA_SUCCESS: undefined,
  GET_LINEITEM_TABLES_DATA_FAILURE: undefined,

  GET_DOCUMENT_TABLES_DATA: undefined,
  GET_DOCUMENT_TABLES_DATA_SUCCESS: undefined,
  GET_DOCUMENT_TABLES_DATA_FAILURE: undefined,

  CREATE_DOCUMENT_TABLES_DATA: undefined,
  CREATE_DOCUMENT_TABLES_DATA_SUCCESS: undefined,
  CREATE_DOCUMENT_TABLES_DATA_FAILURE: undefined,

  UPDATE_DOCUMENT_TABLES_DATA: undefined,
  UPDATE_DOCUMENT_TABLES_DATA_SUCCESS: undefined,
  UPDATE_DOCUMENT_TABLES_DATA_FAILURE: undefined,

  DELETE_DOCUMENT_TABLE_DATA: undefined,
  DELETE_DOCUMENT_TABLE_DATA_SUCCESS: undefined,
  DELETE_DOCUMENT_TABLE_DATA_FAILURE: undefined,

  HIDE_SUCCESS_MSG: undefined,

  DOWNLOAD_PDF_ACTION: undefined,
  DOWNLOAD_PDF_ACTION_SUCCESS: undefined,
  DOWNLOAD_PDF_ACTION_FAILURE: undefined,


  DOWNLOAD_AMENDMENTTEMPLATE_ACTION: undefined,
  DOWNLOAD_AMENDMENTTEMPLATE_ACTION_SUCCESS: undefined,
  DOWNLOAD_AMENDMENTTEMPLATE_ACTION_FAILURE: undefined,

  DOWNLOAD_AMENDMENTTEMPLATE_ACTION_V2: undefined,
  DOWNLOAD_AMENDMENTTEMPLATE_ACTION_SUCCESS_V2: undefined,
  DOWNLOAD_AMENDMENTTEMPLATE_ACTION_FAILURE_V2: undefined,

  CLEAR_UPLOAD_PDF_DATA: undefined,

  DOWNLOAD_SHEET: undefined,
  DOWNLOAD_SHEET_SUCCESS: undefined,
  DOWNLOAD_SHEET_FAILURE: undefined,

  UPDATE_DATA_MODAL_DATA: undefined,
  UPDATE_DATA_MODAL_DATA_SUCCESS: undefined,
  UPDATE_DATA_MODAL_DATA_FAILURE: undefined,

  CLEAR_SUCCESS_MSG: undefined,

  CREATE_DOCUMENT: undefined,
  CREATE_DOCUMENT_SUCCESS: undefined,
  CREATE_DOCUMENT_FAILURE: undefined,

  GET_DATA_MODEL_BY_ID: undefined,
  GET_DATA_MODEL_BY_ID_SUCCESS: undefined,
  GET_DATA_MODEL_BY_ID_FAILURE: undefined,

  UPDATE_DATA_MODEL_BY_ID: undefined,
  UPDATE_DATA_MODEL_BY_ID_SUCCESS: undefined,
  UPDATE_DATA_MODEL_BY_ID_FAILURE: undefined,

  CLEAR_REDUX_CHART_DATA_OF_COMPONENT: undefined,
  CLEAR_REDUX_DATA_OF_COMPONENT: undefined,
  GET_REDUX_EXTERNAL_FILTER: undefined,
  GET_MODELS_LOADING: undefined,
  GET_MODELS_SUCCESS: undefined,
  GET_MODELS_ERROR: undefined,

  GET_MODEL_LOADING: undefined,
  GET_MODEL_SUCCESS: undefined,
  GET_MODEL_ERROR: undefined,

  SAVE_MODEL_LOADING: undefined,
  SAVE_MODEL_SUCCESS: undefined,
  SAVE_MODEL_ERROR: undefined,

  UPDATE_MODEL_LOADING: undefined,
  UPDATE_MODEL_SUCCESS: undefined,
  UPDATE_MODEL_ERROR: undefined,

  DELETE_MODEL_LOADING: undefined,
  DELETE_MODEL_SUCCESS: undefined,
  DELETE_MODEL_ERROR: undefined,

  CHECK_FOR_TRAINING: undefined,
  CHECK_FOR_TRAINING_SUCCESS: undefined,
  CHECK_FOR_TRAINING_FAILURE: undefined,

  UPDATE_MODEL_STATUS: undefined,
  UPDATE_MODEL_STATUS_SUCCESS: undefined,
  UPDATE_MODEL_STATUS_FAILURE: undefined,

  CLEAR_TRAINING_CHECK_DATA: undefined,

  SAVE_DATA_FOR_TRAINING: undefined,
  SAVE_DATA_FOR_TRAINING_SUCCESS: undefined,
  SAVE_DATA_FOR_TRAINING_FAILURE: undefined,

  GET_TOP_SUGGESTIONS: undefined,
  GET_TOP_SUGGESTIONS_SUCCESS: undefined,
  GET_TOP_SUGGESTIONS_FAILURE: undefined,

  INITIATE_ACTION: undefined,
  INITIATE_ACTION_SUCCESS: undefined,
  INITIATE_ACTION_FAILURE: undefined,

  GET_EVENT_BY_ID: undefined,
  GET_EVENT_BY_ID_SUCCESS: undefined,
  GET_EVENT_BY_ID_FAILURE: undefined,

  ON_SEARCH_TEXT: undefined,
  ON_SEARCH_TEXT_SUCCESS: undefined,
  ON_SEARCH_TEXT_FAILURE: undefined,

  GET_TASK_DATA: undefined,
  GET_TASK_DATA_SUCCESS: undefined,
  GET_TASK_DATA_FAILURE: undefined,

  DELETE_TASK_DATA: undefined,
  DELETE_TASK_DATA_SUCCESS: undefined,
  DELETE_TASK_DATA_FAILURE: undefined,

  UPDATE_TASK_DATA_LOADING: undefined,
  UPDATE_TASK_DATA_SUCCESS: undefined,
  UPDATE_TASK_DATA_FAILURE: undefined,

  GET_DATA_BY_URL: undefined,
  GET_DATA_BY_URL_SUCCESS: undefined,
  GET_DATA_BY_URL_FAILURE: undefined,

  SAVE_DATA_BY_URL: undefined,
  SAVE_DATA_BY_URL_SUCCESS: undefined,
  SAVE_DATA_BY_URL_FAILURE: undefined,

  UPDATE_DATA_BY_URL: undefined,
  UPDATE_DATA_BY_URL_SUCCESS: undefined,
  UPDATE_DATA_BY_URL_FAILURE: undefined,

  DELETE_DATAPOINT: undefined,
  DELETE_DATAPOINT_SUCCESS: undefined,
  DELETE_DATAPOINT_FAILURE: undefined,

  GET_API_DATA: undefined,
  GET_API_DATA_SUCCESS: undefined,
  GET_API_DATA_FAILURE: undefined,

  GET_LABELBOX_DATA: undefined,
  GET_LABELBOX_DATA_SUCCESS: undefined,
  GET_LABELBOX_DATA_FAILURE: undefined,

  CLEAR_RESET_PASSWORD_ERROR: undefined,

  // Get business rules
  ...dataModels,
  // GET_BUSINESSRULES: undefined,
  // GET_BUSINESSRULES_SUCCESS: undefined,
  // GET_BUSINESSRULES_FAILURE: undefined,

  // GET_BUSINESSRULE: undefined,
  // GET_BUSINESSRULE_SUCCESS: undefined,
  // GET_BUSINESSRULE_FAILURE: undefined,

  // SAVE_BUSINESSRULE: undefined,
  // SAVE_BUSINESSRULE_SUCCESS: undefined,
  // SAVE_BUSINESSRULE_FAILURE: undefined,

  // UPDATE_BUSINESSRULE: undefined,
  // UPDATE_BUSINESSRULE_SUCCESS: undefined,
  // UPDATE_BUSINESSRULE_FAILURE: undefined,

  // DELETE_BUSINESSRULE: undefined,
  // DELETE_BUSINESSRULE_SUCCESS: undefined,
  // DELETE_BUSINESSRULE_FAILURE: undefined,

  // EXECUTE_BUSINESSRULE: undefined,
  // EXECUTE_BUSINESSRULE_SUCCESS: undefined,
  // EXECUTE_BUSINESSRULE_FAILURE: undefined,

  // SET_BUSINESSRULES_SEARCH_TEXT: undefined,

  // Get datamodels
  ...rules,
  // GET_DATAELEMENTS: undefined,
  // GET_DATAELEMENTS_SUCCESS: undefined,
  // GET_DATAELEMENTS_FAILURE: undefined,

  // GET_DATAMODELS: undefined,
  // GET_DATAMODELS_SUCCESS: undefined,
  // GET_DATAMODELS_FAILURE: undefined,

  // GET_DATAMODEL: undefined,
  // GET_DATAMODEL_SUCCESS: undefined,
  // GET_DATAMODEL_FAILURE: undefined,

  // SAVE_DATAMODEL: undefined,
  // SAVE_DATAMODEL_SUCCESS: undefined,
  // SAVE_DATAMODEL_FAILURE: undefined,

  // UPDATE_DATAMODEL: undefined,
  // UPDATE_DATAMODEL_SUCCESS: undefined,
  // UPDATE_DATAMODEL_FAILURE: undefined,

  // DELETE_DATAMODEL: undefined,
  // DELETE_DATAMODEL_SUCCESS: undefined,
  // DELETE_DATAMODEL_FAILURE: undefined,

  // DELETE_DATAMODELS: undefined,
  // DELETE_DATAMODELS_SUCCESS: undefined,
  // DELETE_DATAMODELS_FAILURE: undefined,

  // GET_COLLECTIONS_FROM_DATASOURCE: undefined,
  // GET_COLLECTIONS_FROM_DATASOURCE_SUCCESS: undefined,
  // GET_COLLECTIONS_FROM_DATASOURCE_FAILURE: undefined,

  // GET_DATAELMENTS_BY_DATAMODEL: undefined,
  // GET_DATAELMENTS_BY_DATAMODEL_SUCCESS: undefined,
  // GET_DATAELMENTS_BY_DATAMODEL_FAILURE: undefined,

  // GET_DATAMODELS_BY_DATASOURCE: undefined,
  // GET_DATAMODELS_BY_DATASOURCE_SUCCESS: undefined,
  // GET_DATAMODELS_BY_DATASOURCE_FAILURE: undefined,

  // SET_DATAMODELS_SEARCH_TEXT: undefined,

  // OPEN_NEW_DATAMODEL_DIALOG: undefined,
  // CLOSE_NEW_DATAMODEL_DIALOG: undefined,

  // GET_ORG_DATAMODELS: undefined,
  // GET_ORG_DATAMODELS_SUCCESS: undefined,
  // GET_ORG_DATAMODELS_FAILURE: undefined,
  // EMPTY_DATAELEMENTS: undefined

  GET_LINE_DATA_BY_ID: undefined,
  GET_LINE_DATA_BY_ID_SUCCESS: undefined,
  GET_LINE_DATA_BY_ID_FAILURE: undefined,

  GET_BAR_DATA_BY_ID: undefined,
  GET_BAR_DATA_BY_ID_SUCCESS: undefined,
  GET_BAR_DATA_BY_ID_FAILURE: undefined,

  GET_TASKS: undefined,
  GET_TASKS_SUCCESS: undefined,
  GET_TASKS_FAILURE: undefined,

  PROCESS_AGAIN: undefined,
  PROCESS_AGAIN_SUCCESS: undefined,
  PROCESS_AGAIN_FAILURE: undefined,

  GET_MEMBERS_LIST: undefined,
  GET_MEMBERS_LIST_SUCCESS: undefined,
  GET_MEMBERS_LIST_FAILURE: undefined,

  GET_ALL_MEMBERS_LIST: undefined,
  GET_ALL_MEMBERS_LIST_SUCCESS: undefined,
  GET_ALL_MEMBERS_LIST_FAILURE: undefined,

  GET_APP_TEMPLATES: undefined,

  CREATE_APP: undefined,
  CREATE_APP_SUCCESS: undefined,
  CREATE_APP_FAILURE: undefined,

  VERIFY_APP: undefined,
  VERIFY_APP_SUCCESS: undefined,
  VERIFY_APP_FAILURE: undefined,

  HANDLE_LOADER: undefined,
  NO_MEMBER_FOUND: undefined,

  GET_AUDIT_TRAILS_DATA: undefined,
  GET_AUDIT_TRAILS_DATA_SUCCESS: undefined,
  GET_AUDIT_TRAILS_DATA_FAILURE: undefined,

  GET_OTHERS_AUDIT_TRAILS_DATA: undefined,
  GET_OTHERS_AUDIT_TRAILS_DATA_SUCCESS: undefined,
  GET_OTHERS_AUDIT_TRAILS_DATA_FAILURE: undefined,

  BULK_UPLOAD_PDF: undefined,
  BULK_UPLOAD_PDF_SUCCESS: undefined,
  BULK_UPLOAD_PDF_FAILURE: undefined,

  BULK_UPLOAD_PDF_MERGE: undefined,
  BULK_UPLOAD_PDF_MERGE_SUCCESS: undefined,
  BULK_UPLOAD_PDF_MERGE_FAILURE: undefined,

  SERVICE_BUS_DATA: undefined,
  SERVICE_BUS_DATA_SUCCESS: undefined,
  SERVICE_BUS_DATA_FAILURE: undefined,

  SERVICE_BUS_TITLE_LIST_STATUS: undefined,
  SERVICE_BUS_TITLE_LIST_STATUS_SUCCESS: undefined,
  SERVICE_BUS_TITLE_LIST_STATUS_FAILURE: undefined,

  ASSIGNEE_CHANGE: undefined,
  ASSIGNEE_CHANGE_SUCCESS: undefined,
  ASSIGNEE_CHANGE_FAILURE: undefined,

  COMPLETE_REVIEW: undefined,
  COMPLETE_REVIEW_SUCCESS: undefined,
  COMPLETE_REVIEW_FAILURE: undefined,

  CREATE_MEMBER: undefined,
  CREATE_MEMBER_SUCCESS: undefined,
  CREATE_MEMBER_FAILURE: undefined,

  DELETE_MEMBER: undefined,
  DELETE_MEMBER_SUCCESS: undefined,
  DELETE_MEMBER_FAILURE: undefined,

  GET_ROLES_MEMBERS: undefined,
  GET_ROLES_MEMBERS_SUCCESS: undefined,
  GET_ROLES_MEMBERS_FAILURE: undefined,

  GET_GROUPS: undefined,
  GET_GROUPS_SUCCESS: undefined,
  GET_GROUPS_FAILURE: undefined,

  GET_PRIVILEGES: undefined,
  GET_PRIVILEGES_SUCCESS: undefined,
  GET_PRIVILEGES_FAILURE: undefined,

  GET_PRIVILEGE_BY_ID: undefined,
  GET_PRIVILEGE_BY_ID_SUCCESS: undefined,
  GET_PRIVILEGE_BY_ID_FAILURE: undefined,

  CREATE_PRIVILEGE: undefined,
  CREATE_PRIVILEGE_SUCCESS: undefined,
  CREATE_PRIVILEGE_FAILURE: undefined,

  GET_SYSTEM_OPERATIONS: undefined,
  GET_SYSTEM_OPERATIONS_SUCCESS: undefined,
  GET_SYSTEM_OPERATIONS_FAILURE: undefined,

  GET_ROLES: undefined,
  GET_ROLES_SUCCESS: undefined,
  GET_ROLES_FAILURE: undefined,

  GET_ROLE_BY_ID: undefined,
  GET_ROLE_BY_ID_SUCCESS: undefined,
  GET_ROLE_BY_ID_FAILURE: undefined,

  CREATE_ROLE: undefined,
  CREATE_ROLE_SUCCESS: undefined,
  CREATE_ROLE_FAILURE: undefined,

  GET_WORKFLOW: undefined,
  GET_WORKFLOW_SUCCESS: undefined,
  GET_WORKFLOW_FAILURE: undefined,

  GET_NEXT_TASKS: undefined,
  GET_NEXT_TASKS_SUCCESS: undefined,
  GET_NEXT_TASKS_FAILURE: undefined,

  REINITIATE_WORKFLOW: undefined,
  REINITIATE_WORKFLOW_SUCCESS: undefined,
  REINITIATE_WORKFLOW_FAILURE: undefined,

  EXECUTE_WORKFLOW: undefined,
  EXECUTE_WORKFLOW_SUCCESS: undefined,
  EXECUTE_WORKFLOW_FAILURE: undefined,

  INIT_WORKFLOW: undefined,
  INIT_WORKFLOW_SUCCESS: undefined,
  INIT_WORKFLOW_FAILURE: undefined,

  GET_NEXT_RESPONDENT: undefined,
  GET_NEXT_RESPONDENT_SUCCESS: undefined,
  GET_NEXT_RESPONDENT_FAILURE: undefined,

  GET_APP_CONFIG: undefined,
  GET_APP_CONFIG_SUCCESS: undefined,
  GET_APP_CONFIG_FAILURE: undefined,

  SAVE_APP_CONFIG: undefined,
  SAVE_APP_CONFIG_SUCCESS: undefined,
  SAVE_APP_CONFIG_FAILURE: undefined,

  GROUPS_CREATE: undefined,
  GROUPS_CREATE_SUCCESS: undefined,
  GROUPS_CREATE_FAILURE: undefined,

  DELETE_GROUPS: undefined,
  DELETE_GROUPS_SUCCESS: undefined,
  DELETE_GROUPS_FAILURE: undefined,

  GET_CHARGE_CODE: undefined,
  GET_CHARGE_CODE_SUCCESS: undefined,
  GET_CHARGE_CODE_FAILURE: undefined,

  CREATE_CHARGE_CODE: undefined,
  CREATE_CHARGE_CODE_SUCCESS: undefined,
  CREATE_CHARGE_CODE_FAILURE: undefined,

  DELETE_CHARGE_CODE: undefined,
  DELETE_CHARGE_CODE_SUCCESS: undefined,
  DELETE_CHARGE_CODE_FAILURE: undefined,

  GET_CHARGE_CODES: undefined,
  GET_CHARGE_CODES_SUCCESS: undefined,
  GET_CHARGE_CODES_FAILURE: undefined,

  GET_ARCHIVED_DATA: undefined,
  GET_ARCHIVED_DATA_SUCCESS: undefined,
  GET_ARCHIVED_DATA_FAILURE: undefined,

  REJECT_WORKFLOW: undefined,
  REJECT_WORKFLOW_SUCCESS: undefined,
  REJECT_WORKFLOW_FAILURE: undefined,

  REJECT_TO_QA: undefined,
  REJECT_TO_QA_SUCCESS: undefined,
  REJECT_TO_QA_FAILURE: undefined,

  GET_REVIEW_INFO: undefined,
  GET_REVIEW_INFO_SUCCESS: undefined,
  GET_REVIEW_INFO_FAILURE: undefined,

  GET_PO_INFO_FROM_EXTERNAL_SOURCE: undefined,
  GET_PO_INFO_FROM_EXTERNAL_SOURCE_SUCCESS: undefined,
  GET_PO_INFO_FROM_EXTERNAL_SOURCE_FAILURE: undefined,

  SAGE_INTACCT: undefined,
  SAGE_INTACCT_SUCCESS: undefined,
  SAGE_INTACCT_FAILURE: undefined,

  GET_DELETED_DOCUMENT: undefined,
  GET_DELETED_DOCUMENT_SUCCESS: undefined,
  GET_DELETED_DOCUMENT_FAILURE: undefined,

  GET_DELEGATIONS: undefined,
  GET_DELEGATIONS_SUCCESS: undefined,
  GET_DELEGATIONS_FAILURE: undefined,

  GET_DELEGATION: undefined,
  GET_DELEGATION_SUCCESS: undefined,
  GET_DELEGATION_FAILURE: undefined,

  CREATE_DELEGATION: undefined,
  CREATE_DELEGATION_SUCCESS: undefined,
  CREATE_DELEGATION_FAILURE: undefined,

  DELETE_DELEGATION: undefined,
  DELETE_DELEGATION_SUCCESS: undefined,
  DELETE_DELEGATION_FAILURE: undefined,

  GET_TICKETS: undefined,
  GET_TICKETS_SUCCESS: undefined,
  GET_TICKETS_FAILURE: undefined,

  CREATE_TICKETS: undefined,
  CREATE_TICKETS_SUCCESS: undefined,
  CREATE_TICKETS_FAILURE: undefined,

  DELETE_TICKETS: undefined,
  DELETE_TICKETS_SUCCESS: undefined,
  DELETE_TICKETS_FAILURE: undefined,

  RESTORE_DELETE_DOC: undefined,
  RESTORE_DELETE_DOC_SUCCESS: undefined,
  RESTORE_DELETE_DOC_FAILURE: undefined,

  RESTORE_OTHERS_DELETED_DOC: undefined,
  RESTORE_OTHERS_DELETED_DOC_SUCCESS: undefined,
  RESTORE_OTHERS_DELETED_DOC_FAILURE: undefined,

  TERMINATE_MEMBER: undefined,
  TERMINATE_MEMBER_SUCCESS: undefined,
  TERMINATE_MEMBER_FAILURE: undefined,

  GET_ALL_OTHER_DOC: undefined,
  GET_ALL_OTHER_DELETED_DOC: undefined,
  GET_ALL_OTHER_DOC_SUCCESS: undefined,
  GET_ALL_OTHER_DOC_FAILURE: undefined,

  DOWNLOAD_ALL_OTHER_DOCS: undefined,
  DOWNLOAD_ALL_OTHER_DOCS_SUCCESS: undefined,
  DOWNLOAD_ALL_OTHER_DOCS_FAILURE: undefined,

  DELETE_ALL_OTHER_DOCS: undefined,
  DELETE_ALL_OTHER_DOCS_SUCCESS: undefined,
  DELETE_ALL_OTHER_DOCS_FAILURE: undefined,

  GET_PDF_FOR_OTHER_DOC: undefined,
  GET_OTHERS_DELETED_DOC: undefined,
  GET_PDF_FOR_OTHER_DOC_SUCCESS: undefined,
  GET_PDF_FOR_OTHER_DOC_FAILURE: undefined,

  OTHER_DOC_MOVE: undefined,
  OTHER_DOC_MOVE_SUCCESS: undefined,
  OTHER_DOC_MOVE_DUPLICATE_ERROR: undefined,
  OTHER_DOC_MOVE_FAILURE: undefined,

  MOVE_TO_DUPLICATES: undefined,
  MOVE_TO_DUPLICATES_SUCCESS: undefined,
  MOVE_TO_DUPLICATES_FAILURE: undefined,

  ADD_COMMENT: undefined,
  ADD_COMMENT_SUCCESS: undefined,
  ADD_COMMENT_FAILURE: undefined,

  ADD_NEW_ENTRY_BY_DATAMODELS: undefined,
  ADD_NEW_ENTRY_BY_DATAMODELS_SUCCESS: undefined,
  ADD_NEW_ENTRY_BY_DATAMODELS_FAILURE: undefined,

  BULK_ASSIGN: undefined,
  BULK_ASSIGN__SUCCESS: undefined,
  BULK_ASSIGN__FAILURE: undefined,

  GET_GROUPS_BY_MEMBER_ID: undefined,
  GET_GROUPS_BY_MEMBER_ID_SUCCESS: undefined,
  GET_GROUPS_BY_MEMBER_ID_FAILURE: undefined,

  UPLOAD_DATA: undefined,
  UPLOAD_DATA_SUCCESS: undefined,
  UPLOAD_DATA_FAILURE: undefined,

  GET_SUPPORTING_dOCUMENTS: undefined,
  GET_SUPPORTING_dOCUMENTS_SUCCESS: undefined,
  GET_SUPPORTING_dOCUMENTS_FAILURE: undefined,

  SET_USER_DETAILS_LOADING: undefined,

  FINAL_DOCUMENT: undefined,
  FINAL_DOCUMENT_SUCCESS: undefined,
  FINAL_DOCUMENT_FAILURE: undefined,

  ...powerBi,

  DUPLICATES_GET_REQUEST: undefined,
  DUPLICATES_GET_REQUEST_SUCCESS: undefined,
  DUPLICATES_GET_REQUEST_FAILURE: undefined,

  GET_DUPLICATE_DOC: undefined,
  GET_DUPLICATE_DOC_SUCCESS: undefined,
  GET_DUPLICATE_DOC_FAILURE: undefined,

  DUPLICATES_MOVE_REQUEST: undefined,
  DUPLICATES_MOVE_REQUEST_SUCCESS: undefined,
  DUPLICATES_MOVE_REQUEST_FAILURE: undefined,

  SPLIT_PDF: undefined,
  SPLIT_PDF_SUCCESS: undefined,
  SPLIT_PDF_FAILURE: undefined,

  UPLOAD_SUPPORT_DOCUMENT: undefined,
  UPLOAD_SUPPORT_DOCUMENT_SUCCESS: undefined,
  UPLOAD_SUPPORT_DOCUMENT_FAILURE: undefined,

  GET_VIS_DATA: undefined,
  GET_VIS_DATA_SUCCESS: undefined,
  GET_VIS_DATA_FAILURE: undefined,

  LOCK_DOCUMENT: undefined,
  LOCK_DOCUMENT_SUCCESS: undefined,
  LOCK_DOCUMENT_FAILURE: undefined,

  UNLOCK_DOCUMENT: undefined,
  UNLOCK_DOCUMENT_SUCCESS: undefined,
  UNLOCK_DOCUMENT_FAILURE: undefined,

  GET_WORKFLOW_BY_ID: undefined,
  GET_WORKFLOW_BY_ID_SUCCESS: undefined,
  GET_WORKFLOW_BY_ID_FAILURE: undefined,

  UPDATE_ROUTE: undefined,
  SET_DATA_TO_DB: undefined,
  CLEAR_DATA_FROM_DB: undefined,

  REJECT_REVIEW: undefined,
  REJECT_REVIEW_SUCCESS: undefined,
  REJECT_REVIEW_FAILURE: undefined,

  FETCH_MASTER_DATA: undefined,
  FETCH_MASTER_DATA_SUCCESS: undefined,
  FETCH_MASTER_DATA_FAILURE: undefined,

  GET_HISTORY_DATA: undefined,
  GET_HISTORY_DATA_SUCCESS: undefined,
  GET_HISTORY_DATA_FAILURE: undefined,

  GET_PROMPT_RESPONSE: undefined,
  GET_PROMPT_RESPONSE_SUCCESS: undefined,
  GET_PROMPT_RESPONSE_FAILURE: undefined,

  GET_CHAT_HISTORY_DATA: undefined,
  GET_CHAT_HISTORY_DATA_SUCCESS: undefined,
  GET_CHAT_HISTORY_DATA_FAILURE: undefined,

  GET_CHAT_HISTORY_BY_ID_DATA: undefined,
  GET_CHAT_HISTORY_DATA_BY_ID_SUCCESS: undefined,
  GET_CHAT_HISTORY_DATA_BY_ID_FAILURE: undefined,

  GET_WORKSPACES_DATA: undefined,
  GET_WORKSPACES_DATA_SUCCESS: undefined,
  GET_WORKSPACES_DATA_FAILURE: undefined,

  GET_WORKSPACES_BY_ID_DATA: undefined,
  GET_WORKSPACES_DATA_BY_ID_SUCCESS: undefined,
  GET_CHAT_HISTORY_DATA_BY_ID_FAILURE: undefined,

  CREATE_WORKSPACE: undefined,
  CREATE_WORKSPACE_SUCCESS: undefined,
  CREATE_WORKSPACE_FAILURE: undefined,

  UPDATE_WORKSPACE: undefined,
  UPDATE_WORKSPACE_SUCCESS: undefined,
  UPDATE_WORKSPACE_FAILURE: undefined,

  ADD_NEW_CHART_TO_WORKSPACE: undefined,
  ADD_NEW_CHART_TO_WORKSPACE_SUCCESS: undefined,
  ADD_NEW_CHART_TO_WORKSPACE_FAILURE: undefined,

  DELETE_CHART_FROM_WORKSPACE: undefined,
  DELETE_CHART_FROM_WORKSPACE_SUCCESS: undefined,
  DELETE_CHART_FROM_WORKSPACE_FAILURE: undefined,

  UPDATE_WORKSPACE_TITLE: undefined,
  UPDATE_WORKSPACE_TITLE_SUCCESS: undefined,
  UPDATE_WORKSPACE_TITLE_FAILURE: undefined,

  DELETE_CHAT_FROM_HISTORY: undefined,
  DELETE_CHAT_FROM_HISTORY_SUCCESS: undefined,
  DELETE_CHAT_FROM_HISTORY_FAILURE: undefined,

  DELETE_QUESTION_FROM_CHAT_HISTORY: undefined,
  DELETE_QUESTION_FROM_CHAT_HISTORY_SUCCESS: undefined,
  DELETE_QUESTION_FROM_CHAT_HISTORY_FAILURE: undefined,

  UPDATE_CHAT_TITLE: undefined,
  UPDATE_CHAT_TITLE_SUCCESS: undefined,
  UPDATE_CHAT_TITLE_FAILURE: undefined,

  PUBLISH_OR_DRAFT_WORKSPACE: undefined,
  PUBLISH_OR_DRAFT_WORKSPACE_SUCCESS: undefined,
  PUBLISH_OR_DRAFT_WORKSPACE_FAILURE: undefined,

  DELETE_WORKSPACE: undefined,
  DELETE_WORKSPACE_SUCCESS: undefined,
  DELETE_WORKSPACE_FAILURE: undefined,

  CLEAR_REDUX_CHAT_DATA_OF_COMPONENT: undefined,

  SET_CHAT_CURRENT_COMPONENT: undefined,

  GET_ALL_SAVEFORLATER_CHATS: undefined,
  GET_ALL_SAVEFORLATER_CHATS_SUCCESS: undefined,
  GET_ALL_SAVEFORLATER_CHATS_FAILURE: undefined,

  GET_SAVEFORLATER_BY_CHAT: undefined,
  GET_SAVEFORLATER_BY_CHAT_SUCCESS: undefined,
  GET_SAVEFORLATER_BY_CHAT_FAILURE: undefined,

  GET_SAMPLE_QUESTIONS: undefined,
  GET_SAMPLE_QUESTIONS_SUCCESS: undefined,
  GET_SAMPLE_QUESTIONS_FAILURE: undefined,

  GET_QUESTION_COMMENTS: undefined,
  GET_QUESTION_COMMENTS_SUCCESS: undefined,
  GET_QUESTION_COMMENTS_FAILURE: undefined,

  CREATE_QUESTION_COMMENTS: undefined,
  CREATE_QUESTION_COMMENTS_SUCCESS: undefined,
  CREATE_QUESTION_COMMENTS_FAILURE: undefined,

  QUESTION_LIKE: undefined,
  QUESTION_LIKE_SUCCESS: undefined,
  QUESTION_LIKE_FAILURE: undefined,

  QUESTION_DISLIKE: undefined,
  QUESTION_DISLIKE_SUCCESS: undefined,
  QUESTION_DISLIKE_FAILURE: undefined,

  QUESTION_SAVED_FOR_LATER: undefined,
  QUESTION_SAVED_FOR_LATER_SUCCESS: undefined,
  QUESTION_SAVED_FOR_LATER_FAILURE: undefined,

  QUESTION_RATING: undefined,
  QUESTION_RATING_SUCCESS: undefined,
  QUESTION_RATING_FAILURE: undefined,

  UPDATE_QUESTION_CHART_TYPE: undefined,
  UPDATE_QUESTION_CHART_TYPE_SUCCESS: undefined,
  UPDATE_QUESTION_CHART_TYPE_FAILURE: undefined,

  QUESTION_SEND_EMAIL: undefined,
  QUESTION_SEND_EMAIL_SUCCESS: undefined,
  QUESTION_SEND_EMAIL_FAILURE: undefined,
  UPDATE_QUESTION_EMAIL_SUCCESS_STATE: undefined,

  GET_AUTOCOMPLETE_SUGGESTIONS: undefined,
  GET_AUTOCOMPLETE_SUGGESTIONS_SUCCESS: undefined,
  GET_AUTOCOMPLETE_SUGGESTIONS_FAILURE: undefined,

  QUESTION_SELECTED_CHART_DATA: undefined,

  SAVE_DOCUMENT_PROMPT: undefined,
  SAVE_DOCUMENT_PROMPT_SUCCESS: undefined,
  SAVE_DOCUMENT_PROMPT_FAILURE: undefined,

  FETCH_DOCUMENT_QUESTIONS: undefined,
  FETCH_DOCUMENT_QUESTIONS_SUCCESS: undefined,
  FETCH_DOCUMENT_QUESTIONS_FAILURE: undefined,

  GET_ALL_UNREAD_NOTIFICATIONS: undefined,
  GET_ALL_UNREAD_NOTIFICATIONS_SUCCESS: undefined,
  GET_ALL_UNREAD_NOTIFICATIONS_FAILURE: undefined,

  GET_SINGLE_NOTIFICATION: undefined,
  GET_SINGLE_NOTIFICATION_SUCCESS: undefined,
  GET_SINGLE_NOTIFICATION_FAILURE: undefined,

  UPDATE_NOTIFICATIONS: undefined,
  UPDATE_NOTIFICATIONS_SUCCESS: undefined,
  UPDATE_NOTIFICATIONS_FAILURE: undefined,

  CLEAR_ALL_NOTIFICATIONS: undefined,
  CLEAR_ALL_NOTIFICATIONS_SUCCESS: undefined,
  CLEAR_ALL_NOTIFICATIONS_FAILURE: undefined,

  CLEAR_SINGLE_NOTIFICATION: undefined,
  CLEAR_SINGLE_NOTIFICATION_SUCCESS: undefined,
  CLEAR_SINGLE_NOTIFICATION_FAILURE: undefined,

  CLEAR_NOTIFICATION_REDUX_STATE: undefined,

  GET_PAGE_BY_URL: undefined,
  GET_PAGE_BY_URL_SUCCESS: undefined,
  GET_PAGE_BY_URL_FAILURE: undefined,

  FETCH_PROCESS_DASHBOARD_1_DATA: undefined,
  FETCH_PROCESS_DASHBOARD_1_DATA_SUCCESS: undefined,
  FETCH_PROCESS_DASHBOARD_1_DATA_FAILURE: undefined,

  FETCH_PROCESS_DASHBOARD_2_DATA: undefined,
  FETCH_PROCESS_DASHBOARD_2_DATA_SUCCESS: undefined,
  FETCH_PROCESS_DASHBOARD_2_DATA_FAILURE: undefined,

  SELECTED_DASHBOARD_CHART_ITEMS: undefined,

  CREATE_AMENDMENT: undefined,
  CREATE_AMENDMENT_SUCCESS: undefined,
  CREATE_AMENDMENT_FAILURE: undefined,
  
  CREATE_AMENDMENT_NEW_V2: undefined,
  CREATE_AMENDMENT_NEW_V2_SUCCESS: undefined,
  CREATE_AMENDMENT_NEW_V2_FAILURE: undefined,

  GET_AMENDMENTS: undefined,
  GET_AMENDMENTS_SUCCESS: undefined,
  GET_AMENDMENTS_FAILURE: undefined,

  CREATE_CONTRACT_HIERARCHY: undefined,
  CREATE_CONTRACT_HIERARCHY_SUCCESS: undefined,
  CREATE_CONTRACT_HIERARCHY_FAILURE: undefined,
  
  GET_CONTRACT_HIERARCHYS: undefined,
  GET_CONTRACT_HIERARCHYS_SUCCESS: undefined,
  GET_CONTRACT_HIERARCHYS_FAILURE: undefined,

  GET_DOCUMENT_HIERARCHYS: undefined,
  GET_DOCUMENT_HIERARCHYS_SUCCESS: undefined,
  GET_DOCUMENT_HIERARCHYS_FAILURE: undefined,

  GET_DOCUMENT_HIERARCHYS_NEWV2: undefined,
  GET_DOCUMENT_HIERARCHYS_SUCCESS_NEWV2: undefined,
  GET_DOCUMENT_HIERARCHYS_FAILURE_NEWV2: undefined,

  UPDATE_DOCUMENT_HIERARCHYS: undefined,
  UPDATE_DOCUMENT_HIERARCHYS_SUCCESS: undefined,
  UPDATE_DOCUMENT_HIERARCHYS_FAILURE: undefined,

  DELETE_TREE_ELEMENTS: undefined,
  DELETE_TREE_ELEMENTS_SUCCESS: undefined,
  DELETE_TREE_ELEMENTS_FAILURE: undefined,

  GET_DOCUMENTS_FOR_HIERARCHYS: undefined,
  GET_DOCUMENTS_FOR_HIERARCHYS_SUCCESS: undefined,
  GET_DOCUMENTS_FOR_HIERARCHYS_FAILURE: undefined,

  SAVE_DOCUMENT_HIERARCHY: undefined,
  SAVE_DOCUMENT_HIERARCHY_SUCCESS: undefined,
  SAVE_DOCUMENT_HIERARCHY_FAILURE: undefined,

  FETCH_DOCUMENT_DATA_FROM_TARGET_DATAMODEL: undefined,
  FETCH_DOCUMENT_DATA_FROM_TARGET_DATAMODEL_SUCCESS: undefined,
  FETCH_DOCUMENT_DATA_FROM_TARGET_DATAMODEL_FAILURE: undefined,

  FETCH_DOCUMENT_DATA_TO_SUPPORT_MAPPING: undefined,
  FETCH_DOCUMENT_DATA_TO_SUPPORT_MAPPING_SUCCESS: undefined,
  FETCH_DOCUMENT_DATA_TO_SUPPORT_MAPPING_FAILURE: undefined,

  UPDATE_MAPPING_DATA: undefined,
  UPDATE_MAPPING_DATA_SUCCESS: undefined,
  UPDATE_MAPPING_DATA_FAILURE: undefined,

  FETCH_DOCUMENT_DATA_TO_SUPPORT_THREE_WAY_MATCH: undefined,
  FETCH_DOCUMENT_DATA_TO_SUPPORT_THREE_WAY_MATCH_SUCCESS: undefined,
  FETCH_DOCUMENT_DATA_TO_SUPPORT_THREE_WAY_MATCH_FAILURE: undefined,

  GET_DOCUMENT_ANNOTATIONS: undefined,
  GET_DOCUMENT_ANNOTATIONS_SUCCESS: undefined,
  GET_DOCUMENT_ANNOTATIONS_FAILURE: undefined,

  GET_NOTDUPLICATE: undefined,
  GET_NOTDUPLICATE_SUCCESS: undefined,
  GET_NOTDUPLICATE_FAILURE: undefined,

  GET_CURRENT_STEP_RESPONDENT: undefined,
  GET_CURRENT_STEP_RESPONDENT_SUCCESS: undefined,
  GET_CURRENT_STEP_RESPONDENT_FAILURE: undefined,

  CREATE_OPPORTUNITY: undefined,
  CREATE_OPPORTUNITY_SUCCESS: undefined,
  CREATE_OPPORTUNITY_FAILURE: undefined,

  GET_ALL_OPPORTUNITIES: undefined,
  GET_ALL_OPPORTUNITIES_SUCCESS: undefined,
  GET_ALL_OPPORTUNITIES_FAILURE: undefined,

  GET_SINGLE_OPPORTUNITY: undefined,
  GET_SINGLE_OPPORTUNITY_SUCCESS: undefined,
  GET_SINGLE_OPPORTUNITY_FAILURE: undefined,

  DELETE_OPPORTUNITY: undefined,
  DELETE_OPPORTUNITY_SUCCESS: undefined,
  DELETE_OPPORTUNITY_FAILURE: undefined,

  UPDATE_OPPORTUNITY: undefined,
  UPDATE_OPPORTUNITY_SUCCESS: undefined,
  UPDATE_OPPORTUNITY_FAILURE: undefined,

  CREATE_ACTION_FOR_OPPORTUNITY: undefined,
  CREATE_ACTION_FOR_OPPORTUNITY_SUCCESS: undefined,
  CREATE_ACTION_FOR_OPPORTUNITY_FAILURE: undefined,

  GET_ALL_ACTION_OF_OPPORTUNITY: undefined,
  GET_ALL_ACTION_OF_OPPORTUNITY_SUCCESS: undefined,
  GET_ALL_ACTION_OF_OPPORTUNITY_FAILURE: undefined,

  GET_SINGLE_ACTION_OF_OPPORTUNITY: undefined,
  GET_SINGLE_ACTION_OF_OPPORTUNITY_SUCCESS: undefined,
  GET_SINGLE_ACTION_OF_OPPORTUNITY_FAILURE: undefined,

  UPDATE_SINGLE_ACTION_OF_OPPORTUNITY: undefined,
  UPDATE_SINGLE_ACTION_OF_OPPORTUNITY_SUCCESS: undefined,
  UPDATE_SINGLE_ACTION_OF_OPPORTUNITY_FAILURE: undefined,

  DELETE_SINGLE_ACTION_OF_OPPORTUNITY: undefined,
  DELETE_SINGLE_ACTION_OF_OPPORTUNITY_SUCCESS: undefined,
  DELETE_SINGLE_ACTION_OF_OPPORTUNITY_FAILURE: undefined,

  GET_DOCUMENTS_FROM_IDS_OF_OPPORTUNITY: undefined,
  GET_DOCUMENTS_FROM_IDS_OF_OPPORTUNITY_SUCCESS: undefined,
  GET_DOCUMENTS_FROM_IDS_OF_OPPORTUNITY_FAILURE: undefined,

  RESOLVE_INTEGRATION_EXCEPTIONS: undefined,
  
  UPLOAD_ATTACHEMENT_OF_OPPORTUNITY:undefined,
  UPLOAD_ATTACHEMENT_OF_OPPORTUNITY_SUCCESS: undefined, 
  UPLOAD_ATTACHEMENT_OF_OPPORTUNITY_FAILURE: undefined,

  UPLOAD_ATTACHEMENT_OF_AMENDMENTTEMPLATE:undefined, 
  UPLOAD_ATTACHEMENT_OF_AMENDMENTTEMPLATE_SUCCESS: undefined, 
  UPLOAD_ATTACHEMENT_OF_AMENDMENTTEMPLATE_FAILURE: undefined,

  DELETE_ATTACHMENTS_FOR_OPPORTUNITY:undefined,
  DELETE_ATTACHMENTS_FOR_OPPORTUNITY_SUCCESS: undefined, 

  DELETE_ATTACHMENTS_FOR_OPPORTUNITY_FAILURE: undefined,
  RESOLVE_INTEGRATION_EXCEPTIONS_SUCCESS: undefined,
  RESOLVE_INTEGRATION_EXCEPTIONS_FAILURE: undefined,

  GET_SUB_MODAL_DATA: undefined,
  GET_SUB_MODAL_DATA_SUCCESS: undefined,
  GET_SUB_MODAL_DATA_FAILURE: undefined,

  GET_MEMBER_PREVILAGES: undefined,
  GET_MEMBER_PREVILAGES_SUCCESS: undefined,
  GET_MEMBER_PREVILAGES_FAILURE: undefined,

});

/**
 * @constant {Object} STATUS
 * @memberof Constants
 */
export const STATUS = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
};

/**
 * @constant {Object} APP LEVEL CONFIG
 * @memberof Constants
 */
export const APP_CONFIG = {
  APP_ID: process.env.APP_ID,
  APP_URI: '/' + process.env.APP_ID,
  APP_NAME: process.env.APP_NAME,
  APP_PRIVILEGECHECKS: process.env['REACT_APP_Dsilo.apprenederer.privilegechecks'],
};

export const PUBLIC_PATH = process.env.REACT_APP_PUBLIC_PATH
  ? process.env.REACT_APP_PUBLIC_PATH + '/'
  : '';

export const VIEWER_SUPPORT_APPLICATION = ['ciep', 'cvm'];