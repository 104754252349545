import React, { useState, useEffect } from "react";

import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { hot } from 'react-hot-loader/root';
import { withRouter, useHistory, useParams } from "react-router-dom";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { FormHelperText } from "@material-ui/core";
import { Grid, IconButton, Button, Typography, TextField, Divider, CircularProgress } from "@material-ui/core";

import { Helpers, ChipSelect } from "dsilo-ui-components";

import { getDataModelById, getContractDataById, clearReduxDataOfCurrentComponent, updateDataModelData, createDocument, getModelData } from '../../../store/actions';

import { Loader } from '../../loader';

import useStyles from '../styles';

const TemplateView = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const { id: _id } = useParams();

    const { chartData, chartUniqueId } = props.data

    const [allDataElementsObj, setAllDataElementsObj] = useState({})
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [state, setState] = useState({})

    let dataUniqueId = chartData.selectedDataModel;
    let selectedDataElements = chartData?.selectedDataElement || []

    const clearCurrentChartReduxStore = () => {
        let subKeys = [
            chartUniqueId + '_get',
            chartUniqueId + '_save',
            chartUniqueId + '_update',
            chartUniqueId + '_getClause'
        ]

        props.clearReduxDataOfCurrentComponent({
            key: "document",
            subKey: subKeys
        })
    }

    useEffect(() => {
        const editDataArray = props.formData?.document?.[chartUniqueId + '_get']?.data
        if (Array.isArray(editDataArray) && editDataArray.length > 0) {
            const docData = editDataArray[0];
            setState((prevState) => ({
                ...prevState,
                ...docData,
            }));
        }
    }, [props.formData]);

    useEffect(() => {
        let res = props.formData.document[chartUniqueId + '_save'];
        if (res) {
            toast.success(res.message || 'Document Created Successfully')
            clearCurrentChartReduxStore();
            handleBackBtnClick();
        }
    }, [props.formData?.document[chartUniqueId + '_save']])

    useEffect(() => {
        let res = props.formData.document[chartUniqueId + '_update'];
        if (res) {
            handleBackBtnClick();
            clearCurrentChartReduxStore();
        }
    }, [props.formData?.document[chartUniqueId + '_update']]);

    useEffect(() => {
        let res = props.formData.document[props.data.chartUniqueId + '_get'];
        if (res?.data?.[0]) {
            let newState = res?.data?.[0] || {}
            setState((prevState) => ({
                ...prevState,
                ...newState
            }));
        }
    }, [props.formData.document[props.data.chartUniqueId + '_get']])

    useEffect(() => {
        if (props.formData?.dataModel[dataUniqueId]?.dataElements) {
            let commonConfigList = props.formData?.dataModel[dataUniqueId]?.commonMasterDataConfiguration || []
            let dataElementsList = props.formData?.dataModel[dataUniqueId]?.dataElements || []
            let obj = {}
            dataElementsList.forEach((element, indexss) => {
                selectedDataElements && selectedDataElements.forEach((selectedElement) => {
                    if (element.name === selectedElement.value && element.data.type === "commonMasterDataConfiguration") {
                        commonConfigList.forEach((configItem) => {
                            if (element.data.commonMasterDataConfigurationId === configItem["_id"]) {
                                let payload = {
                                    appId: props.match.params.appid,
                                    id: configItem.dataModel,
                                };
                                props.getModelData({ ...payload, dataUniqueId: `${configItem["_id"]}` });
                            }
                        })
                    }
                })
                obj[element.name] = element
            })
            console.log('allDes ::: *** ', dataElementsList, obj);
            setAllDataElementsObj(obj)
        }
    }, [props.formData?.dataModel[dataUniqueId]])

    useEffect(() => {
        fetchDataModel();
        updateDefaultFields();
        fetchContractData();
        return () => {
            clearCurrentChartReduxStore();
        };
    }, []);

    // to fetch DataModel
    const fetchDataModel = () => {
        let dataModelId = chartData.selectedDataModel
        if (!props.formData?.dataModel?.[dataUniqueId] && !props.formData[`${dataUniqueId}_loading}`]) {
            let payload = {
                appid: props.match.params.appid,
                orgId: props.appConfig?.org?._id,
                dataUniqueId: dataUniqueId,
                dataModelId: dataModelId
            }
            props.getDataModelById(payload)
        }
    }

    const updateDefaultFields = () => {
        let fields = "";
        let isUpdated = false;
        let obj = {};

        if (chartData.selectedDataElement?.length) {
            chartData.selectedDataElement.forEach((sd) => {
                fields += sd.value + ",";
                if (sd.defaultValue) {
                    obj[sd.value] = sd.defaultValue;
                    isUpdated = true;
                }
            });

            if (isUpdated) {
                setState((prevState) => ({ ...prevState, ...obj }));
            }
        }
    };

    // to get template data based on the Id
    const fetchContractData = () => {
        if (_id) {
            let payload = {
                id: _id,
                dataModelId: chartData.selectedDataModel,
                appId: props.match.params.appid,
                fields: chartData.selectedDataElement.map(i => i.value).join(","),
                dataUniqueId: chartUniqueId + "_get",
            };
            props.getContractDataById(payload);
        }
    };

    const getOptions = (de) => {
        let options = []
        if (de.data?.type === 'custom') {
            if (de.data?.options?.length > 0) {
                options = de.data.options.map(option => {
                    return {
                        value: option?.value?.toString(),
                        label: option?.label?.toString()
                    }
                })
            }
        }
        else if (de.data?.type === 'commonMasterDataConfiguration') {
            options = props.formData?.document[de.data.commonMasterDataConfigurationId]?.data.map((resd) => {
                return {
                    value: resd?.languageLocale?.toString(),
                    label: resd?.languageLocale?.toString()
                }
            })
        }
        return options;
    }

    const getValue = (key) => {
        let value = { value: state[key], label: state[key] }
        if (value && (value.value === null || value.value === undefined)) {
            value.value = '';
        }
        if (value && (value.label === null || value.label === undefined)) {
            value.label = '';
        }
        return value
    }

    const createUpdateTemplate = () => {
        let data = JSON.parse(JSON.stringify(state))
        let isValid = true;
        chartData.selectedDataElement.forEach(sd => {
            if (!sd.hide && !sd.readonly) {
                if (!state[sd.value] || state[sd.value].toString().trim() === "") {
                    isValid = false;
                }
            }
        });

        if (!isValid) {
            setIsSubmitted(true);
            return;
        }
        //update template with id
        if (_id) {
            let formData = {
                appid: props.match.params.appid,
                dataModelId: chartData.selectedDataModel,
                dataUniqueId: props.data.chartUniqueId + '_update',
                payload: data,
            }
            props.updateDataModelData(formData)
            //create a new template
        } else {
            let formData = {
                appid: props.match.params.appid,
                dataModelId: chartData.selectedDataModel,
                dataUniqueId: props.data.chartUniqueId + '_save',
                payload: data,
            }
            props.createDocument(formData)
        }
    }

    const updateContractTemplate = (event, field) => {
        const { value } = event.target;
        setState((prev) => ({
            ...prev,
            [field.value]: value,
        }));
    };

    if (Helpers.loading(props, '_get')) {
        return <Loader />
    }

    const handleBackBtnClick = () => {
        history.goBack()
    }

    return (
        <>
            <Grid container item xs={12} className={classes.clauseDataDiv}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => { props.history.goBack() }} title={'Back'}><ArrowBackIosIcon /></IconButton>
                    <h2>{props.page.title}</h2>
                </div>
                <div>
                    <Button
                        color="primary" onClick={createUpdateTemplate}
                        style={{ textTransform: 'capitalize', marginTop: '5px' }}
                        variant="contained">
                        {
                            (Helpers.loading(props, '_save') || Helpers.loading(props, '_update')) ? <CircularProgress color='#fff' size={24} /> : (_id ? "Update" : "Add")
                        }
                    </Button>
                </div>
            </Grid>
            <Divider />
            <Grid container spacing={2} columnSpacing={3} xs={12} style={{ marginTop: 10, position: 'relative', overflow: 'visible', maxWidth: '80%' }}>
                {!Helpers.loading(props, '_get') && chartData.selectedDataElement.map((field, index) => {
                    let de = allDataElementsObj[field.value]
                    let type = de?.type || 'string'

                    return (
                        <Grid container item xs={6} key={field.value} alignItems="center">
                            <Grid item xs={3} style={{ paddingRight: 16 }}>
                                <Typography variant="body2">  {field.label}
                                    {field.required && <span style={{ color: "#d32f2f" }}>*</span>}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                {(type === "select" || (de?.data && de?.data?.type && de?.data?.type === "custom")) ? (
                                    <>
                                        <ChipSelect
                                            id={`${field.value}`}
                                            label=""
                                            required
                                            isSearchable
                                            placeholder=""
                                            isClearable={true}
                                            menuPlacement="top"
                                            options={getOptions(de)}
                                            isDisabled={field.readonly}
                                            value={getValue(field.value)}
                                            className={classes.TextField}
                                            textFieldProps={{
                                                InputLabelProps: { shrink: true },
                                                variant: "outlined",
                                                disabled: field.readonly,
                                            }}
                                            variant={"fixed"}
                                            onChange={(v) => updateContractTemplate({ target: { value: v?.value } }, field)}
                                        />
                                        {isSubmitted && !state?.[field.value] && (
                                            <FormHelperText error>This field is required</FormHelperText>
                                        )}
                                    </>

                                ) : (
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        required
                                        className={classes.TextField}
                                        onChange={(event) => updateContractTemplate(event, field)}
                                        value={state?.[field.value] || ""}
                                        fullWidth
                                        placeholder={'Enter ' + field.label}
                                        error={isSubmitted && (!state?.[field.value] || state?.[field.value].trim() === "")}
                                        helperText={isSubmitted && (!state?.[field.value] || state?.[field.value].trim() === "") ? "This field is required" : ""}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

const mapDispatchToProps = {
    getDataModelById,
    getContractDataById,
    clearReduxDataOfCurrentComponent,
    createDocument,
    updateDataModelData,
    getModelData
}

const mapStateToProps = state => {
    return {
        formData: state.form,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(TemplateView)))

