export const getColumns = (data) => {
    let COLUMNS = []
    if (data && data.length) {
        COLUMNS = Object.keys(
            data[0]
        ).map((i) => {
            return {
                id: i,
                numeric: false,
                disablePadding: false,
                label: i,
                sort: true,
                checked: true
            }
        }).filter(i => i.id !== 'id')
    }
    return COLUMNS
}

export const sharedJSON = {
    "order": "asc",
    "orderBy": null,
    "selected": [],
    "tableData": [],
    "filterTableData": [],
    // "page": 0,
    "count": 10,
    // "rowsPerPage": 10,
    "searchText": "",
    "filterList": [],
    "filterData": []
}

export const modifyDataIntoNeededFormat = (dataSource, chartData) => {
    const clonedDataSource = JSON.parse(JSON.stringify(dataSource))
    let fetchType;
    let selectedInnerSupportDataElement;
    return clonedDataSource.map((item) => {
        const keys = Object.keys(item)
        keys.forEach((key) => {
            if (key && typeof item[key] !== "string") {
                if (fetchType === "keysArray" && !selectedInnerSupportDataElement) {
                    if (chartData?.selectedInnerDataElement?.length) {
                        chartData?.selectedInnerDataElement?.map(side => {
                            if (side.value === key) {
                                if (side.type === "namepicker") {
                                    if (item[key] instanceof Array) {
                                        item[key] = item[key].map(obj => obj.label).join(', ')
                                    } else {
                                        item[key] = item[key]
                                            ? (typeof item[key] === "object"
                                                ? item[key][label]
                                                : item[key])
                                            : ""
                                    }
                                } else {
                                    item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                                }
                            }
                        })
                    } else {
                        item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                    }
                } else {
                    if (chartData?.selectedDataElement?.length) {
                        chartData?.selectedDataElement?.map(sde => {
                            if (sde.value === key) {
                                if (sde.type === "namepicker") {
                                    if (item[key] instanceof Array) {
                                        item[key] = item[key].map(obj => obj.label).join(', ')
                                    } else {
                                        item[key] = item[key]
                                            ? (typeof item[key] === "object"
                                                ? item[key][label]
                                                : item[key])
                                            : ""
                                    }
                                } else {
                                    item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                                }
                            }
                        })
                    } else {
                        item[key] = item[key] || item[key] === 0 ? JSON.stringify(item[key]) : ""
                    }
                }
            }
        })
        return item
    })
}