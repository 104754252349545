import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { ToastContainer } from 'react-toastify';
import { Route, Router, Switch } from 'react-router-dom';

// Internal library
import { CacheBuster, history } from 'dsilo-ui-components';

// Pages
import Org from './Pages/Org/Org';
import Profile from './Pages/Profile';
import MFAComponent from './Pages/MFA';
import SAMLComponent from './Pages/SAML';
import NotFound from './Pages/NotFound';
import LoginComponent from './Pages/Login';
import AppLoading from './Pages/AppLoading';
import SignupComponent from './Pages/Signup';
import ContactDsilo from './Pages/Contactus';
import VerifyEmail from './Pages/VerifyEmail';
import AppsDashboard from './Pages/AppsDashboard';
import Contracts from './Pages/Contracts/Contracts'; //TODO: need to move this components css into ui-library.
import AppHeader from './Pages/AppsDashboard/AppHeader';
// import Challenges from './Pages/Challenges/Challenges';
import ResetPassword from './Pages/ResetPassword/ResetPassword';

// Internal components
import { Auth } from 'components/auth';
import SilentSSO from './components/SilentSSO';
import Layout from './components/Layout/Layout';
import ProcureGpt from './components/ProcureGpt';
import CookieConsent from './components/CookieConsent';
import SelfServiceModels from './components/SelfServiceAi/models';
import PreAppLoading from './components/PreAppLoading/PreAppLoading';
import SelfServiceModelDetails from './components/SelfServiceAi/modelDetails';

// Redux store actions
import { loadAppconfig } from './store/actions';

// Routes
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';

import 'styles/styles.scss';

import metaJson from '../assets/meta.json';
global.appVersion = metaJson.version;
global.GITHASH = metaJson.hash;

const ProcureChatGpt = () => {
	return (
		<AppHeader showBgColor>
			<ProcureGpt />
		</AppHeader>
	)
}

export const App = (props) => {
	const { user } = props
	const [trigger, setTrigger] = useState(false)
	const [e401, setE401] = useState(false)

	useEffect(() => {
		if (location.protocol !== 'https:' && location.hostname !== "localhost") {
			location.replace(`https:${location.href.substring(location.protocol.length)}`);
			return
		}

		// To verify user session, when user open the browser tab
		// Silent SSO Triggered
		const handleVisibilityChange = () => {
			if (document.visibilityState === "visible") {
				console.log("Tab is visible. Run your code here.");
				setTrigger(true)
			} else {
				console.log("Tab is invisible. Run your code here.");
				setTrigger(false)
			}
		};

		const handleFocus = async () => {
			console.log("Tab is focused. Run your code here.");
		};

		// To verify user session, when user open the browser tab
		document.addEventListener("visibilitychange", handleVisibilityChange);
		// window.addEventListener("focus", handleFocus);
	}, [])

	useEffect(() => {
		console.log("SSO Expired", user.ssoExpired)
		if (user.ssoExpired) {
			setTrigger(true)
			setE401(true)
		} else {
			setTrigger(false)
			setE401(false)
		}
	}, [user.ssoExpired])

	let publicPath = process.env.REACT_APP_PUBLIC_PATH ? process.env.REACT_APP_PUBLIC_PATH + '/' : ''
	let initUrl = `${publicPath}${':appid'}`

	const secretKey = process.env.REACT_APP_CRYPT_SECRET_KEY || '';
	return (
		<React.Fragment>
			<CacheBuster publicPath={process.env.REACT_APP_PUBLIC_PATH} global={global} process={process}>
				{({ loading, isLatestVersion, refreshCacheAndReload }) => {
					if (loading) return <PreAppLoading />;
					if (!loading && !isLatestVersion) {
						// You can decide how and when you want to force reload
						refreshCacheAndReload();
						return <PreAppLoading />
					}
					return <><div className={"app-cvm"} >
						<ToastContainer hideProgressBar={true} />
						<CookieConsent />
						<SilentSSO secretKey={secretKey} restart={trigger} e401={e401}/>
						<Auth>
							<Router history={history}>
								<Switch>
									<PublicRoute path={`/${publicPath}login`} exact component={LoginComponent} />
									<PublicRoute path={`/${publicPath}signup`} exact component={SignupComponent} />
									<PublicRoute path={`/${publicPath}mfaaad`} exact component={MFAComponent} />
									<PublicRoute path={`/${publicPath}saml/acs`} exact component={SAMLComponent} />
									<Route path={`/${publicPath}resetpassword/:token`} exact component={ResetPassword} />
									<PublicRoute path={`/${publicPath}forgotpassword`} exact component={ResetPassword} />
									<PublicRoute path={`/${publicPath}verifypage/:token`} exact component={VerifyEmail} />
									<PrivateRoute path={[`/${initUrl}/profile`, '/profile']} exact component={Profile} />
									<PrivateRoute path={[`/${initUrl}/aiAssistant`, '/aiAssistant']} exact component={ProcureChatGpt} />
									<PrivateRoute path={[`/${initUrl}/org`, '/org']} exact component={Org} />
									<PrivateRoute path={`/apploading`} exact component={AppLoading} />
									<PrivateRoute path={'/apps'} exact component={AppsDashboard} />
									<PrivateRoute path={'/contact-dsilo'} exact component={ContactDsilo} />
									{/* {props.members && <Switch> */}
									<PrivateRoute path={['/', `/${initUrl}`, `/${initUrl}/page/:page`,
										`/${initUrl}/page/:page/:id`,
                                        `/${initUrl}/page/:page/:id/sd/:sdid`,
										`/${initUrl}/page/:page/:id/pi/:pi`,
										`/${initUrl}/page/:page/:id/pi/:pi/rpp/:rpp`,
										`/${initUrl}/page/:page/:pagetype/:id`]} exact component={Layout} />
									<PrivateRoute path={[`/${initUrl}/selfserviceai/model/:id`]} exact component={SelfServiceModelDetails} />
									<PrivateRoute path={[`/${initUrl}/selfserviceai/models`]} exact component={SelfServiceModels} />
									<Route component={NotFound} />
									{/* {props.memberSuccess && <Route component={NotFound} /> } */}
								</Switch>
							</Router>
						</Auth>
					</div></>
				}}
			</CacheBuster>
		</React.Fragment>
	);
};

const mapDispatchToProps = {
	loadAppconfig,
};

const mapStateToProps = state => {
	return {
		appConfig: state.appConfig.app,
		members: state.appConfig.members,
		memberSuccess: state.appConfig.memberSuccess,
		apps: state.app.app,
		login: state.login,
		user: state.user,
	};
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(App));
