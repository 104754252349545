import React, { useEffect, useMemo, useState, useRef } from 'react';

// Imports from 3rd party libraries
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';

import classNames from "classnames";

// Components import from material-ui
import { FormControl, InputLabel, Select, MenuItem, Paper, Divider, Input, IconButton } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

// Icons import from 3rd party libraries
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close"

// Imports from internal library
import { AnnotationTable } from 'dsilo-ui-components'

// Internal Components
import PDFViewerComponent from '../PDFViewer';
import PDFAnnotator from "../../components/Annonator/PDFAnnotator";
import PDFEditableTableWrapperComponent from '../PDFEditableTableViewer';
import AnnotationPdfTableViewerComponent from '../AnnotationPdfTableViewer';
import PDFExtractedDataTablesViewComponent from '../PDFExtractedTables';
import PDFQAView from '../PDFQAView';
import PDFComplexView from '../PDFComplexView'
import PDFComplexViewV2 from '../PDFComplexViewV2'
import PDFComplexViewV3 from '../PDFComplexViewV3'
import PDFInvoiceGLCodes from '../PDFInvoiceGLCodes'
import PDFStepperView from '../PDFStepperView'
import StepperView from '../StepperView'
import ContractFieldsSummaryView from '../ContractFieldsSummaryView'
import CognitiveSearch from '../CognitiveSearch';
import PDFWorkFlow from '../PDFWorkFlow';
import MailBox from '../MailBox/mailBoxOldWrapper';
import LayoutFilter from './LayoutFilter'
import PDFCompleteViewer from '../PDFCompleteViewer'
import DeleteDocumentViewer from '../DeleteDocumentViewer'
import TileListComponent from '../TileList';
import UploadPDFComponent from '../UploadPDFComponent';
import ListComponent from '../ListComponent';
import MiniTable from '../MiniTable';
import EditableTableComponent from '../EditableTable';
import ContractDetailsComponent from '../ContractDetails';
import LabelColorBoxSupport from '../LabelColorBoxSupport';
import ValueContainersV1Support from './../ValueContainersV1Support';
import AiModel from './../../Pages/AiBuilder/Model/index';
import ActionsComponent from '../Actions';
import ModelDetails from './../../Pages/AiBuilder/ModelDetails';
import ModelAliasNameList from '../ModelAliasNameList';
import ModelAliasNameSupporter from '../ModelAliasNameSupporter';
import OnboardingOverView from '../OnboardingOverView';
import SupplierMainInfoBox from '../OnboardingOverView/SupplierMainInfoBox';
import AdvanceDocComparison from '../AdvanceDocComparison';
import ReconciliationDashboard from "../DOMA/ReconcialiationDashboard";
import ReconciliationList from "../DOMA/ReconcialiationList";
import ElasticSearchWrapper from "../ElasticSearch/index";
import ElasticSearchTableComponent from "../ElasticSearchTableComponent/index";
import ElasticSearchFileComponent from "../ElasticSearchFiles/index";
import DealSheetComponent from "../DealSheetComponent/index";
import SmallOrganizationViewRedux from './../Cards/SmallOrganizationViewRedux';
import HighChart from '../../components/highcharts/Chart';
import TotalSpend from '../../components/TotalSpend';
import TopClausesComponent from '../../components/TopClauses';
import BusinessRule from 'Pages/BusinessRule';
import BusinessRules from 'Pages/BusinessRules';
import MyTasks from '../MyTasks';
import ContractViewComponent from '../ContractViewComponent';
import MyTaskListWrapper from '../MyTasks/myTaskListWrapper';
import DatapointsListWrapper from '../DataPointsList/datapointsListWrapper';
import Task from '../../components/Task';
import AliasNameList from '../AliasNameList';
import AuditTrail from '../AuditTrail';
import BulkUpload from '../BulkUpload';
import InvoiceDashboard from '../../Pages/InvoiceDashboard';
import SpendAnalyticsDashboard from '../../Pages/SpendAnalyticsDashboard';
import VendorDashboard from '../../Pages/VendorDashboard';
import ContractsInsights from '../../Pages/ContractsinsightsNew';
import DocumentHierarchy from '../DocumentHierarchy'
import WorkbookView from '../WorkbookView';
import ProcureGpt from '../ProcureGpt';

// System pages
import UserManagement from '../../Pages/UserManagement';
import AddMember from '../../Pages/UserManagement/AddMember';
import Groups from '../Groups';
import AddGroups from '../Groups/AddGroup';
import ChargeCode from '../ChargeCode';
import AddChargeCode from '../ChargeCode/AddChargeCode';
import Tickets from '../Tickets';
import AddTickets from '../Tickets/AddTickets';
import SimpleForm from '../SimpleForm/index';
import JustForm from '../JustForm/index';
import MailList from '../MailBox/index';
import MailConfig from '../MailBox/mailBox';
import Delegations from '../Delegation';
import AddDelegation from '../Delegation/AddDelegation';
import Others from '../Others';
import PowerBiComponent from '../../Pages/PowerBi';
import DuplicateViewer from '../DuplicateViewer';
import PageLevelFilters from '../PageLevelFilters';
import FormulaGenerator from '../FormulaGenerator';
import DocumentViewerV2 from '../DocumentViewer'

// Dashboard components
import { ProcessDashboard1, ProcessDashboard2 } from '../ProcessDashboards';

import { Loader } from "../loader";

import Supplier from '../../images/supplier.png';

// Related to styles
import { layoutStylesGeneration } from './layoutStylesGenerator';
import { useStyles } from './styles';
import './styles.css';
import Privileges from '../../Pages/Privileges';
import AddPrivilege from '../../Pages/Privileges/AddPrivilege';
import Roles from '../../Pages/Roles';
import AddRole from '../../Pages/Roles/AddRole';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const colCard = 4;

let initialLayout = [], defaultBreakpoint = "lg", mountedDefault = false, compactTypeDefault = "vertical";
let layoutProps = {
	className: "layout",
	rowHeight: 30,
	onLayoutChange: function () { },
	cols: { lg: 12, md: 12, sm: 12, xs: 4, xxs: 2 },
};

const LayoutBox = (props) => {
	const [breakpoint, setBreakpoint] = useState(defaultBreakpoint);
	const [mounted, setMounted] = useState(mountedDefault);
	const [compactType, setCompactType] = useState(compactTypeDefault);
	const [state, setState] = useState({ isFilterOn: false });
	// const [pageState, setPageState] = useState({ page: props.page });
	const [localLoading, setLocalLoading] = useState(true)
	const [searchValue, setSearchValue] = useState("");
	const [cognitiveSearchValue, setCognitiveSearchValue] = useState("");
	const [changedCognitiveSearchValue, setChangedCognitiveSearchValue] = useState(false);
	const [layout, setLayout] = useState(props.page?.layout || false);
	const [layoutStyles, setLayoutStyles] = useState(layoutStylesGeneration(props.page?.layout || false));
	const [pageFilterConditions, setPageFilterConditions] = useState([]); // filter array to be sent in api call

	let page = props.page && JSON.parse(JSON.stringify(props.page));

	useEffect(() => {
		setMounted(true);
		setTimeout(() => {
			setLocalLoading(false);
			window.dispatchEvent(new Event('resize'))
		}, 1500)
		return () => {
			console.log("on return useeffect");
		}
	}, []);

	// useEffect(() => {
	// 	setPageState({ page: props.page })
	// }, [props.page])

	useEffect(() => {
		setLayout(props.page?.layout)
	}, [props.page?.layout])

	useEffect(() => {
		let layoutStylesDump = layoutStylesGeneration(layout)
		setLayoutStyles(layoutStylesDump)
	}, [layout])

	useEffect(() => {
		setCompactType(props.compactType);
	}, [props.compactType])

	const onBreakpointChange = breakpoint => {
		setBreakpoint(breakpoint);
	};

	const onLayoutChange = (layout, layouts) => {
		console.log("onLayoutChange");
	}

	const onResize = (layout, oldItem, newItem, placeholder, e, element) => {
		console.log("onResize");
	};

	const onDrop = elemParams => {
		console.log("onDrop");
	};
	/**
	 * This below variable is for checking whether to display the Cognitive Search or not
	 */
	const showCognitiveSearch = useMemo(() => {
		return [
			props?.page?.chart &&
			props?.page?.chart.length > 0 &&
			props?.page?.chart?.[0].chartData?.cognitiveSearch
		].every(Boolean);
	}, [JSON.stringify(props?.page?.chart)]);

	let allRefs = []
	let allChart = null, layouts = { lg: initialLayout };
	const classes = useStyles();
	allChart = page &&
		page.chart &&
		page.chart.length > 0 &&
		page.chart.map((cData, i) => {

			let chartStyle = {
				height: cData.chartType === 'stepper'
					? 'calc(-170px + 100vh)'
					: cData.chartType === 'PDFInvoiceGLCodesView'
						? 'calc(100vh - 95px)'
						: !props.page?.title || props?.page?.hideHeader
							? page.chart.length.length === 1
								? "100%" // TODO: need to test without title
								: "100%"
							: page.chart.length.length === 1
								? '100%'
								: '100%',
				// : 'calc(100vh - 130px)',
				// height: 'calc(100vh - 130px)'
				// height: window.innerHeight < 900 ? 'calc(100vh - 95px)' : 'calc(100vh - 130px)', /// 140 is the max width of header + title 
				width: cData.chartType === "PDFInvoiceGLCodesView"
					? (props.navBarOpen
						? 'calc(100vw - 270px)'
						: 'calc(100vw - 10px)')
					: (props.hideTitle
						? "100vw"
						: (props.navBarOpen
							? 'calc(100vw - 260px)'
							: 'calc(100vw - 100px)')), // 310 & 145 is with of sidebar
				overflow: 'auto',
				// backgroundColor: '#fff'  // Only needed when configured
				...(layoutStyles.chartStyles || {}),
				paddingTop: (!props.page?.title || props?.page?.hideHeader) ? "0px" : "25px"
			}
			console.log("layoutStyles?.chartLevelPaper == = =", layoutStyles?.chartLevelPaper, cData.chartUniqueId)
			if (layoutStyles?.chartLevelPaper) {
				chartStyle.height = window.screen.availWidth > 1700
					? 'calc(100vh - 95px)'
					: 'calc(100vh - 120px)' /// 140 is the max width of header + title
			}
			console.log("chartStyle ====", chartStyle)
			return (
				<div key={i}
					// id="PageDesignerCard1-wrapper" 
					id={cData.chartUniqueId}
					style={chartStyle}>

					{/* To display the title */}
					{props.page?.title && !props.page.hideHeader
						&& <>
							{!props.hideTitle && (<>
								<div className={classes.titleSearchDiv}>
									<h4>{page.title}</h4>
									{showCognitiveSearch && (
										<Paper className={classNames(classes.searchRoot)}>
											<div className={classes.searchContainer}>
												<Input
													disableUnderline
													className={classes.input}
													id='standard-basic'
													label='Cognitive Search'
													variant="outlined"
													value={searchValue}
													onChange={handleSearchInput}
													onKeyDown={handleKeyUp}
													placeholder="Cognitive Search"
												/>
											</div>
											<IconButton
												className={classNames(classes.iconButton, classes.searchIconButton, {
													[classes.iconButtonHidden]: cognitiveSearchValue !== "",
												})}
											>
												<SearchIcon className={classes.icon} color="primary" />
											</IconButton>
											<IconButton
												onClick={handleCancelSearch}
												className={classNames(classes.iconButton, {
													[classes.iconButtonHidden]: cognitiveSearchValue === "",
												})}
											>
												<CloseIcon className={classes.icon} color="primary" />
											</IconButton>
										</Paper>
									)}
									{layout && <FormControl >
										{/* <InputLabel id="layout-select-label">Layout</InputLabel> */}
										<Select
											labelId="layou-select-label"
											id="layout--select"
											value={layout}
											label="Age"
											onChange={handleLayoutChange}
										>
											<MenuItem value={"layout1"}>Layout 1</MenuItem>
											<MenuItem value={"layout2"}>Layout 2</MenuItem>
											<MenuItem value={"layout3"}>Layout 3</MenuItem>
											<MenuItem value={"layout4"}>Layout 4</MenuItem>
											<MenuItem value={"layout5"}>Layout 5</MenuItem>
											<MenuItem value={"layout6"}>Layout 6</MenuItem>
										</Select>
									</FormControl>}
								</div>
								<Divider />
							</>)}
						</>
					}

					{/* externalFilters view */}
					{page && page?.externalFilter && page?.externalFilter.length && page?.selectedDataModel &&
						<PageLevelFilters
							{...props}
							chartUniqueId="pageFilterChrtUnqeId"
							externalFilters={page.externalFilter}
							filterConditions={pageFilterConditions}
							savePageFilterConditions={setPageFilterConditions}
							page={page}
						/>
					}

					{/* <Suspense fallback={<Loader />}> */}
					{(function () {
						switch (cData.chartType) {
							case 'TILELIST':
								return <TileListComponent page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} cognitiveSearchText={cognitiveSearchValue}
									changedCognitiveSearchValue={changedCognitiveSearchValue} />
							// all upload components
							case 'BulkUpload':
								return <BulkUpload page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'UPLOAD':
								return <UploadPDFComponent page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							// All PDF viewer components
							case 'PDFView':
								return <PDFViewerComponent page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'PDFSimpleTableView':
								return <AnnotationPdfTableViewerComponent page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'PDFTableView':
								return <PDFEditableTableWrapperComponent page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'PDFExtractedDataTablesView':
								return <PDFExtractedDataTablesViewComponent page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'annotator':
								return <PDFAnnotator page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'PDFComplexView':
								return <PDFComplexView page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'PDFComplexViewV2':
								return <PDFComplexViewV2 page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'PDFComplexViewV3':
								return <PDFComplexViewV3 page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId}
									handleSideNavBarClose={props.handleSideNavBarClose} />
							case 'ContractFieldsSummaryView':
								return <ContractFieldsSummaryView page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'PDFQAnalystViewV2':
							case 'PDFQAnalystView':
								return <PDFQAView page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId}
									handleSideNavBarClose={props.handleSideNavBarClose} />
							case 'PDFStepperView':
								return <PDFStepperView
									page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId}
									handleSideNavBarClose={props.handleSideNavBarClose}
								/>
							case 'stepper':
								return <StepperView
									page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId}
									handleSideNavBarClose={props.handleSideNavBarClose}
								/>
							case 'PDFInvoiceGLCodesView':
								return <PDFInvoiceGLCodes page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId}
									handleSideNavBarClose={props.handleSideNavBarClose} />
							// All table viewer components
							case 'AnnotationTable':
								return <AnnotationTable page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'EditableTableView':
								return <EditableTableComponent page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'myTasks':
								return <MyTasks noDataText={'There are no tasks assigned to you'}
									page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString}
									appId={props.appId} />
							case 'MyTaskList':
								return <MyTaskListWrapper page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString}
									appId={props.appId} />
							case 'ContractDetails':
								return <ContractDetailsComponent page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'recentItems':
								return <MiniTable noDataText={'There are no recent items'}
									page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString}
									appId={props.appId} />
							case 'MINITABLE':
								return <MiniTable noDataText={'There are no items display'}
									page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString}
									appId={props.appId} />
							case 'documentViewer':
								return <DocumentViewerV2 page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'list':
								return <ListComponent page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} cognitiveSearchText={cognitiveSearchValue} pageFilterConditions={pageFilterConditions}
									changedCognitiveSearchValue={changedCognitiveSearchValue} />
							// Datapoints UI
							case 'DataPointsList':
								return <DatapointsListWrapper page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString}
									appId={props.appId} />
							case 'DatapointAliasNameList':
								return <AliasNameList page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							// Dashboard components
							case 'LabelColorBoxes':
								return <LabelColorBoxSupport page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} layoutStyles={layoutStyles} pageFilterConditions={pageFilterConditions} />
							case 'ValueContainersV1':
								return <ValueContainersV1Support page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							// Rules view
							case 'RULEVIEW':
								return <BusinessRule page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							case 'RULES':
								return <BusinessRules page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							case 'TOTALSPEND':
								return <TotalSpend page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							// Models view
							case 'MODELSLIST':
								return <AiModel page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							case 'MODELDETAILS':
								return <ModelDetails page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							case 'ModelAliasNameSupporter':
								return <ModelAliasNameSupporter page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							case 'ModelAliasNameList':
								return <ModelAliasNameList page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							// Search
							case 'CognitiveSearch1':
								return <CognitiveSearch page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'ELASTICSEARCH':
								return <ElasticSearchWrapper page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							case 'ELASTICSEARCHTABLE':
								return <ElasticSearchTableComponent page={page} key={cData.chartUniqueId}
									data={cData}
									queryString={props.queryString} appId={props.appId} />
							case 'ELASTICSEARCHFILES':
								return <ElasticSearchFileComponent page={page} key={cData.chartUniqueId}
									data={cData}
									queryString={props.queryString} appId={props.appId} />
							// Static components
							case 'Actions':
								return <ActionsComponent page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							case 'ONBOARDINGOVERVIEW':
								return <OnboardingOverView page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							case 'SUPPLIERMAININFO':
								return <SupplierMainInfoBox page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							case 'AdvancedDocumentComparisonTable':
								return <AdvanceDocComparison page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							case 'ReconciliationDashboard':
								return <ReconciliationDashboard page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							case 'ReconciliationList':
								return <ReconciliationList page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							// Custom components
							case 'DEALSHEET':
								return <DealSheetComponent page={page} key={cData.chartUniqueId} data={cData}
									queryString={props.queryString} appId={props.appId} />
							case 'SMALLORGANIZATIONVIEW':
								return <SmallOrganizationViewRedux page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId}
									defaultLogo={Supplier} />
							case 'TOPCLAUSES':
								return <TopClausesComponent page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId}
									defaultLogo={Supplier} />
							case 'CONTRACTVIEW':
								return <ContractViewComponent page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId}
									defaultLogo={Supplier} />
							case 'TASK':
								return <Task page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'AuditTrail':
								return <AuditTrail page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'InvoiceDashBoard':
								return <InvoiceDashboard page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'SPENDANALYTICS':
								return <SpendAnalyticsDashboard page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'VendorDashboard':
								return <VendorDashboard page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'ContractInsights':
								return <ContractsInsights page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'mailBox':
								return <MailList page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'mailBoxEdit':
								return <MailConfig page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'PDFWorkFlow':
								return <PDFWorkFlow page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} handleSideNavBarClose={props.handleSideNavBarClose} />
							case 'UserManagement':
								return <UserManagement page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'UserManagementAdd':
								return <AddMember page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'RolesList':
								return <Roles page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'RoleCreateUpdate':
								return <AddRole page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'PrivilegesList':
								return <Privileges page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'PrivilegeCreateUpdate':
								return <AddPrivilege page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'groups':
								return <Groups page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'AddGroups':
								return <AddGroups page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'ChargeCode':
								return <ChargeCode page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'AddChargeCode':
								return <AddChargeCode page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'Tickets':
								return <Tickets page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'AddTickets':
								return <AddTickets page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'PDFCompleteViewer':
								return <PDFCompleteViewer page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'DeleteDocumentViewer':
								return <DeleteDocumentViewer page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'Delegation':
								return <Delegations page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'AddDelegation':
								return <AddDelegation page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'others':
								return <Others page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'SimpleForm':
								return <SimpleForm page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'JustForm':
								return <JustForm page={page} key={cData.chartUniqueId}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'powerbi':
								return <PowerBiComponent page={page} appDetails={props.appDetails} />
							case 'DuplicateViewer':
								return <DuplicateViewer page={page} appDetails={props.appDetails}
									data={cData} queryString={props.queryString} appId={props.appId} />
							case 'DsiloOAIGPT': {
								if (props.hideCompleteSideNavBar) props.hideCompleteSideNavBar(true)
								return <ProcureGpt chartData={cData.chartData} page={page} hideCompleteSideNavBar={props.hideCompleteSideNavBar} chartUniqueId={cData.chartUniqueId} />
							}
							case "FormulaGenerator":
								return <FormulaGenerator page={page} appId={props.appId} />
							// All high charts
							case 'pie':
							case 'mixed':
							case 'doughnut':
							case 'bar':
							case 'stackedbar':
							case 'line':
							case 'horizontalBar':
							case 'bullet':
							case 'treemap':
								return (
									<HighChart page={props.page} key={cData.chartUniqueId}
										data={cData} queryString={props.queryString} appId={props.appId}
										layout={layout} layoutStyles={layoutStyles} pageFilterConditions={pageFilterConditions} />
								);
							case 'ProcessDashboard1':
								return (
									<ProcessDashboard1
										appId={props.appId}
										data={cData}
										key={cData.chartUniqueId}
										page={page}
									/>
								);
							case 'ProcessDashboard2':
								return (
									<ProcessDashboard2
										appId={props.appId}
										data={cData}
										key={cData.chartUniqueId}
										page={page}
									/>
								);
							case 'DocumentHierarchy':
								return (
									<DocumentHierarchy
										page={page}
										data={cData}
										appId={props.appId}
										key={cData.chartUniqueId}
										handleSideNavBarClose={props.handleSideNavBarClose}
									/>
								);
							case 'WorkbookView':
								return (
									<WorkbookView
										page={page}
										key={cData.chartUniqueId}
										data={cData} queryString={props.queryString} appId={props.appId}

									/>
								);
							default:
								return null;
						}
					})()}
					{/* </Suspense> */}

				</div>
			);
		});
	let y = 0
	let lg = page && page.chart &&
		page.chart.length > 0 ?
		page.chart.map((element, i) => {
			y = y > element.chartData.layout.y ? y : element.chartData.layout.y + element.chartData.layout.h
			return {
				...element.chartData.layout,
				'i': "" + i,
				"moved": false,
				"static": true
			}
		}) : [];

	if (lg.length === 0) {
		lg = [{
			'x': 0, 'y': 0, w: 12, h: 2,
			'i': "enforce", "moved": false, "static": true
		}];
		allChart = <div key="enforce" align="center"
			style={{ "backgroundColor": "transparent", "borderRadius": "0px" }}>
			{"There is no layout to load"}<br />
		</div>
	} else {
		// For test
		if (allChart.length > 1) {
			lg.push({
				'x': 0, 'y': y, w: 12, h: 1,
				'i': "enforce", "moved": false, "static": true
			})
			allChart.push(<div style={{
				width: '100%',
				height: 24,
				backgroundColor: 'transparent !important',
			}} />)
		}
	}

	layouts = { lg };

	const onFilterClick = () => {
		setState({ ...state, isFilterOn: !state.isFilterOn })
	}

	/**
	 * This method is used for clearing the cognitive search text
	 */
	const handleCancelSearch = () => {
		setSearchValue("");
		setCognitiveSearchValue("");
		setChangedCognitiveSearchValue(true);
	};

	/**
	 * This is an onChange method which trigger when key is entered in the search field
	 * @param {event} e 
	 */
	const handleSearchInput = (e) => {
		const value = e?.target?.value;
		setSearchValue(value ?? "");
		if (value === "") { setCognitiveSearchValue(""); setChangedCognitiveSearchValue(true); }
	}

	/**
	 * This is triggerd when Enter key is pressed upon which the cognitive search is triggered
	 * @param {event} e 
	 */
	const handleKeyUp = (e) => {
		if (e.keyCode === 13 || e.key === "Enter") {
			setCognitiveSearchValue(searchValue);
			setChangedCognitiveSearchValue(true);
		} else if (e.keyCode === 27 || e.key === "Escape") {
			handleCancel();
		}
	};

	const handleLayoutChange = (event) => {
		setLayout(event.target.value);
	};

	if (localLoading) {
		return <div></div>
	}

	// if (props.pageByUrlLoading) {
	// 	return <Loader />
	// }

	//TODO: `Height` needs to be calculated based on the co-ordinates of the dashboard
	// componentViewPaneElement ==> width: "100%", height: "100%"

	// console.log("layout = == = ====", layout, layoutStyles)

	return (
		<React.Fragment>
			<div
				id="componentViewPaneElement"
				style={{
					width: "100%",
					height: "100%"
					// height: "calc(100vh - 150px)"
				}}>

				{allChart.length === 1
					? <>{allChart}</>
					: <>
						{allChart && <ResponsiveReactGridLayout
							style={{
								color: '#000', height: "100%",
								backgroundColor: '#fff',
								...(layoutStyles.gridLayoutStyles || {})
								// background: "#f7f7f7" // Only needed when configured
							}}
							{...props}
							{...layoutProps}
							layouts={layouts}
							onBreakpointChange={onBreakpointChange}
							onLayoutChange={onLayoutChange}
							onResize={onResize}
							onDrop={onDrop}
							// WidthProvider option
							measureBeforeMount={false}
							// I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
							// and set `measureBeforeMount={true}`.
							useCSSTransforms={mounted}
							compactType={compactType}
							preventCollision={!compactType}
							isDroppable={false}
						>
							{allChart}
						</ResponsiveReactGridLayout>}
					</>}
			</div>
		</React.Fragment>
	);
};

export default hot(withRouter(LayoutBox));
