import React, { useState, useEffect } from "react";

import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { hot } from 'react-hot-loader/root';
import { useHistory, withRouter } from "react-router-dom";

import { TextField, InputLabel, Grid, Paper, Button, Divider, IconButton, CircularProgress } from '@material-ui/core';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { ChipSelect } from 'dsilo-ui-components'

import { Loader } from "../../components/loader";
import { clearReduxDataOfCurrentComponent, createPrivilege, getDataModels, getPrivilegeById, getSystemOperations } from "../../store/actions";

import { useStyles } from "../../utility/SystemPagesCommonCode/styles";

const AddPrivilege = (props) => {
    const classes = useStyles()
    const history = useHistory();

    const { appId, formData } = props
    const { chartUniqueId, chartData } = props.data

    const [operationsList, setOperationsList] = useState([])
    const [dataModelsList, setDataModelsList] = useState([])

    const [formState, setFormState] = useState({})
    const [validate, setValidate] = useState({})

    useEffect(() => {
        fetchSystemOperations()
        fetchDataModels()
    }, [])

    useEffect(() => {
        if (formData.document[chartUniqueId + '_operations']?.length) {
            setOperationsList(formData.document[chartUniqueId + '_operations'])
        }
    }, [formData && formData.document[chartUniqueId + '_operations']])

    useEffect(() => {
        if (props.dataModels?.data?.length) {
            console.log("props.dataModels ==== after response", props.dataModels.data)
            let dms = props.dataModels.data.map((dm) => ({
                value: dm._id?.toString(),
                label: dm.description
            }))
            setDataModelsList(dms)
        }
    }, [props.dataModels])

    useEffect(() => {
        if (formData?.document[chartUniqueId + '_privilege']) {
            let d = formData?.document[chartUniqueId + '_privilege']
            console.log("it is privilege data ===", d)
            console.log("it is dataModelsList ===", dataModelsList)
            let obj = {
                _id: d._id?.toString(),
                name: d.name,
                description: d.description
            }
            if (d.operations.length) {
                obj['operations'] = d.operations.map((operation) => ({
                    value: operation,
                    label: operation
                }))
            }
            if (d.dataModelId && dataModelsList.length) {
                let dm = dataModelsList.find((dm) => dm.value === d.dataModelId)
                if (dm) {
                    obj['dataModelId'] = {
                        value: d.dataModelId,
                        label: dm.label
                    }
                }
            }
            setFormState(obj)
        }
    }, [formData && formData.document[chartUniqueId + '_privilege']])

    useEffect(() => {
        if (dataModelsList?.length) {
            fetchPrivilegeData()
        }
    }, [dataModelsList])

    useEffect(() => {
        if (formData?.document && formData.document[chartUniqueId + '_create_privilege']) {
            let message = formData.document[chartUniqueId + '_create_privilege']?.message
            if (message) {
                toast.success(message);
                props.history.push({ pathname: `/${props.match.params.appid}/page/privileges` });
            }
        }
        return () => {
            props.clearReduxDataOfCurrentComponent({
                key: "document",
                subKey: [chartUniqueId + '_create_privilege']
            })
        }
    }, [formData && formData.document[chartUniqueId + '_create_privilege']])

    const fetchSystemOperations = () => {
        const payload = {
            "appId": appId,
            "orgId": props.user.orgId,
            "dataUniqueId": chartUniqueId + '_operations'
        }
        props.getSystemOperations(payload)
    }

    const fetchDataModels = () => {
        if (props.dataModels?.data?.length) {
            console.log("props.dataModels ====", props.dataModels)
            let dms = props.dataModels.data.map((dm) => ({
                value: dm._id?.toString(),
                label: dm.description
            }))
            setDataModelsList(dms)
        } else {
            props.getDataModels(appId)
        }
    }

    const fetchPrivilegeData = () => {
        if (props.match.params.id !== "new") {
            const payload = {
                "appId": appId,
                "orgId": props.user.orgId,
                "id": props.match.params.id,
                "dataUniqueId": chartUniqueId + '_privilege'
            }
            props.getPrivilegeById(payload)
        }
    }

    const onChangeHandler = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        })
    }

    const onChangeOfSelectHandler = (value, key) => {
        setFormState({
            ...formState,
            [key]: value
        })
    }

    const handleBackBtnClick = () => {
        history.goBack()
    }

    const onSubmitHandler = () => {
        console.log("onSubmitHandler === formState", formState)
        let data = {
            ...formState,
        }
        if (formState.dataModelId) {
            data['dataModelId'] = formState.dataModelId?.value
        }
        if (formState.operations.length) {
            data['operations'] = formState.operations.map(operation => operation.value)
        }
        console.log("It is data to save in db ==", data)
        const payload = {
            data,
            "appId": appId,
            "orgId": props.user.orgId,
            "id": props.match.params.id,
            "dataUniqueId": chartUniqueId + '_create_privilege',
        }
        props.createPrivilege(payload)
    }

    if (formData[chartUniqueId + '_operations_loading'] || formData[chartUniqueId + '_privilege_loading']) {
        return <Loader />
    }

    return (
        <div style={{ position: 'absolute' }}>
            <div style={{ display: 'flex' }}>
                <IconButton onClick={handleBackBtnClick} className={classes.backIconCls} title={'Back'}>
                    <ArrowBackIosIcon />
                </IconButton>
                <h1>{'Privilege View'}</h1>
            </div>
            <Divider />
            <Grid container style={{ marginTop: 30, position: 'relative', overflow: 'visible' }}>
                <Grid container xs={6} spacing={3} className={classes.gridContainer}>
                    <Grid item xs={3} sm={3} >
                        <InputLabel htmlFor="my-input" className={classes.InputLabel}>Name</InputLabel>
                    </Grid>
                    <Grid item xs={9} sm={9}>
                        <TextField
                            variant="outlined"
                            id="standard-error-helper-text"
                            className={classes.TextField}
                            placeholder={'Enter Name'}
                            name='name'
                            value={formState.name}
                            onChange={onChangeHandler}
                            error={validate.name ? true : false}
                            helperText={validate.name ? 'Name is required' : ''}
                        />
                    </Grid>

                    <Grid item xs={3} sm={3}>
                        <InputLabel htmlFor="my-input" className={classes.InputLabel}>Description</InputLabel>
                    </Grid>
                    <Grid item xs={9} sm={9}>
                        <TextField
                            variant="outlined"
                            className={classes.TextField}
                            placeholder={'Enter Description'}

                            name={'description'}
                            value={formState.description}
                            onChange={onChangeHandler}

                            error={validate.description ? true : false}
                            id="standard-error-helper-text"
                            helperText={validate.description ? 'Description is required' : ''}
                        />
                    </Grid>

                    <Grid item xs={3} sm={3} >
                        <InputLabel htmlFor="my-input" className={classes.InputLabel}>Select Datamodel</InputLabel>
                    </Grid>
                    <Grid item xs={9} sm={9}>
                        <ChipSelect
                            id='role'
                            variant="fixed"
                            isSearchable={true}

                            options={dataModelsList}
                            value={formState.dataModelId}
                            onChange={(val) => onChangeOfSelectHandler(val, 'dataModelId')}

                            textFieldProps={{
                                InputLabelProps: {
                                    shrink: true,
                                },
                                variant: 'outlined',
                            }}
                            className={classes.chipSelect}
                        />
                    </Grid>

                    <Grid item xs={3} sm={3} >
                        <InputLabel htmlFor="my-input" className={classes.InputLabel}>Select Operations</InputLabel>
                    </Grid>
                    <Grid item xs={9} sm={9}>
                        <ChipSelect
                            id='role'
                            isMulti
                            variant="fixed"
                            isSearchable={true}

                            options={operationsList}
                            value={formState.operations}
                            onChange={(val) => onChangeOfSelectHandler(val, 'operations')}

                            textFieldProps={{
                                InputLabelProps: {
                                    shrink: true,
                                },
                                variant: 'outlined',
                            }}
                            className={classes.chipSelect}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSubmitHandler}
                            style={{ float: 'right' }}
                        >
                            {
                                props.formData[chartUniqueId + '_create_privilege_loading']
                                    ? <CircularProgress color="#fff" size={24} />
                                    : formState?._id ? "Update" : "Save"
                            }
                        </Button>
                        {formState?._id &&
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleBackBtnClick}
                                style={{ float: 'right', marginRight: 10 }}
                            >
                                {"Cancel"}
                            </Button>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapDispatchToProps = {
    getDataModels,
    getPrivilegeById,
    createPrivilege,
    getSystemOperations,
    clearReduxDataOfCurrentComponent,
}

const mapStateToProps = state => {
    return {
        user: state.user,
        formData: state.form,
        dataModels: state.dataModels,
        member: state.appConfig.members,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPrivilege)))