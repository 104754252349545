import { PDFViewer } from "dsilo-ui-components";
import { PdfjsViewer } from "dsilo-ui-components";
import React, { useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { downloadContractPDFAction, clearReduxDataOfCurrentComponent, downloadAmendmentTemplateActionV2 } from '../../store/actions';
import { Loader } from "../loader";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetAppIcon from '@material-ui/icons/GetApp';

import { Box, Button, CircularProgress, Divider, Grid, IconButton, InputLabel, makeStyles, TextField, Typography } from "@material-ui/core";


const DocumentViewerV2 = (props) => {
    const [pdf, setPdf] = useState(null);

    const fetchData = () => {
        props.downloadAmendmentTemplateActionV2({
            appid: props.appId,
            _id: props.match.params.id,
            dataModelId: props.data.chartData.selectedDataModel,
            payload: {
                view: true,
            },
            dataUniqueId: "quickView_amendment_view_template"
        })
    }

    /**
     * Clears the Redux store data related to the current component.
     */
    const clearCurrentChartReduxStore = () => {
        props.clearReduxDataOfCurrentComponent({
            key: "pdfDocument",
            subKey: ['quickView_amendment_view_template', 'error', 'message']
        })
    }

    useEffect(() => {
        fetchData()
        return () => {
            clearCurrentChartReduxStore()
        }
    }, [])

    const handlesQuickDownload = () => {
        props.downloadAmendmentTemplateActionV2({
            appid: props.appId,
            _id: props.match.params.id,
            dataModelId: props.data.chartData.selectedDataModel,
            payload: {
                view: false,
            },
            dataUniqueId: "download_amendment_docx_file"
        })
    }

    useEffect(() => {
        if (props.formData?.pdfDocument?.['quickView_amendment_view_template']?.pdf) {
            setPdf(new window.Blob([props.formData?.pdfDocument['quickView_amendment_view_template']?.pdf], { type: "application/pdf" }))
        }
    }, [props?.formData?.pdfDocument])
    return (
        <Grid style={{ padding: '10px' }}>
            {
                (props.formData?.['quickView_amendment_view_template_loading']) ? <Loader /> :
                    <Grid>
                        <Grid style={{ display: 'flex', justifyContent: 'end', gap: '5px', margin: '5px' }}>
                            <GetAppIcon color="primary" style={{ fontSize: "30px", cursor: 'pointer' }} onClick={handlesQuickDownload} />
                        </Grid>
                        <Grid>
                            {
                                (pdf && <PdfjsViewer pdf={pdf} />)
                            }
                        </Grid>
                    </Grid>

            }
        </Grid>
    )
}


const mapDispatchToProps = {
    downloadContractPDFAction,
    clearReduxDataOfCurrentComponent,
    downloadAmendmentTemplateActionV2
};

const mapStateToProps = state => {
    return {
        formData: state.form,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentViewerV2)));
