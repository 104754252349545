export const getPaginationDataFromUserSession = (userId, pageUrl) => {
    let localStoreJson = localStorage.getItem('userSession');
    if (localStoreJson) {
        let currentSession = JSON.parse(atob(localStoreJson));
        if (Object.keys(currentSession).length > 0) {
            if (currentSession && currentSession[userId] && currentSession[userId][pageUrl]) {
                let currentPageSession = currentSession[userId][pageUrl];
                let output = {};
                if (currentPageSession?.page) {
                    output['page'] = Number(currentPageSession?.page)
                }
                if (currentPageSession?.rowsPerPage) {
                    output['rowsPerPage'] = Number(currentPageSession?.rowsPerPage)
                }
                return output
            }
        }
    }
    return null;
}

export const updatePaginationDataToUserSession = (userId, pageUrl, data) => {
    let localStoreJson = localStorage.getItem('userSession');
    if (localStoreJson) {
        let currentSession = JSON.parse(atob(localStoreJson));
        if (Object.keys(currentSession).length === 0) {
            if (Object.keys(data).length > 0) {
                localStorage.setItem('userSession', btoa(JSON.stringify({ [userId]: { [pageUrl]: { ...data } } })))
            }
        } else {
            for (let [key, value] of Object.entries(currentSession)) {
                if (key === userId) {
                    for (let [key1, value1] of Object.entries(value)) {
                        if (key1 === pageUrl) {
                            currentSession[userId][pageUrl] = { ...value1, ...data }
                            localStorage.setItem('userSession', btoa(JSON.stringify(currentSession)))
                        } else {
                            if (Object.keys(data).length > 0) {
                                currentSession[userId][pageUrl] = { ...data }
                                localStorage.setItem('userSession', btoa(JSON.stringify(currentSession)))
                            }
                        }
                    }
                }
            }
        }
    } else if (Object.keys(data).length > 0) {
        localStorage.setItem('userSession', btoa(JSON.stringify({ [userId]: { [pageUrl]: { ...data } } })))
    }
}

export const getLastSelectedItemFromUserSession = (userId, pageUrl) => {
    let localStoreJson = localStorage.getItem('localStoreJson')
    if (localStoreJson) {
        localStoreJson = JSON.parse(atob(localStoreJson))
        let userSession = localStoreJson && localStoreJson[userId]
        let pageSession = userSession && userSession[pageUrl]
        if (pageSession?.selectedLineItem) {
            return pageSession.selectedLineItem;
        }
    }
    return null;
}

export const updateSelectedItemToUserSession = (userId, pageUrl, selectedItem) => {
    let dataToSetInLocalStore = null;
    let localStoreJson = localStorage.getItem('localStoreJson')
    if (localStoreJson) {
        let dataToSetInLocalStore = JSON.parse(atob(localStoreJson))
        let userSession = dataToSetInLocalStore[userId];
        if (userSession) {
            let pageSession = userSession[pageUrl]
            if (pageSession) {
                dataToSetInLocalStore = {
                    ...dataToSetInLocalStore,
                    [userId]: {
                        ...userSession,
                        [pageUrl]: {
                            ...pageSession,
                            'selectedLineItem': selectedItem
                        }
                    }
                }
            } else {
                dataToSetInLocalStore = {
                    ...dataToSetInLocalStore,
                    [userId]: {
                        ...userSession,
                        [pageUrl]: {
                            'selectedLineItem': selectedItem
                        }
                    }
                }
            }
        } else {
            dataToSetInLocalStore = {
                ...dataToSetInLocalStore,
                [userId]: {
                    [pageUrl]: { 'selectedLineItem': selectedItem }
                }
            }
        }
        for (let [key, value] of Object.entries(userSession)) {
            if (key === userId) { // userId matched
                for (let [key1, value1] of Object.entries(value)) {
                    if (key1 === pageUrl) { // page matched
                        userSession[userId][pageUrl] = { ...value1, 'selectedLineItem': docId }
                        localStorage.setItem('userSession', btoa(JSON.stringify(userSession)))
                    } else {
                        if (Object.keys(userSession).length > 0) {
                            userSession[userId][pageUrl] = { ...value1, 'selectedLineItem': docId }
                            localStorage.setItem('userSession', btoa(JSON.stringify(userSession)))
                        }
                    }
                }
            }
        }
    } else {
        dataToSetInLocalStore = { [userId]: { [pageUrl]: { 'selectedLineItem': selectedItem } } }
    }

    console.log("updateSelectedItemToUserSession === dataToSetInLocalStore", dataToSetInLocalStore)
    localStorage.setItem('userSession', btoa(JSON.stringify(dataToSetInLocalStore)))
}