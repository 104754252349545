import React, { useState, useEffect } from "react"

import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { useHistory, withRouter, useParams } from "react-router-dom";

import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import SearchIcon from "@material-ui/icons/Search"
import CloseIcon from "@material-ui/icons/Close"

import { TextField, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, Paper, InputAdornment, TablePagination } from "@material-ui/core";

import { ConfirmationHandler } from "dsilo-ui-components";

import { Loader } from '../../loader';
import { PUBLIC_PATH } from "../../../constants"

import { getDataModelById, getContractDataById, getSubModelData, clearReduxDataOfCurrentComponent, updateDataModelData, deleteContractPDFAction } from '../../../store/actions';

import useStyles from "../styles";

const ClauseList = (props) => {
    const classes = useStyles()
    const history = useHistory();
    const { id: _id } = useParams();

    const { setIsClauseView } = props
    const { chartData, chartUniqueId } = props.data
    const { pagination, subDataElement } = chartData
    const [searchedText, setSearchedText] = useState('');
    const [fetched1, setFetched1] = useState(false)


    const getRowsPerPage = () => {
        return Number(props.data.chartData?.pagination?.rowsPerPage) || 10;
    };

    const sharedJSON = {
        paginationPage: 0,
        count: 10,
        rowsPerPage: getRowsPerPage(),
        searchText: "",
        isSearchIconHide: false
    }

    const [state, setState] = useState({
        ...sharedJSON
    })
    const [deleteConfirmState, setDeleteConfirm] = useState({
        isOpen: false,
        deleteMessage: "Are you sure you want to delete ?",
        data: null
    })

    const tableData = props.formData?.document[chartUniqueId + "_data"]
    const count = props.formData?.document[chartUniqueId + "_data"]?.total
    const loading = props?.formData?.[chartUniqueId + '_data' + '_loading']
    let dataUniqueId = chartData.subDataModel
    let mainDataModelDataElement = props?.formData?.dataModel?.[dataUniqueId]?.mainDataModel?.dataElement

    useEffect(() => {
        let dataUniqueId = chartData.subDataModel;
        let dataModelId = chartData.subDataModel
        if (!props.formData?.dataModel?.[dataUniqueId] && !props.formData[`${dataUniqueId}_loading}`]) {
            let payload = {
                appid: props.match.params.appid,
                orgId: props.appConfig?.org?._id,
                dataModelId: dataModelId,
                dataUniqueId: dataUniqueId
            };
            props.getDataModelById(payload);
        }
    }, [])

    useEffect(() => {
        let dataUniqueId = chartData.subDataModel;
        const dataModelEntry = props?.formData?.dataModel?.[dataUniqueId];
        if (dataModelEntry?.mainDataModel) {
            fetchData();
        }
    }, [props?.formData?.dataModel, state.paginationPage, state.rowsPerPage, _id]);



    const tableCrudOperations = (item, type, e) => {
        if (type === 'delete') {
            setDeleteConfirm({
                ...deleteConfirmState,
                isOpen: true,
                data: item._id
            })
        }
        if (type === "edit") {
            history.push({
                pathname: `/${PUBLIC_PATH}${props.match.params.appid}/page/${props.page.url}/${_id}/sd/${item._id}`
            })
            setIsClauseView(true)
        }
        if (type === "add") {
            history.push({
                pathname: `/${PUBLIC_PATH}${props.match.params.appid}/page/${props.page.url}/${_id}/sd/new`
            })
            setIsClauseView(true)
        }
    }

    const searchHandler = (e) => {
        setSearchedText(e ? e.target.value : "")
    }

    const onSearchEnter = (e, onSearchButton = false) => {
        if (e === null) {
            setSearchedText('')
            fetchData("")
        } else if (e?.keyCode === 13 || onSearchButton) {
            fetchData(searchedText)
        }
    }

    const handleSearchClick = (type) => {
        setState((prevState) => {
            if (!prevState) {
                return sharedJson
            }
            const newState = { ...prevState, isSearchIconHide: !prevState.isSearchIconHide };

            if (!newState.isSearchIconHide && type === "close" && searchedText) {
                onSearchEnter(null);
            }
            return newState
        })
    }

    const handleChangeRowsPerPage = (event) => {
        setFetched1(true)
        setState(prev => {
            return {
                ...prev,
                rowsPerPage: event.target.value,
                paginationPage: 0
            }
        })
    }

    const handleChangePage = (event, page) => {
        setFetched1(true)
        setState(prev => {
            return {
                ...prev, paginationPage: page
            }
        })
    }

    const getPageNo = () => {
        if (state.paginationPage !== undefined) {
            return state.paginationPage;
        }
        if (typeof page === 'object') {
            return 0;
        }
        return page || 0;
    };

    const fetchData = (searchTextData) => {
        const payload = {
            appId: props.match.params.appid,
            dataModelId: chartData.subDataModel,
            dataUniqueId: chartUniqueId + "_data",
            fields: subDataElement.map((i) => i.value).join(","),
            [mainDataModelDataElement]: _id
        }
        payload.page = state.paginationPage;
        payload.rowsPerPage = state.rowsPerPage;
        if (searchTextData !== undefined) {
            payload.search = searchTextData;
        } else if (searchedText) {
            payload.search = searchedText;
        }
        props.getSubModelData(payload)
    }

    const onDeleteResponse = (isDelete) => {
        if (isDelete) {
            const payload = {
                ids: { ids: [deleteConfirmState.data] },
                dataModelId: chartData.subDataModel,
                appId: props.match.params.appid,
                dataUniqueId: props.data.chartUniqueId + '_data'
            }
            props.deleteContractPDFAction(payload)
        }
        setDeleteConfirm({ ...deleteConfirmState, isOpen: false, data: null })
    }

    return (
        <>
            <div className={classes.clauseList}>
                <h2>{props.page.listTitle}</h2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {props.isSearch
                        && <TextField
                            id="standard-basic"
                            label="Search"
                            variant="outlined"
                            value={searchedText}
                            onChange={searchHandler}
                            onKeyDown={onSearchEnter}
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {state.isSearchIconHide ? (
                                            <IconButton
                                                title="Cancel"
                                                onClick={() => handleSearchClick("close")}
                                                style={{ padding: 0 }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                title="Search"
                                                onClick={(e) => {
                                                    onSearchEnter(e, true);
                                                    handleSearchClick("search");
                                                }}
                                                style={{ padding: 0 }}
                                            >
                                                <SearchIcon color="primary" />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    }
                    <Button variant="contained" color="primary" size='small' style={{ marginLeft: "10px" }} onClick={(e) => tableCrudOperations(null, "add", e)}>+ CREATE CLAUSE</Button>
                </div>
            </div>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.tableHeadRow}>
                            {chartData?.subDataElement?.map((header, index) => (
                                <TableCell key={index} className={classes.tableHeadCell}>
                                    {header.label || header.value}
                                </TableCell>
                            ))}
                            <TableCell className={classes.tableHeadCell}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={chartData?.subDataElement?.length + 1} align="center">
                                    <Loader />
                                </TableCell>
                            </TableRow>
                        ) : (
                            tableData?.data?.length > 0 ? (
                                tableData?.data?.map((row, rowIndex) => (
                                    <TableRow
                                        key={row._id || rowIndex}
                                        className={`${classes.tableRow} ${rowIndex % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd}`}
                                    >
                                        {chartData?.subDataElement?.map((header, colIndex) => (
                                            <TableCell key={colIndex} className={classes.tableCell}>
                                                {row[header.value] || "-"}
                                            </TableCell>
                                        ))}
                                        <TableCell className={classes.tableCell}>
                                            <div className={classes.actionsContainer}>
                                                <IconButton className={classes.actionIcons} onClick={(e) => tableCrudOperations(row, "edit", e)}>
                                                    <Tooltip title="Edit">
                                                        <EditIcon />
                                                    </Tooltip>
                                                </IconButton>
                                                <IconButton className={classes.actionIcons} onClick={(e) =>
                                                    tableCrudOperations(row, "delete", e)
                                                }>
                                                    <Tooltip title="Delete">
                                                        <DeleteIcon />
                                                    </Tooltip>
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={chartData?.subDataElement?.length + 1} align="center">
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={
                    pagination?.rowsPerPageOptions || [
                        10, 25, 50, 100
                    ]
                }
                component='div'
                count={count || 0}
                style={{ color: "rgba(0, 0, 0, 0.87)" }}
                rowsPerPage={Number(state.rowsPerPage) || 10}
                page={getPageNo()}
                backIconButtonProps={{
                    "aria-label": "Previous Page"
                }}
                nextIconButtonProps={{
                    "aria-label": "Next Page"
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ConfirmationHandler
                open={deleteConfirmState.isOpen}
                handleClose={onDeleteResponse}
                alertMessageText={deleteConfirmState.deleteMessage}
                classes={classes}
                confirm
            />
        </>
    )
}

const mapDispatchToProps = {
    getDataModelById,
    getContractDataById,
    getSubModelData,
    clearReduxDataOfCurrentComponent,
    updateDataModelData,
    deleteContractPDFAction

}

const mapStateToProps = state => {
    return {
        formData: state.form,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ClauseList)))

